import React, { useEffect, useState } from "react";
import styles from "./Events.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const Discounts = () => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const [code, setCode] = useState("");
  const [amount, setAmount] = useState("");

  const navigate = useNavigate();

  const [discounts, setDiscounts] = useState([]);

  useEffect(() => {
    axios.get("https://3.106.200.146:3001/getdiscounts").then((response) => {
      setDiscounts(response.data.result);
      setLoading(false);
    });
  }, [loading]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="container">
        <h3 className="mainFont">Discounts</h3>
      </div>

      <div className={`${styles.tableContainer} container`}>
        <div className="d-flex justify-content-end">
          <Button variant="primary" onClick={handleShow}>
            Add Discount
          </Button>
        </div>
        <table className={`${styles.customTable} table`}>
          <thead>
            <tr className="mainFont fontThin">
              <th scope="col">Discount ID</th>
              <th scope="col">Code</th>
              <th scope="col">Amount</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading
              ? discounts.map((e) => (
                  <tr key={e.id} className="mainFont fontThin">
                    <td style={{ paddingTop: "15px" }}>{e.id}</td>
                    <td style={{ paddingTop: "15px" }}>{e.code}</td>
                    <td style={{ paddingTop: "15px" }}>{e.amount}$</td>
                    <td className="d-flex" style={{ paddingTop: "15px" }}>
                      <p
                        className="color__red"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => {
                          setLoading(true);
                          axios
                            .get(
                              "https://3.106.200.146:3001/delete-discount/" +
                                e.id
                            )
                            .then((response) => {
                              setLoading(false);
                            });
                        }}
                      >
                        Delete
                      </p>
                    </td>
                  </tr>
                ))
              : "Loading..."}
          </tbody>
        </table>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>Discount Code:</label>
            <br />
            <input
              className="w-100 pt-2 pb-2"
              placeholder="EM5000"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <label>Discount Amount (Fixed Amount) :</label>
            <br />
            <input
              className="w-100 pt-2 pb-2"
              placeholder="50"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setLoading(true);
              axios
                .post("https://3.106.200.146:3001/add-discount", {
                  code: code,
                  amount: amount,
                })
                .then((response) => {
                  handleClose();
                  setLoading(false);
                });
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Discounts;
