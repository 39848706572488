import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./Teams.module.css";
import { Button, Modal } from "react-bootstrap";

const AloneTeams = () => {
  const { filter } = useParams();
  const [teams, setTeams] = useState([]);
  const [eventDates, setEventDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(0);
  const [selectedDate, setSelectedDage] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const filteredTeams = teams.filter((t) => t.event_date === selectedDate);

  const [teamName, setTeamName] = useState("");
  const [memberName, setMemberName] = useState("");
  const [teamCode, setTeamCode] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [captainDob, setCaptainDob] = useState("");
  const [captainEmail, setCaptainEmail] = useState("");
  const [captainPhone, setCaptainPhone] = useState("");
  const [kin, setKin] = useState("");
  const [secondPhone, setSecondPhone] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    axios.get("https://3.106.200.146:3001/allEvents").then((response) => {
      setEvents(response.data.result);

      if (response.data.result.length > 0) {
        setSelectedEvent(response.data.result[0].id);

        axios
          .get("https://3.106.200.146:3001/getAloneTeams/" + selectedEvent)
          .then((response) => {
            setTeams(response.data.result);

            if (response.data.result.length > 0) {
              axios
                .get("https://3.106.200.146:3001/eventDates/" + selectedEvent)
                .then((response) => {
                  setEventDates(response.data.result);

                  if (response.data.result.length > 0) {
                    setSelectedDage(response.data.result[0].event_date);
                    setLoading(false);
                  } else {
                    console.error("No event dates available");
                  }
                })
                .catch((error) => {
                  console.error("Error fetching event dates:", error);
                });
            } else {
              console.error("No teams available");
            }
          })
          .catch((error) => {
            console.error("Error fetching teams:", error);
          });
      } else {
        console.error("No events available");
      }
    });
  }, []);
  useEffect(() => {
    if (selectedEvent != 0) {
      setLoading(true);
      axios
        .get("https://3.106.200.146:3001/getAloneTeams/" + selectedEvent)
        .then((response) => {
          setTeams(response.data.result);
          axios
            .get("https://3.106.200.146:3001/eventDates/" + selectedEvent)
            .then((response) => {
              setEventDates(response.data.result);
              setSelectedDage(response.data.result[0].event_date);
              console.log(response.data.result[0].event_date);
              setLoading(false);
            });
        });
    }
  }, [selectedEvent]);
  return (
    <>
      <div className={`${styles.tableContainer} container mt-5`}>
        {selectedTeam == "" ? (
          <>
            <div className="d-flex">
              <div>
                <label>Event Date:</label>
                <br />
                <select
                  style={{
                    padding: "10px",
                    border: "1px solid lightgrey",
                    width: "200px",
                    marginRight: "20px",
                  }}
                  onChange={(e) => setSelectedDage(e.target.value)}
                >
                  {eventDates.map((e, index) => (
                    <option key={index} value={e.event_date}>
                      {e.event_date}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>Events:</label>
                <br />
                <select
                  style={{
                    padding: "10px",
                    border: "1px solid lightgrey",
                    width: "200px",
                    marginRight: "20px",
                  }}
                  onChange={(e) => setSelectedEvent(e.target.value)}
                >
                  {events.map((e, index) => (
                    <option key={index} value={e.id}>
                      {e.event_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <br />
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Child's School / Sports Team</th>
                  <th scope="col">Captain Name</th>
                  <th scope="col">Team Code</th>
                  <th scope="col">Event Date</th>
                  <th scope="col">Deposit</th>
                  <th scope="col">Members</th>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? "Loading..."
                  : filteredTeams.map((t, index) => (
                      <tr key={index}>
                        <th scope="row">{t.team_id}</th>
                        <td>{t.team_name}</td>
                        <td>{t.captain_name}</td>
                        <td>{t.team_code}</td>
                        <td>{t.event_date}</td>
                        <td className="allCaps">{t.payment}</td>
                        <td>
                          <p
                            className="color__blue"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setTeamName(t.team_name);
                              setMemberName(t.captain_name);
                              setTeamCode(t.team_code);
                              setEventDate(t.event_date);
                              setCaptainDob(t.captain_dob);
                              setCaptainEmail(t.captain_email);
                              setCaptainPhone(t.captain_phone);
                              setKin(t.kin);
                              setSecondPhone(t.secondPhone);
                              setDescription(t.description);
                              handleShow();
                            }}
                          >
                            View Detail
                          </p>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{teamName}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Member Name: <b>{memberName}</b>
                <br />
                Unique Code: <b>{teamCode}</b> <br />
                Event Date: <b>{eventDate}</b> <br />
                Member DOB: <b>{captainDob}</b> <br />
                Email Address: <b>{captainEmail}</b> <br />
                Phone Number: <b>{captainPhone}</b> <br />
                Next To Kin: <b>{kin}</b> <br />
                Alternate Phone Number: <b>{secondPhone}</b> <br />
                Dietary Requirements / Allergies: <b>{description}</b> <br />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AloneTeams;
