import React from "react";
import styles from "./AboutMobile.module.css";
import FirstImage from "../../images/about_mobile.png";
import SecondImage from "../../images/about_image4.png";

import Clock from "../../icons/watch.svg";
import Basketball from "../../icons/basketball.svg";
import Rugby from "../../icons/rugby.svg";
import { useNavigate } from "react-router-dom";
const FirstSection = () => {
  return (
    <div className={`${styles.firstContainer}`}>
      <img src={FirstImage} />
      <h1 className="color__orange mainFont fontBold">
        Meet <br /> HOLLY AND <br /> SCOTT
      </h1>
      <div className="d-flex justify-content-end">
        <p className="mainFont color__white fontThin">
          proud parents of three energetic boys who are always on the move.{" "}
          <br /> <br />
          Their eldest boy suffered with some physical challenges early on in
          life, and they soon learned that he still wanted to keep up with his
          friends.
        </p>
      </div>
    </div>
  );
};

const SecondSection = () => {
  const navigate = useNavigate();
  return (
    <div className={`${styles.secondContainer}`}>
      <img src={SecondImage} />
      <p className="color__white mainFont fontThin">
        This meant a little extra work from them all to improve his physicality
        so he didn’t feel left behind in sports. This is how{" "}
        <span className="color__blue mainFont fontSemiBold">
          Growing Athletes TRAINING CAMPS
        </span>{" "}
        came about.
      </p>
      <button
        className="mainFont color__white"
        onClick={() => navigate("/weekly-training")}
      >
        Check Training Camps
      </button>
      <p className="mainFont color__white fontThin">
        With custom programs created around children, GA’s trainers focus on
        helping kids build strength, stamina, speed and agility as well as
        understand how important daily exercise is for their health and
        wellbeing.
      </p>
    </div>
  );
};

const ThirdSection = () => {
  const navigate = useNavigate();
  return (
    <div className={`${styles.thirdContainer}`}>
      <h2 className="color__blue mainFont fontBold">Growing Athletes</h2>
      <h1 className="color__orange mainFont fontBold">
        Then Host a <br /> GAME DAY
      </h1>
      <p className="color__white mainFont fontThin">
        where GA members and others can bring their own <br /> team along for a
        day of fun to <br />
        help test their new skills.
      </p>
      <button
        className="mainFont color__white"
        onClick={() => navigate("/gameday")}
      >
        Check Out The Game Day
      </button>
    </div>
  );
};

const AboutMobile = () => {
  return (
    <>
      <img src={Clock} className={`${styles.clock}`} />
      <img src={Basketball} className={`${styles.basketball}`} />
      <img src={Rugby} className={`${styles.rugby}`} />
      <img src={Clock} className={`${styles.clock2}`} />
      <FirstSection />
      <SecondSection />
      <ThirdSection />
    </>
  );
};

export default AboutMobile;
