import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.css";
import axios from "axios";
const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [eventCount, setEventCount] = useState(0);
  const [teamsCount, setTeamsCount] = useState(0);
  const [visitors, setVisitors] = useState(0);
  const [teamsJoined, setTeamsJoined] = useState([]);
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    axios.get("https://3.106.200.146:3001/teamsJoined").then((response) => {
      setTeamsJoined(response.data.result);
      axios.get("https://3.106.200.146:3001/getLogs").then((response) => {
        setLogs(response.data.result);
        setLoading(false);
      });
    });
  }, []);

  return (
    <div>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <div className={`${styles.overviewCards}`}>
              <div>
                <h3 className="mainFont fontBold">Users</h3>
                <p className="mainFont fontMedium">{userCount}</p>
              </div>
              <img
                src="https://cdn-icons-png.flaticon.com/512/1177/1177568.png"
                width="70"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className={`${styles.overviewCards}`}>
              <div>
                <h3 className="mainFont fontBold">Events</h3>
                <p className="mainFont fontMedium">{eventCount}</p>
              </div>
              <img
                src="https://cdn-icons-png.flaticon.com/512/1692/1692465.png"
                width="70"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className={`${styles.overviewCards}`}>
              <div>
                <h3 className="mainFont fontBold">Teams</h3>
                <p className="mainFont fontMedium">{teamsCount}</p>
              </div>
              <img
                src="https://cdn-icons-png.flaticon.com/512/1692/1692501.png"
                width="70"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className={`${styles.overviewCards}`}>
              <div>
                <h3 className="mainFont fontBold">Visitors</h3>
                <p className="mainFont fontMedium">{visitors}</p>
              </div>
              <img
                src="https://cdn-icons-png.flaticon.com/512/1692/1692492.png"
                width="70"
              />
            </div>
          </div>
        </div>
      </div>
      {/* OverView Start */}
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12 col-md-3 col-lg-3">
            <div className={`${styles.overview_card}`}>
              <h6 className="mainFont">Latest Teams</h6>
              {!loading
                ? teamsJoined.map((t, index) => (
                    <div>
                      <h5 className="mainFont">{t.team_name}</h5>
                      <p className="mainFont fontThin">
                        Captain: {t.captain_name}
                      </p>
                      <p className="mainFont fontThin">Event: {t.event_date}</p>
                    </div>
                  ))
                : ""}
            </div>
          </div>
          <div className="col-12 col-md-9 col-lg-9">
            <div className={`${styles.overview_card}`}>
              <h5>Logs</h5>
              {!loading
                ? logs.map((l, index) => (
                    <div>
                      <p style={{ paddingTop: "15px" }}>{l.text}</p>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>
      </div>
      {/* Overview End */}
    </div>
  );
};

export default Dashboard;
