import React, { useEffect, useState } from "react";
import styles from "./Purchase.module.css";
import Navbar from "../../components/Navbar";
import PurchaseImage from "../../images/purchase_img.png";
import WeeklyTeam from "./WeeklyTeam";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import BookingPageImage from "../../images/booking_page_image.png";
import ClockIcon from "../../images/clock_icon.svg";
import I1 from "../../images/i_1.png";
import I2 from "../../images/i_2.png";
import I3 from "../../images/i_3.png";
function formatDate(inputDate) {
  const options = { day: "numeric", month: "long" };
  const date = new Date(inputDate);
  const formattedDate = date.toLocaleDateString(undefined, options);
  return formattedDate;
}

const WeeklyPurchase = () => {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [eventData, setEventData] = useState([]);
  const [dates, setDates] = useState([]);
  const [showBox, setShowBox] = useState(true);
  const [dateSelected, setDateSelected] = useState(0);
  useEffect(() => {
    axios
      .get("https://3.106.200.146:3001/getWeeklyById/" + eventId)
      .then((response) => {
        console.log(response.data);
        setEventData(response.data.result[0]);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className={`${styles.hideDesktop}`}>
              <div
                className={`${styles.dateAndLocation} d-flex align-items-center`}
              >
                <h2
                  className={`${styles.date} color__orange mainFont fontBold allCaps`}
                >
                  {loading ? "Loading..." : formatDate(eventData.weekly_date)}
                </h2>
                <h4 className="color__white mainFont fontRegular">
                  {" "}
                  At {eventData.weekly_location}
                </h4>
                <br />
              </div>
            </div>
            <div
              className={`${styles.customScroller}`}
              style={{
                width: "100%",
                height: "800px",
                overflow: "auto",
              }}
            >
              <img src={I1} width="100%" />
              <img src={I2} width="100%" />
              <img src={I3} width="100%" />
            </div>
          </div>
          <div className="col-12 col-md-8">
            <div className={`${styles.hideMobile}`}>
              <div
                className={`${styles.dateAndLocation} d-flex align-items-center`}
              >
                <h2
                  className={`${styles.date} color__orange mainFont fontBold allCaps`}
                >
                  {loading ? "Loading..." : eventData.weekly_location}
                </h2>
                <br />
              </div>
              <div className="d-flex align-items-center">
                <img src={ClockIcon} width={50} />
                <h3 className="color__orange mainFont fontBold mt-2 mx-3">
                  {eventData.weekly_time + " " + eventData.weekly_day}
                </h3>
              </div>
            </div>
            <p className="color__grey mainFont fontRegular mt-3">
              We are keen to have you be a part of our weekly Training Camp!{" "}
              <br />
              <br />
              Children must be booked for the school term. <br />
              <br /> Price: <b>${eventData.deposit} per child</b>
              <br /> <br /> Note* If you are half way through a school term but
              wish to enroll a child please call to discuss options.
            </p>
            {loading ? (
              ""
            ) : (
              <WeeklyTeam
                styles={styles}
                eventId={eventId}
                date={eventData.weekly_date}
                deposit={eventData.deposit}
                eventLocation={eventData.weekly_location}
              />
            )}
          </div>
        </div>
        {/* <MyPaymentForm /> */}
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
};
export default WeeklyPurchase;
