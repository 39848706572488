import axios from "axios";
import React, { useEffect, useState } from "react";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import PP_Logo from "../../images/paypal_logo.svg";
import Mastercard from "../../images/mastercard.svg";
import Visa from "../../images/visa.svg";
import { PayPalButtons } from "@paypal/react-paypal-js";
import Modal from "react-bootstrap/Modal";

const PaypalCheckoutButton = (props) => {
  const {
    product,
    deposit,
    teamName,
    cName,
    cDob,
    cEmail,
    cPhone,
    date,
    eventId,
    kinName,
    secondPhone,
  } = props;
  const [payPass, setPayPass] = useState(false);
  const handleApprove = () => {
    // No need to set payPass to true again here, you already did it in the onApprove callback
    // setPayPass(true);

    // Check if payPass is true before showing the alert
    if (payPass) {
      alert("Thank you for your payment!");
    }
  };

  return (
    <>
      {payPass ? (
        <h3 className="mainFont color__white fontBold">
          Thank you for your payment! <br /> You'll receive a confirmation email
          shortly!
        </h3>
      ) : (
        <PayPalButtons
          styles={{
            layout: "horizontal",
            height: 48,
            tagline: true,
            shape: "pill",
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: product.description,
                  amount: {
                    value: deposit,
                  },
                },
              ],
            });
          }}
          onApprove={async (data, actions) => {
            const order = await actions.order.capture();
            // Set payPass to true when the payment is approved
            setPayPass(true);
            handleApprove(); // Call the handleApprove function
            console.log(order);
            axios
              .post("https://3.106.200.146:3001/registerTeam", {
                team_name: teamName,
                captain_name: cName,
                captain_dob: cDob,
                captain_email: cEmail,
                captain_phone: cPhone,
                event_id: eventId,
                event_date: date,
                kinName: kinName,
                secondPhone: secondPhone,
              })
              .then((response) => console.log(response));
          }}
          onError={(err) => {
            alert("An error occurred: " + err);
          }}
        />
      )}
    </>
  );
};

const PaypalCheckoutButtonSecond = (props) => {
  const {
    product,
    deposit,
    teamCode,
    cName,
    cDob,
    cEmail,
    cPhone,
    date,
    eventId,
    kinName,
    secondPhone,
    diet,
  } = props;
  const [payPass, setPayPass] = useState(false);
  const handleApprove = () => {
    // No need to set payPass to true again here, you already did it in the onApprove callback
    // setPayPass(true);

    // Check if payPass is true before showing the alert
    if (payPass) {
      alert("Thank you for your payment!");
    }
  };

  return (
    <>
      {payPass ? (
        <h3 className="mainFont color__white fontBold">
          Thank you for your payment! <br /> You'll receive a confirmation email
          shortly!
        </h3>
      ) : (
        <PayPalButtons
          styles={{
            layout: "horizontal",
            height: 48,
            tagline: true,
            shape: "pill",
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: product.description,
                  amount: {
                    value: deposit,
                    currency_code: "AUD",
                  },
                },
              ],
            });
          }}
          onApprove={async (data, actions) => {
            const order = await actions.order.capture();
            // Set payPass to true when the payment is approved
            setPayPass(true);
            handleApprove(); // Call the handleApprove function
            console.log(order);
            axios
              .post("https://3.106.200.146:3001/joinTeam", {
                member_name: cName,
                member_dob: cDob,
                member_phone: cPhone,
                member_email: cEmail,
                description: diet,
                team_code: teamCode,
                kinName: kinName,
                secondPhone: secondPhone,
              })
              .then((e) => {
                console.log(e);
              });
          }}
          onError={(err) => {
            alert("An error occurred: " + err);
          }}
        />
      )}
    </>
  );
};

const MyPaymentForm = ({
  deposit,
  teamName,
  cName,
  cDob,
  cEmail,
  cPhone,
  date,
  eventId,
  kinName,
  secondPhone,
}) => {
  const [message, setMessage] = useState("");
  const [paymentDone, setPaymentDone] = useState(null);
  return (
    <PaymentForm
      /**
       * Identifies the calling form with a verified application ID generated from
       * the Square Application Dashboard.
       */
      applicationId="sq0idp-kmDHjEZQNpbilIamooBwPw"
      /**
       * Invoked when payment form receives the result of a tokenize generation
       * request. The result will be a valid credit card or wallet token, or an error.
       */
      cardTokenizeResponseReceived={(token, buyer) => {
        axios
          .post("https://3.106.200.146:3001/pay", {
            amount: deposit,
            token: token.token,
          })
          .then((response) => {
            if (response.data.code == 1) {
              axios
                .post("https://3.106.200.146:3001/registerTeam", {
                  team_name: teamName,
                  captain_name: cName,
                  captain_dob: cDob,
                  captain_email: cEmail,
                  captain_phone: cPhone,
                  event_id: eventId,
                  event_date: date,
                  kinName: kinName,
                  secondPhone: secondPhone,
                })
                .then((response) => console.log(response));
              setPaymentDone(true);
            } else {
              setPaymentDone(false);
              setMessage("There was an error processing your payment.");
            }
          })
          .catch((error) => {
            // Handle errors here and log the error details

            // If the error object contains more specific details, you can access them
            if (error.response) {
              console.error("Response Data:", error.response.data);
            }
          });
      }}
      locationId="LC4Y26181PE85"
    >
      {!paymentDone && paymentDone != null ? (
        <h3 className="mainFont fontMedium color__white">
          Pay Deposit - ${deposit}
        </h3>
      ) : (
        ""
      )}
      {!paymentDone && paymentDone != null ? (
        <div className="alert alert-danger">{message}</div>
      ) : (
        ""
      )}
      {paymentDone ? (
        <h3 className="mainFont color__white fontBold">
          Thank you for your payment! You'll receive an email shortly!
        </h3>
      ) : (
        <div style={{ marginRight: "40px" }}>
          <CreditCard />
          <p className="mainFont color__white fontThin mt-2">
            Payment gateway by SquareUp
          </p>
        </div>
      )}
    </PaymentForm>
  );
};

const MyPaymentFormJoin = ({
  deposit,
  teamCode,
  cName,
  cDob,
  cEmail,
  cPhone,
  date,
  eventId,
  kinName,
  secondPhone,
  diet,
}) => {
  const [message, setMessage] = useState("");
  const [paymentDone, setPaymentDone] = useState(null);
  return (
    <PaymentForm
      /**
       * Identifies the calling form with a verified application ID generated from
       * the Square Application Dashboard.
       */
      applicationId="sq0idp-kmDHjEZQNpbilIamooBwPw"
      /**
       * Invoked when payment form receives the result of a tokenize generation
       * request. The result will be a valid credit card or wallet token, or an error.
       */
      cardTokenizeResponseReceived={(token, buyer) => {
        axios
          .post("https://3.106.200.146:3001/pay", {
            amount: deposit,
            token: token.token,
          })
          .then((response) => {
            if (response.data.code == 1) {
              axios
                .post("https://3.106.200.146:3001/joinTeam", {
                  member_name: cName,
                  member_dob: cDob,
                  member_phone: cPhone,
                  member_email: cEmail,
                  description: diet,
                  team_code: teamCode,
                  kinName: kinName,
                  secondPhone: secondPhone,
                })
                .then((e) => {
                  setPaymentDone(true);
                });
            } else {
              setPaymentDone(false);
              setMessage("There was an error processing your payment.");
            }
          })
          .catch((error) => {
            // Handle errors here and log the error details

            // If the error object contains more specific details, you can access them
            if (error.response) {
              console.error("Response Data:", error.response.data);
            }
          });
      }}
      locationId="LC4Y26181PE85"
    >
      {!paymentDone && paymentDone != null ? (
        <h3 className="mainFont fontMedium color__white">
          Pay Deposit - ${deposit}
        </h3>
      ) : (
        ""
      )}
      {!paymentDone && paymentDone != null ? (
        <div className="alert alert-danger">{message}</div>
      ) : (
        ""
      )}
      {paymentDone ? (
        <h3 className="mainFont color__white fontBold">
          Thank you for your payment! You'll receive an email shortly!
        </h3>
      ) : (
        <div style={{ marginRight: "40px" }}>
          <CreditCard />
          <p className="mainFont color__white fontThin mt-2">
            Payment gateway by SquareUp
          </p>
        </div>
      )}
    </PaymentForm>
  );
};
const MyPaymentFormAlone = ({
  deposit,
  teamCode,
  cName,
  cDob,
  cEmail,
  cPhone,
  date,
  eventId,
  kinName,
  secondPhone,
  diet,
}) => {
  const [message, setMessage] = useState("");
  const [paymentDone, setPaymentDone] = useState(null);
  return (
    <PaymentForm
      /**
       * Identifies the calling form with a verified application ID generated from
       * the Square Application Dashboard.
       */
      applicationId="sq0idp-kmDHjEZQNpbilIamooBwPw"
      /**
       * Invoked when payment form receives the result of a tokenize generation
       * request. The result will be a valid credit card or wallet token, or an error.
       */
      cardTokenizeResponseReceived={(token, buyer) => {
        axios
          .post("https://3.106.200.146:3001/pay", {
            amount: deposit,
            token: token.token,
          })
          .then((response) => {
            if (response.data.code == 1) {
              axios
                .post("https://3.106.200.146:3001/joinAlone", {
                  member_name: cName,
                  member_dob: cDob,
                  member_phone: cPhone,
                  member_email: cEmail,
                  description: diet,
                  team_name: teamCode,
                  kinName: kinName,
                  event_date: date,
                  event_id: eventId,
                  secondPhone: secondPhone,
                })
                .then((e) => {
                  setPaymentDone(true);
                });
            } else {
              setPaymentDone(false);
              setMessage("There was an error processing your payment.");
            }
          })
          .catch((error) => {
            // Handle errors here and log the error details

            // If the error object contains more specific details, you can access them
            if (error.response) {
              console.error("Response Data:", error.response.data);
            }
          });
      }}
      locationId="LC4Y26181PE85"
    >
      {!paymentDone && paymentDone != null ? (
        <h3 className="mainFont fontMedium color__white">
          Pay Deposit - ${deposit}
        </h3>
      ) : (
        ""
      )}
      {!paymentDone && paymentDone != null ? (
        <div className="alert alert-danger">{message}</div>
      ) : (
        ""
      )}
      {paymentDone ? (
        <h3 className="mainFont color__white fontBold">
          Thank you for your payment! You'll receive an email shortly!
        </h3>
      ) : (
        <div style={{ marginRight: "40px" }}>
          <CreditCard />
          <p className="mainFont color__white fontThin mt-2">
            Payment gateway by SquareUp
          </p>
        </div>
      )}
    </PaymentForm>
  );
};
const PaypalCheckoutButtonAlone = (props) => {
  const {
    product,
    deposit,
    teamCode,
    cName,
    cDob,
    cEmail,
    cPhone,
    date,
    eventId,
    kinName,
    secondPhone,
    diet,
  } = props;
  const [payPass, setPayPass] = useState(false);
  const handleApprove = () => {
    // No need to set payPass to true again here, you already did it in the onApprove callback
    // setPayPass(true);

    // Check if payPass is true before showing the alert
    if (payPass) {
      alert("Thank you for your payment!");
    }
  };

  return (
    <>
      {payPass ? (
        <h3 className="mainFont color__white fontBold">
          Thank you for your payment! <br /> You'll receive a confirmation email
          shortly!
        </h3>
      ) : (
        <PayPalButtons
          styles={{
            layout: "horizontal",
            height: 48,
            tagline: true,
            shape: "pill",
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: product.description,
                  amount: {
                    value: deposit,
                    currency_code: "AUD",
                  },
                },
              ],
            });
          }}
          onApprove={async (data, actions) => {
            const order = await actions.order.capture();
            // Set payPass to true when the payment is approved
            setPayPass(true);
            handleApprove(); // Call the handleApprove function
            console.log(order);
            axios
              .post("https://3.106.200.146:3001/joinAlone", {
                member_name: cName,
                member_dob: cDob,
                member_phone: cPhone,
                member_email: cEmail,
                description: diet,
                team_name: teamCode,
                kinName: kinName,
                event_date: date,
                event_id: eventId,
                secondPhone: secondPhone,
              })
              .then((e) => {
                console.log(e);
              });
          }}
          onError={(err) => {
            alert("An error occurred: " + err);
          }}
        />
      )}
    </>
  );
};
const TeamRegisterForm = ({
  styles,
  eventId,
  date,
  deposit,
  eventName,
  eventLocation,
}) => {
  const [teamName, setTeamName] = useState("");
  const [childName, setChildName] = useState("");
  const [childDob, setChildDob] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showPayment, setShowPayment] = useState(false);
  const [message, setMessage] = useState("");
  const [method, setMethod] = useState("");
  const [kinName, setKinName] = useState("");
  const [secondPhone, setSecondPhone] = useState("");
  const [showAg, setShowAg] = useState(false);
  const [waiver, setWaiver] = useState("");

  const [agreed, setAgreed] = useState(false);

  const [discountCode, setDiscountCode] = useState("");
  const [discountDone, setDiscountDone] = useState(false);

  const [newDeposit, setNewDeposit] = useState(9999);
  const [amountOff, setAmountOff] = useState(0);
  const [discountType, setDiscountType] = useState("");

  useEffect(() => {
    axios
      .get("https://3.106.200.146:3001/get-waiver/2")
      .then((response) => setWaiver(response.data.result[0].agreement));
  }, []);
  let product = {
    description:
      "Team registration for event_id: " + eventId + " date: " + date,
    price: deposit,
  };

  const getDiscount = () => {
    if (!discountDone) {
      axios
        .get("https://3.106.200.146:3001/check-discount/" + discountCode)
        .then((response) => {
          if (response.data.code == 99) {
            alert("Discount Code Is Incorrect!");
          } else {
            deposit = deposit - response.data.result[0].amount;
            setDiscountDone(true);
            setNewDeposit(deposit);
            setAmountOff(response.data.result[0].amount);
            setDiscountType(response.data.result[0].type);
          }
        });
    } else {
      alert("Discount already applied.");
    }
  };
  const addTeam = () => {
    if (
      teamName != "" &&
      childName != "" &&
      childDob != "" &&
      email != "" &&
      phone != "" &&
      kinName != "" &&
      secondPhone != ""
    ) {
      if (phone == secondPhone) {
        setMessage("Alternate Phone must be different from main number");
        setTimeout(() => setMessage(""), 2000);
      } else {
        if (method == "") {
          setMessage("Select a payment method.");
          setTimeout(() => setMessage(""), 2000);
        } else {
          setShowPayment(true);
        }
      }
    } else {
      setMessage("All fields are required");
      setTimeout(() => setMessage(""), 2000);
    }
  };

  return (
    <>
      <div className={`${styles.registerBox} mb-5`}>
        {showPayment && method == "square" ? (
          <MyPaymentForm
            deposit={discountDone ? newDeposit : deposit}
            teamName={teamName}
            cName={childName}
            cDob={childDob}
            cEmail={email}
            cPhone={phone}
            date={date}
            eventId={eventId}
            kinName={kinName}
            secondPhone={secondPhone}
          />
        ) : showPayment && method == "paypal" ? (
          <PaypalCheckoutButton
            product={product}
            deposit={discountDone ? newDeposit : deposit}
            teamName={teamName}
            cName={childName}
            cDob={childDob}
            cEmail={email}
            cPhone={phone}
            date={date}
            eventId={eventId}
            kinName={kinName}
            secondPhone={secondPhone}
          />
        ) : !showPayment ? (
          <>
            <h3 className="mainFont fontBold color__white">CREATE A TEAM!</h3>
            {message == "" ? (
              ""
            ) : (
              <div className="alert alert-danger">{message}</div>
            )}

            <div className="container-fluid" style={{ width: "100%" }}>
              <div className="row">
                <div
                  className="col-12 col-md-12 col-lg-6"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin  mt-3">
                      Team Name
                    </label>
                    <br />
                    <input
                      value={teamName}
                      onChange={(e) => setTeamName(e.target.value)}
                      placeholder="North Stars"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin mt-3">
                      Captain's Name
                    </label>
                    <br />
                    <input
                      value={childName}
                      placeholder="Henry"
                      onChange={(e) => setChildName(e.target.value)}
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin mt-3">
                      Captain's DOB
                    </label>
                    <br />
                    <input
                      type="date"
                      value={childDob}
                      onChange={(e) => setChildDob(e.target.value)}
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Email Address
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="examplemail@gmail.com"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Contact Number
                    </label>
                    <input
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="+61455520032"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Next Of Kin (Name)
                    </label>
                    <input
                      value={kinName}
                      onChange={(e) => setKinName(e.target.value)}
                      placeholder="Richard"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-12 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Alt Phone Number (different to main number)
                    </label>
                    <input
                      value={secondPhone}
                      placeholder="+61455520666"
                      onChange={(e) => setSecondPhone(e.target.value)}
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-12 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont">
                      Discount Code:
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        className="form-control mainFont color__white"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        style={{
                          background: "transparent",
                          color: "white",
                          borderRadius: "0px",
                        }}
                        disabled={discountDone}
                        value={discountCode}
                        onChange={(e) => setDiscountCode(e.target.value)}
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-light"
                          type="button"
                          style={{ borderRadius: "0px" }}
                          onClick={getDiscount}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="color__white mainFont">
              Total: {newDeposit === 9999 ? deposit : newDeposit}${" "}
              {newDeposit === 9999
                ? ""
                : `(${amountOff}${discountType === "fixed" ? "$" : "%"} Off)`}
            </h3>

            <div style={{ flexDirection: "row", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={agreed}
                onClick={() => setShowAg(true)}
              />
              <label
                className="color__white mainFont fontThin mt-2"
                style={{ marginLeft: "10px" }}
              >
                I agree to the terms and conditions
              </label>
            </div>
            <label className="color__grey mainFont fontThin mt-3 mb-2">
              Select Payment Method
            </label>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <input
                  type="radio"
                  onChange={(e) => setMethod(e.target.value)}
                  style={{ marginRight: "10px" }}
                  value="paypal"
                />
                <img src={PP_Logo} width={70} />
              </div>
              <div className="mx-4">
                <input
                  type="radio"
                  onChange={(e) => setMethod(e.target.value)}
                  style={{ marginRight: "10px" }}
                  value="square"
                />
                <img src={Mastercard} width={90} />
              </div>
              <div>
                <input
                  type="radio"
                  onChange={(e) => setMethod(e.target.value)}
                  style={{ marginRight: "10px" }}
                  value="square"
                />
                <img src={Visa} width={70} />
              </div>
            </div>

            <div>
              <button
                className="bg__blue px-5 color__white mainFont"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => {
                  addTeam();
                }}
              >
                PAY NOW
              </button>
            </div>
            <Modal show={showAg} onHide={() => setShowAg(!showAg)} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Terms & Conditions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  dangerouslySetInnerHTML={{
                    __html: waiver
                      .replace("[Your Event Name]", eventName)
                      .replace("[Event Date]", date)
                      .replace("[Event Location]", eventLocation),
                  }}
                  style={{
                    height: "400px",
                    overflow: "auto",
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setAgreed(true);
                    setShowAg(false);
                  }}
                >
                  I Agree
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setAgreed(false);
                    setShowAg(false);
                  }}
                >
                  Decline
                </button>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
const TeamJoinForm = ({
  styles,
  eventId,
  date,
  deposit,
  eventName,
  eventLocation,
}) => {
  const [teamCode, setTeamCode] = useState("");
  const [childName, setChildName] = useState();
  const [childDob, setChildDob] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showPayment, setShowPayment] = useState(false);
  const [message, setMessage] = useState("");
  const [diet, setDiet] = useState("");
  const [joined, setJoined] = useState(false);
  const [kinName, setKinName] = useState("");
  const [secondPhone, setSecondPhone] = useState("");
  const [method, setMethod] = useState("");

  const [showAg, setShowAg] = useState(false);
  const [waiver, setWaiver] = useState("");

  const [agreed, setAgreed] = useState(false);

  const [discountCode, setDiscountCode] = useState("");
  const [discountDone, setDiscountDone] = useState(false);

  const [newDeposit, setNewDeposit] = useState(9999);
  const [amountOff, setAmountOff] = useState(0);
  const [discountType, setDiscountType] = useState("");

  let product = {
    price: deposit,
    description: "Team joined team code : " + teamCode,
  };

  useEffect(() => {
    axios
      .get("https://3.106.200.146:3001/get-waiver/2")
      .then((response) => setWaiver(response.data.result[0].agreement));
  }, []);

  const getDiscount = () => {
    if (!discountDone) {
      axios
        .get("https://3.106.200.146:3001/check-discount/" + discountCode)
        .then((response) => {
          if (response.data.code == 99) {
            alert("Discount Code Is Incorrect!");
          } else {
            deposit = deposit - response.data.result[0].amount;
            setDiscountDone(true);
            setNewDeposit(deposit);
            setAmountOff(response.data.result[0].amount);
            setDiscountType(response.data.result[0].type);
          }
        });
    } else {
      alert("Discount already applied.");
    }
  };

  const addTeam = () => {
    if (
      teamCode != "" &&
      childName != "" &&
      childDob != "" &&
      email != "" &&
      phone != "" &&
      kinName != "" &&
      secondPhone != ""
    ) {
      axios
        .post("https://3.106.200.146:3001/checkCode", {
          code: teamCode,
        })
        .then((response) => {
          if (response.data.code == 0) {
            setMessage("Incorrect Team Code");
            setTimeout(() => setMessage(""), 2000);
          } else {
            if (phone == secondPhone) {
              setMessage(
                "Alternate contact must be different from main contact"
              );
              setTimeout(() => setMessage(""), 2000);
            } else {
              if (method == "") {
                setMessage("Select a payment method");
                setTimeout(() => setMessage(""), 2000);
              } else {
                setShowPayment(true);
              }
            }
          }
        });
    } else {
      setMessage("All fields are required");
      setTimeout(() => setMessage(""), 2000);
    }
  };

  return (
    <>
      <div className={`${styles.registerBox} mb-5`}>
        {!showPayment ? (
          <>
            <h3 className="mainFont fontMedium color__white">
              JOIN YOUR TEAM!
            </h3>
            {message == "" ? (
              ""
            ) : (
              <div
                className="alert alert-danger"
                style={{ marginRight: "20px" }}
              >
                {message}
              </div>
            )}
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-12 col-md-12 col-lg-6"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin mt-3">
                      Team Code
                    </label>
                    <br />
                    <input
                      value={teamCode}
                      placeholder="555BHK1"
                      onChange={(e) => setTeamCode(e.target.value)}
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin mt-3">
                      Child Name
                    </label>
                    <br />
                    <input
                      value={childName}
                      placeholder="Henry"
                      onChange={(e) => setChildName(e.target.value)}
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 "
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin mt-3">
                      Child DOB
                    </label>
                    <br />
                    <input
                      type="date"
                      value={childDob}
                      onChange={(e) => setChildDob(e.target.value)}
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Email Address
                    </label>
                    <br />
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="youremail@gmail.com"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Contact Number
                    </label>
                    <br />
                    <input
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="+61455544737"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Next Of Kin (Name)
                    </label>
                    <br />
                    <input
                      value={kinName}
                      onChange={(e) => setKinName(e.target.value)}
                      placeholder="Richard"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-12 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Alt Phone Number (different to main number)
                    </label>
                    <br />
                    <input
                      value={secondPhone}
                      onChange={(e) => setSecondPhone(e.target.value)}
                      placeholder="+61455544111"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingRight: "20px" }}>
              <label className="color__white mainFont fontThin">
                Dietary Requirements / Allergies
              </label>
              <textarea
                style={{
                  background: "transparent",
                  width: "100%",
                  border: "1px solid white",
                  height: "150px",
                }}
                className="color__white"
                value={diet}
                onChange={(e) => setDiet(e.target.value)}
              ></textarea>
            </div>
            <div
              className="col-12 col-md-12 col-lg-12 mt-3"
              style={{ paddingLeft: "0px" }}
            >
              <div>
                <label className="color__white mainFont">Discount Code:</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    className="form-control mainFont color__white"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    style={{
                      background: "transparent",
                      color: "white",
                      borderRadius: "0px",
                    }}
                    disabled={discountDone}
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-light"
                      type="button"
                      style={{ borderRadius: "0px" }}
                      onClick={getDiscount}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="color__white mainFont">
              Total: {newDeposit === 9999 ? deposit : newDeposit}${" "}
              {newDeposit === 9999
                ? ""
                : `(${amountOff}${discountType === "fixed" ? "$" : "%"} Off)`}
            </h3>
            <div style={{ flexDirection: "row", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={agreed}
                onClick={() => setShowAg(true)}
              />
              <label
                className="color__white mainFont fontThin mt-2"
                style={{ marginLeft: "10px" }}
              >
                I agree to the terms and conditions
              </label>
            </div>
            <label className="color__grey mainFont fontThin mt-3 mb-2">
              Select Payment Method
            </label>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <input
                  type="radio"
                  onChange={(e) => setMethod(e.target.value)}
                  style={{ marginRight: "10px" }}
                  value="paypal"
                />
                <img src={PP_Logo} width={70} />
              </div>
              <div className="mx-4">
                <input
                  type="radio"
                  onChange={(e) => setMethod(e.target.value)}
                  style={{ marginRight: "10px" }}
                  value="square"
                />
                <img src={Mastercard} width={90} />
              </div>
              <div>
                <input
                  type="radio"
                  onChange={(e) => setMethod(e.target.value)}
                  style={{ marginRight: "10px" }}
                  value="square"
                />
                <img src={Visa} width={70} />
              </div>
            </div>
            <div>
              <button
                className="bg__blue px-5 color__white mainFont"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => {
                  addTeam();
                }}
              >
                PAY NOW
              </button>
            </div>
            <Modal show={showAg} onHide={() => setShowAg(!showAg)} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Terms & Conditions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  dangerouslySetInnerHTML={{
                    __html: waiver
                      .replace("[Your Event Name]", eventName)
                      .replace("[Event Date]", date)
                      .replace("[Event Location]", eventLocation),
                  }}
                  style={{
                    height: "400px",
                    overflow: "auto",
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setAgreed(true);
                    setShowAg(false);
                  }}
                >
                  I Agree
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setAgreed(false);
                    setShowAg(false);
                  }}
                >
                  Decline
                </button>
              </Modal.Footer>
            </Modal>
          </>
        ) : showPayment && method == "square" ? (
          <MyPaymentFormJoin
            deposit={discountDone ? newDeposit : deposit}
            teamCode={teamCode}
            cName={childName}
            cDob={childDob}
            cEmail={email}
            cPhone={phone}
            date={date}
            eventId={eventId}
            kinName={kinName}
            secondPhone={secondPhone}
            diet={diet}
          />
        ) : (
          <PaypalCheckoutButtonSecond
            product={product}
            deposit={discountDone ? newDeposit : deposit}
            teamCode={teamCode}
            cName={childName}
            cDob={childDob}
            cEmail={email}
            cPhone={phone}
            date={date}
            eventId={eventId}
            kinName={kinName}
            secondPhone={secondPhone}
            diet={diet}
          />
        )}
      </div>
    </>
  );
};
const JoinAlone = ({
  styles,
  eventId,
  date,
  deposit,
  eventName,
  eventLocation,
}) => {
  const [teamCode, setTeamCode] = useState("");
  const [childName, setChildName] = useState();
  const [childDob, setChildDob] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showPayment, setShowPayment] = useState(false);
  const [message, setMessage] = useState("");
  const [diet, setDiet] = useState("");
  const [joined, setJoined] = useState(false);
  const [kinName, setKinName] = useState("");
  const [secondPhone, setSecondPhone] = useState("");
  const [method, setMethod] = useState("");

  const [showAg, setShowAg] = useState(false);
  const [waiver, setWaiver] = useState("");

  const [agreed, setAgreed] = useState(false);

  const [discountCode, setDiscountCode] = useState("");
  const [discountDone, setDiscountDone] = useState(false);

  const [newDeposit, setNewDeposit] = useState(9999);
  const [amountOff, setAmountOff] = useState(0);
  const [discountType, setDiscountType] = useState("");

  let product = {
    price: deposit,
    description: "Team joined team code : " + teamCode,
  };

  useEffect(() => {
    axios
      .get("https://3.106.200.146:3001/get-waiver/2")
      .then((response) => setWaiver(response.data.result[0].agreement));
  }, []);

  const getDiscount = () => {
    if (!discountDone) {
      axios
        .get("https://3.106.200.146:3001/check-discount/" + discountCode)
        .then((response) => {
          if (response.data.code == 99) {
            alert("Discount Code Is Incorrect!");
          } else {
            deposit = deposit - response.data.result[0].amount;
            setDiscountDone(true);
            setNewDeposit(deposit);
            setAmountOff(response.data.result[0].amount);
            setDiscountType(response.data.result[0].type);
          }
        });
    } else {
      alert("Discount already applied.");
    }
  };

  const addTeam = () => {
    if (
      teamCode != "" &&
      childName != "" &&
      childDob != "" &&
      email != "" &&
      phone != "" &&
      kinName != "" &&
      secondPhone != ""
    ) {
      if (phone == secondPhone) {
        setMessage("Alternate contact must be different from main contact");
        setTimeout(() => setMessage(""), 2000);
      } else {
        if (method == "") {
          setMessage("Select a payment method");
          setTimeout(() => setMessage(""), 2000);
        } else {
          setShowPayment(true);
        }
      }
    } else {
      setMessage("All fields are required");
      setTimeout(() => setMessage(""), 2000);
    }
  };

  return (
    <>
      <div className={`${styles.registerBox} mb-5`}>
        {!showPayment ? (
          <>
            <h3 className="mainFont fontMedium color__white">
              REGISTER INDIVIDUALLY!
            </h3>
            {message == "" ? (
              ""
            ) : (
              <div
                className="alert alert-danger"
                style={{ marginRight: "20px" }}
              >
                {message}
              </div>
            )}
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-12 col-md-12 col-lg-6"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin mt-3">
                      Child's School / Sports Team
                    </label>
                    <br />
                    <input
                      value={teamCode}
                      placeholder="West Public School"
                      onChange={(e) => setTeamCode(e.target.value)}
                      className="w-100"
                    />
                    <br />
                    <span className="color__white">
                      {" "}
                      *by providing your child’s school or sport team helps us
                      allocate them to teams with kids they may know
                    </span>
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin mt-3">
                      Child Name
                    </label>
                    <br />
                    <input
                      value={childName}
                      placeholder="Henry"
                      onChange={(e) => setChildName(e.target.value)}
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 "
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin mt-3">
                      Child DOB
                    </label>
                    <br />
                    <input
                      type="date"
                      value={childDob}
                      onChange={(e) => setChildDob(e.target.value)}
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Email Address
                    </label>
                    <br />
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="youremail@gmail.com"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Contact Number
                    </label>
                    <br />
                    <input
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="+61455544737"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Next Of Kin (Name)
                    </label>
                    <br />
                    <input
                      value={kinName}
                      onChange={(e) => setKinName(e.target.value)}
                      placeholder="Richard"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-12 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Alt Phone Number (different to main number)
                    </label>
                    <br />
                    <input
                      value={secondPhone}
                      onChange={(e) => setSecondPhone(e.target.value)}
                      placeholder="+61455544111"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingRight: "20px" }}>
              <label className="color__white mainFont fontThin">
                Dietary Requirements / Allergies
              </label>
              <textarea
                style={{
                  background: "transparent",
                  width: "100%",
                  border: "1px solid white",
                  height: "150px",
                }}
                className="color__white"
                value={diet}
                onChange={(e) => setDiet(e.target.value)}
              ></textarea>
            </div>
            <div
              className="col-12 col-md-12 col-lg-12 mt-3"
              style={{ paddingLeft: "0px" }}
            >
              <div>
                <label className="color__white mainFont">Discount Code:</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    className="form-control mainFont color__white"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    style={{
                      background: "transparent",
                      color: "white",
                      borderRadius: "0px",
                    }}
                    disabled={discountDone}
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-light"
                      type="button"
                      style={{ borderRadius: "0px" }}
                      onClick={getDiscount}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="color__white mainFont">
              Total: {newDeposit === 9999 ? deposit : newDeposit}${" "}
              {newDeposit === 9999
                ? ""
                : `(${amountOff}${discountType === "fixed" ? "$" : "%"} Off)`}
            </h3>
            <div style={{ flexDirection: "row", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={agreed}
                onClick={() => setShowAg(true)}
              />
              <label
                className="color__white mainFont fontThin mt-2"
                style={{ marginLeft: "10px" }}
              >
                I agree to the terms and conditions
              </label>
            </div>
            <label className="color__grey mainFont fontThin mt-3 mb-2">
              Select Payment Method
            </label>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <input
                  type="radio"
                  onChange={(e) => setMethod(e.target.value)}
                  style={{ marginRight: "10px" }}
                  value="paypal"
                />
                <img src={PP_Logo} width={70} />
              </div>
              <div className="mx-4">
                <input
                  type="radio"
                  onChange={(e) => setMethod(e.target.value)}
                  style={{ marginRight: "10px" }}
                  value="square"
                />
                <img src={Mastercard} width={90} />
              </div>
              <div>
                <input
                  type="radio"
                  onChange={(e) => setMethod(e.target.value)}
                  style={{ marginRight: "10px" }}
                  value="square"
                />
                <img src={Visa} width={70} />
              </div>
            </div>
            <div>
              <button
                className="bg__blue px-5 color__white mainFont"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => {
                  addTeam();
                }}
              >
                PAY NOW
              </button>
            </div>
            <Modal show={showAg} onHide={() => setShowAg(!showAg)} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Terms & Conditions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  dangerouslySetInnerHTML={{
                    __html: waiver
                      .replace("[Your Event Name]", eventName)
                      .replace("[Event Date]", date)
                      .replace("[Event Location]", eventLocation),
                  }}
                  style={{
                    height: "400px",
                    overflow: "auto",
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setAgreed(true);
                    setShowAg(false);
                  }}
                >
                  I Agree
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setAgreed(false);
                    setShowAg(false);
                  }}
                >
                  Decline
                </button>
              </Modal.Footer>
            </Modal>
          </>
        ) : showPayment && method == "square" ? (
          <MyPaymentFormAlone
            deposit={discountDone ? newDeposit : deposit}
            teamCode={teamCode}
            cName={childName}
            cDob={childDob}
            cEmail={email}
            cPhone={phone}
            date={date}
            eventId={eventId}
            kinName={kinName}
            secondPhone={secondPhone}
            diet={diet}
          />
        ) : (
          <PaypalCheckoutButtonAlone
            product={product}
            deposit={discountDone ? newDeposit : deposit}
            teamCode={teamCode}
            cName={childName}
            cDob={childDob}
            cEmail={email}
            cPhone={phone}
            date={date}
            eventId={eventId}
            kinName={kinName}
            secondPhone={secondPhone}
            diet={diet}
          />
        )}
      </div>
    </>
  );
};
const TeamBox = ({
  styles,
  eventId,
  date,
  deposit,
  role,
  eventName,
  eventLocation,
}) => {
  const [registerTeam, setRegisterTeam] = useState(false);
  const [joinTeam, setJoinTeam] = useState(false);
  const [joinAlone, setJoinAlone] = useState(false);
  return registerTeam && joinTeam === false ? (
    <TeamRegisterForm
      styles={styles}
      eventId={eventId}
      date={date}
      deposit={deposit}
      eventName={eventName}
      eventLocation={eventLocation}
    />
  ) : joinTeam && registerTeam === false ? (
    <TeamJoinForm
      styles={styles}
      eventId={eventId}
      date={date}
      deposit={deposit}
      eventName={eventName}
      eventLocation={eventLocation}
    />
  ) : joinAlone && registerTeam === false && joinTeam == false ? (
    <JoinAlone
      styles={styles}
      eventId={eventId}
      date={date}
      deposit={deposit}
      eventName={eventName}
      eventLocation={eventLocation}
    />
  ) : (
    <div className={`${styles.selectContainer}`}>
      <h2 className="color__white mainFont fontSemiBold text-left">
        ARE YOU A CAPTAIN <br /> OF A TEAM?
      </h2>
      <div>
        <button
          className={`${styles.filledBtn} mainFont`}
          onClick={() => {
            setRegisterTeam(true);
            setJoinTeam(false);
            setJoinAlone(false);
            role(1);
          }}
        >
          YES! CREATE A NEW TEAM
        </button>
      </div>
      <button
        className={`${styles.orangeFilledBtn} mainFont`}
        onClick={() => {
          setJoinTeam(true);
          setRegisterTeam(false);
          setJoinAlone(false);
          role(2);
        }}
      >
        JOIN AN EXISTING TEAM
      </button>
      <br />
      <button
        className={`${styles.filledBtn} mainFont`}
        onClick={() => {
          setRegisterTeam(false);
          setJoinTeam(false);
          setJoinAlone(true);
          role(4);
        }}
      >
        REGISTER AS AN INDIVIDUAL
      </button>
    </div>
  );
};

export default TeamBox;
