import React, { useState } from "react";
import styles from "./memberlogin.module.css";
import Logo from "../../images/logo.png";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
const MemberLogin = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  return (
    <>
      <div className={`${styles.memberContainer}`}>
        <div className={`${styles.loginBox}`}>
          <center>
            <img src={Logo} width="200" />
          </center>
          <center>
            <h3 className="mainFont">Log In</h3>
            <p className="mainFont fontRegular color__grey">
              Enter Your Email Address To Log In Into The Dashboard <br /> And
              Check The Events You Are Enrolled In
            </p>
          </center>
          <div>
            <label className="mainFont">Enter your Email Address</label>
            <br />
            <input
              className="w-100"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="mainFont bg__black color__white"
              onClick={() => {
                localStorage.setItem("member_email", email);
                navigate("/member-dashboard");
              }}
            >
              Log In
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MemberLogin;
