import React, { useEffect, useState } from "react";
import styles from "./Events.module.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
const ViewBooking = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchEventName, setSearchEventName] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [searchDeposit, setSearchDeposit] = useState("");
  const navigate = useNavigate();
  const { bookingId } = useParams();
  useEffect(() => {
    axios
      .get("https://3.106.200.146:3001/getWeeklyEventsById/" + bookingId)
      .then((response) => {
        setLoading(false);
        setEvents(response.data.result);
      });
  }, []);
  return (
    <>
      <div className="container">
        <h3 className="mainFont">All Weekly Trainings</h3>
      </div>

      <div className={`${styles.tableContainer} container`}>
        <table className={`${styles.customTable} table`}>
          <thead>
            <tr className="mainFont fontThin">
              <th scope="col">Child Name</th>
              <th scope="col">Child DOB</th>
              <th scope="col">Child Email</th>
              <th scope="col">Diet</th>
              <th scope="col">Event Date</th>
              <th scope="col">Deposit</th>
              <th scope="col">Alt. Contact</th>
              <th scope="col">Next Of Kin</th>
            </tr>
          </thead>
          <tbody>
            {!loading
              ? events.map((e) => (
                  <tr key={e.id} className="mainFont fontThin">
                    <td style={{ paddingTop: "15px" }}>{e.child_name}</td>
                    <td style={{ paddingTop: "15px" }}>{e.child_dob}</td>
                    <td style={{ paddingTop: "15px" }}>{e.child_email}</td>
                    <td style={{ paddingTop: "15px" }}>{e.diet}</td>
                    <td style={{ paddingTop: "15px" }}>{e.event_date}</td>
                    <td style={{ paddingTop: "15px" }}>{e.deposit}</td>
                    <td>{e.secondPhone}</td>
                    <td>{e.kin}</td>
                  </tr>
                ))
              : "Loading..."}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ViewBooking;
