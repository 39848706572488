import React, { useEffect, useState } from "react";
import styles from "./AddEvent.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const AddEvent = () => {
  const [status, setStatus] = useState("none");
  const [dates, setDates] = useState([]);
  const [eventTitle, setEventTitle] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [deposit, setDeposit] = useState(0);
  const navigate = useNavigate();
  const initialFormData = {
    eventDate: "",
    relation: "only",
    year_one: "",
    year_two: "",
  };
  const addDates = () => {
    setDates([...dates, { id: dates.length, data: { ...initialFormData } }]);
  };

  const sendEventData = () => {
    let insertedId = 0;
    setStatus("Inserting Event...");
    axios
      .post("https://3.106.200.146:3001/addevent", {
        event_name: eventTitle,
        event_location: eventLocation,
        event_description: eventDescription,
        deposit: deposit,
      })
      .then((response) => {
        setStatus("Adding Dates");
        insertedId = response.data.insertedId;
        for (let i = 0; i < dates.length; i++) {
          axios
            .post("https://3.106.200.146:3001/add-date", {
              event_id: insertedId,
              event_date: dates[i].data.eventDate,
              age_from: dates[i].data.year_one,
              age_relation: dates[i].data.relation,
              age_to: dates[i].data.year_two,
            })
            .then((response) => navigate("/admin/events"));
        }
        navigate("/admin/events");
        setStatus("none");
      });
  };
  const handleFormChange = (formId, fieldName, value) => {
    setDates(
      dates.map((form) => {
        if (form.id === formId) {
          return {
            ...form,
            data: {
              ...form.data,
              [fieldName]: value,
            },
          };
        }
        return form;
      })
    );
  };
  useEffect(() => {
    console.log(dates);
  }, [dates]);
  return (
    <div className="container-fluid mt-5">
      <div className={`${styles.formBackground}`}>
        <h5 className="mainFont fontMediumBold">Event Details</h5>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Event Title</label>
          <input
            className={`${styles.eventForm} w-100`}
            value={eventTitle}
            onChange={(e) => setEventTitle(e.target.value)}
          />
        </div>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Event Location</label>
          <input
            className={`${styles.eventForm} w-100`}
            placeholder="Mackillop Ova"
            value={eventLocation}
            onChange={(e) => setEventLocation(e.target.value)}
          />
        </div>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Event Description</label>
          <textarea
            className={`${styles.eventForm} w-100`}
            value={eventDescription}
            onChange={(e) => setEventDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="d-flex justify-content-end">
          <button
            onClick={addDates}
            className="bg__blue color__white mainFont btn btn-primary"
            style={{ border: "none" }}
          >
            Add Date
          </button>
        </div>
      </div>

      {dates.map((form) => (
        <div key={form.id}>
          <DatesForm
            formData={form.data}
            formId={form.id}
            onFormChange={handleFormChange}
          />
        </div>
      ))}
      <div className={`${styles.formBackground} mt-3 mb-3`}>
        <h5 className="mainFont fontMediumBold">Deposit Details</h5>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Amount</label>
          <input
            className={`${styles.eventForm} w-100`}
            placeholder="30"
            value={deposit}
            onChange={(e) => setDeposit(e.target.value)}
          />
        </div>
      </div>
      <center>
        <button className="btn btn-primary px-5 mb-5" onClick={sendEventData}>
          {status == "none" ? "Add Event" : status}
        </button>
      </center>
    </div>
  );
};

const DatesForm = ({ formData, formId, onFormChange }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onFormChange(formId, name, value);
  };

  return (
    <div className={`${styles.formBackground} mt-3`}>
      <h5 className="mainFont fontMediumBold">Date Details</h5>
      <div className="w-100 mt-3">
        <label className="mainFont fontThin">Event Date</label>
        <input
          className={`${styles.eventForm} w-100`}
          type="date"
          name="eventDate"
          value={formData.eventDate}
          onChange={handleInputChange}
        />
      </div>
      <div className="w-100 mt-3">
        <label className="mainFont fontThin">Age Group</label>
        <div className="d-flex">
          <select
            className={`${styles.eventForm}`}
            style={{ marginRight: "10px" }}
            name="relation"
            value={formData.relation}
            onChange={handleInputChange}
          >
            <option value="Only">Only</option>
            <option value="And">And</option>
            <option value="Between">Between</option>
          </select>
          <input
            className={`${styles.eventForm}`}
            style={{ marginRight: "10px" }}
            type="number"
            name="year_one"
            placeholder="Min Age"
            value={formData.year_one}
            onChange={handleInputChange}
          />
          <input
            className={`${styles.eventForm}`}
            style={{ marginRight: "10px" }}
            type="number"
            name="year_two"
            placeholder="Max Age"
            value={formData.year_two}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEvent;
