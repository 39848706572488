import React from "react";

import styles from "./Footer.module.css";
import Logo from "../images/logo.png";
import callButton from "../images/call_btn.svg";
import mailButton from "../images/mail.png";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer className={`${styles.footerContainerDesktop}`}>
        <div>
          <center>
            <img src={Logo} width={200} />
          </center>
          <div
            className="container d-flex justify-content-between mt-5"
            style={{ width: "880px" }}
          >
            <p
              className="mainFont color__white"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            >
              Home
            </p>
            <p
              className="mainFont color__white"
              onClick={() => navigate("/gameday")}
              style={{ cursor: "pointer" }}
            >
              Gameday
            </p>
            <p
              className="mainFont color__white"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/weekly-training")}
            >
              Weekly Events
            </p>
            <p
              className="mainFont color__white"
              onClick={() => navigate("/about")}
              style={{ cursor: "pointer" }}
            >
              About
            </p>
            <p
              className="mainFont color__white"
              onClick={() => navigate("/faq")}
              style={{ cursor: "pointer" }}
            >
              FAQs
            </p>
            <p
              className="mainFont color__white"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/member-login")}
            >
              Sign In
            </p>
          </div>
          <div className="container d-flex justify-content-between mt-5">
            <div className="d-flex">
              <div
                className="d-flex"
                style={{ alignItems: "center", marginRight: "50px" }}
              >
                <img src={callButton} width={70} />
                <div style={{ marginLeft: "10px" }}>
                  <p
                    className="color__white mainFont"
                    style={{ lineHeight: "10px", marginTop: "20px" }}
                  >
                    Have a question?
                  </p>
                  <h5
                    className="color__orange mainFont fontBold"
                    style={{ lineHeight: "20px" }}
                  >
                    <a href="tel:0431192867" className="color__orange">
                      0431192867
                    </a>
                  </h5>
                </div>
              </div>
              <div
                className="d-flex"
                style={{ alignItems: "center", marginRight: "50px" }}
              >
                <img src={mailButton} width={70} />
                <div style={{ marginLeft: "10px" }}>
                  <p
                    className="color__white mainFont"
                    style={{ lineHeight: "4px", marginTop: "20px" }}
                  >
                    Contact us at
                  </p>
                  <h5
                    className="color__orange mainFont fontBold"
                    style={{ lineHeight: "20px" }}
                  >
                    <a
                      href="mailto:growingathletes1@gmail.com"
                      className="color__orange"
                    >
                      growingathletes1@gmail.com
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div>
              <p className="mainFont color__white mt-3">
                © 2023, All Rights Reserved
              </p>
              <p style={{ textAlign: "right" }}>
                <a
                  href="https://rmarketingm8.com/"
                  className="color__white mainFont"
                >
                  Powered By MarketingM8
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <footer
        className={`${styles.footerMobileContainer}`}
        style={{ paddingBottom: "50px" }}
      >
        <div className={`${styles.linksContainer}`}>
          <p className="mainFont" onClick={() => navigate("/")}>
            Home
          </p>
          <p className="mainFont" onClick={() => navigate("/gameday")}>
            Booking Page
          </p>
          <p className="mainFont" onClick={() => navigate("/gameday")}>
            Gameday
          </p>
        </div>
        <div className={`${styles.linksContainer2}`}>
          <p className="mainFont" onClick={() => navigate("/about")}>
            About
          </p>
          <p className="mainFont" onClick={() => navigate("/weekly-training")}>
            Weekly Events
          </p>
          <p className="mainFont" onClick={() => navigate("/faq")}>
            FAQs
          </p>
        </div>
        <div className={`${styles.contactInfo}`}>
          <div>
            <img src={callButton} />
            <div>
              <p className="mainFont color__white">Have a question?</p>
              <h3 className="mainFont color__orange">0431192867</h3>
            </div>
          </div>
          <div>
            <img src={callButton} />
            <div>
              <p className="mainFont color__white">Contact us at</p>
              <h3 className="mainFont color__orange">
                growingathletes1@gmail.com
              </h3>
            </div>
          </div>
        </div>
        <center>
          <img src={Logo} width={100} className="mt-4 mb-4" />
        </center>
        <center>
          <p className="color__white mainFont fontThin">
            © 2023, All Rights Reserved
          </p>
          <a
            className="color__white mainFont fontThin"
            href="https://rmarketingm8.com/"
          >
            Powered by MarketingM8
          </a>
        </center>
      </footer>
    </>
  );
};

export default Footer;
