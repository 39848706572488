import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import styles from "./gameday.module.css";
import { BlueBgButtonRounded } from "../../components/Buttons";
import MainImage from "../../images/gameday_image.png";
import Basketball from "../../images/game_basket.png";
import TeamIcon from "../../images/team__icon.svg";
import Frisbee from "../../images/game_frisbee.png";
import KickBasketball from "../../images/game_kball.png";
import CarIcon from "../../images/car_image.PNG";
import GiftIcon from "../../images/gift_icon.PNG";
import GameFrisbee from "../../images/gameday_frisbee.png";
import Oztag from "../../images/oztag.png";
import MapIcon from "../../images/location_btn.png";
import axios from "axios";
import Footer from "../../components/Footer";
import { Navigate, useNavigate } from "react-router-dom";

import WhistleIcon from "../../images/whistle.svg";
import ClockIcon from "../../images/alarm.svg";
import BasketballOne from "../../images/football.svg";
import Carousel from "react-grid-carousel";
import Soccer from "../../images/Soccer.png";
import NFL from "../../images/NFL.png";
function formatDate(inputDate) {
  const options = { day: "numeric", month: "long" };
  const date = new Date(inputDate);
  const formattedDate = date.toLocaleDateString(undefined, options);
  return formattedDate;
}

const GamePageLead = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [collapsedState, setCollapsedState] = useState({});

  const [childName, setChildName] = useState("");
  const [parentName, setParentName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [leadDone, setLeadDone] = useState(false);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    axios.get("https://3.106.200.146:3001/allEventsSecond").then((response) => {
      setEvents(response.data.result);
      setLoading(false);
    });
  }, []);
  const navigate = useNavigate();
  const toggleCollapse = (eventIndex) => {
    setCollapsedState((prevState) => ({
      ...prevState,
      [eventIndex]: !prevState[eventIndex],
    }));
  };

  const handleScroll = () => {
    let element = document.getElementById("gameDaySection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Navbar />
      <div style={{ position: "relative", overflow: "hidden" }}>
        <img src={WhistleIcon} className={`${styles.whistle}`} />
        <img src={BasketballOne} className={`${styles.b_one}`} />
        <img src={ClockIcon} className={`${styles.clock}`} />
        <img src={MainImage} className={`${styles.mainImage}`} />
        <div className="container mb-5">
          <h1
            className={`${styles.mainHeading} color__orange mainFont fontBold`}
          >
            Put Your Skills To The <br /> Test On Game Day!
          </h1>
          <div className={`${styles.hero__paragraph}`}>
            <p className="mainFont color__white" style={{ textAlign: "right" }}>
              Come and join us in the school holidays for a day out playing
              sport with your friends. It’s a full day of fun, games, prizes and
              surprises!
              <br /> Join as a team or individually, but there’s limited spots
              so be quick! More details and dates available below.
            </p>
          </div>
          <div
            className={`${styles.blueButton} d-flex justify-content-end mt-4`}
            onClick={handleScroll}
          >
            <BlueBgButtonRounded text="LET'S DO IT!" external="yes" />
          </div>
          <div className={`${styles.gap}`}></div>
        </div>
        <div className={`${styles.desktopBlur}`}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className={`${styles.box}`}>
                  <img src={TeamIcon} width="120" />
                  <div>
                    <h2 className="color__white mainFont fontBold">Team Up!</h2>
                    <p className="color__white mainFont fontThin">
                      Get your friends together and book in for a day of School
                      Holiday Fun!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className={`${styles.box}`}>
                  <img src={CarIcon} width="120" />
                  <div>
                    <h2 className="color__white mainFont fontBold">
                      Pick-Up & <br /> Drop-off
                    </h2>
                    <p className="color__white mainFont fontThin">
                      Drop off 8:30am
                      <br />
                      Pickup 3:30pm
                      <br /> <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className={`${styles.box}`}>
                  <img src={GiftIcon} width="120" />
                  <div>
                    <h2 className="color__white mainFont fontBold">
                      Additional <br /> Perks
                    </h2>
                    <p className="color__white mainFont fontThin">
                      Lunch provided.
                      <br /> <br /> Every team member receives a sports gift
                      upon arrival! <br /> <br /> There’s also a surprise fun
                      activity to end the day.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${styles.availContainer} container`}
          style={{ marginTop: "150px !important", marginBottom: "150px" }}
        >
          <center>
            <h2 className="mainFont color__blue fontBold">
              Choose A Game Day!
            </h2>
          </center>
          <div
            className="row mt-5 mb-5 justify-content-center"
            id="gameDaySection"
          >
            <div className="col-12 col-md-6 mb-3">
              <div className={`${styles.availBox}`}>
                {leadDone ? (
                  <h1 className="mainFont color__white fontBold">
                    You have been registered successfully!
                  </h1>
                ) : (
                  <>
                    {" "}
                    <div className="d-flex flex-column">
                      <label className="mainFont color__white fontThin">
                        Child's Name
                      </label>
                      <input
                        className={`${styles.leadInput}`}
                        value={childName}
                        onChange={(e) => setChildName(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <label className="mainFont color__white fontThin">
                        Parent Name
                      </label>
                      <input
                        className={`${styles.leadInput}`}
                        value={parentName}
                        onChange={(e) => setParentName(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <label className="mainFont color__white fontThin">
                        Email Address
                      </label>
                      <input
                        className={`${styles.leadInput}`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <label className="mainFont color__white fontThin">
                        Phone Number
                      </label>
                      <input
                        className={`${styles.leadInput}`}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div>
                      <button
                        className={`${styles.leadButton} bg__blue px-5 color__white mainFont mt-3`}
                        data-toggle="modal"
                        data-target="#exampleModal"
                        onClick={() => {
                          if (
                            childName == "" ||
                            parentName == "" ||
                            phone == "" ||
                            email == ""
                          ) {
                            alert("All fields are required!");
                          } else {
                            setLoad(true);
                            axios
                              .post("https://3.106.200.146:3001/add-lead", {
                                childName: childName,
                                parentName: parentName,
                                email: email,
                                phone: phone,
                              })
                              .then((response) => {
                                setLeadDone(true);
                                setLoad(false);
                              });
                          }
                        }}
                      >
                        {load ? "Please wait..." : "Submit"}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.toPlayContainer} container`}>
          <center>
            <h2 className="mainFont color__blue fontBold">Sports To Play</h2>
            <br />
          </center>
        </div>
      </div>
      <div className={`${styles.desktopCarousel} container`}>
        <Carousel cols={3} rows={1} gap={10} loop>
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="70%" src={Frisbee} />
              <h2 className="text-center color__white mainFont mt-3">
                Frisbee
              </h2>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="70%" src={GameFrisbee} />
              <h2 className="text-center color__white mainFont mt-3">
                Dodgeball
              </h2>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="70%" src={Oztag} />
              <h2 className="text-center color__white mainFont mt-3">
                Tag Football
              </h2>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="70%" src={Soccer} />
              <h2 className="text-center color__white mainFont mt-3">Soccer</h2>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="70%" src={NFL} />
              <h2 className="text-center color__white mainFont mt-3">NFL</h2>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="70%" src={Basketball} />
              <h2 className="text-center color__white mainFont mt-3">
                Basketball
              </h2>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div
        style={{
          overflow: "auto",
          marginLeft: "10px",
          marginBottom: "100px",
        }}
        className={`${styles.mobileCarousel}`}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <img width="200px" src={Frisbee} />
          <h2 className="text-center color__white mainFont mt-3">Frisbee</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <img width="200px" src={GameFrisbee} />
          <h2 className="text-center color__white mainFont mt-3">Dodgeball</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <img width="200px" src={Oztag} />
          <h2 className="text-center color__white mainFont mt-3">
            Tag Football
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <img width="200px" src={Soccer} />
          <h2 className="text-center color__white mainFont mt-3">Soccer</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <img width="200px" src={NFL} />
          <h2 className="text-center color__white mainFont mt-3">NFL</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <img width="200px" src={Basketball} />
          <h2 className="text-center color__white mainFont mt-3">Basketball</h2>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};
export default GamePageLead;
