import React, { useState } from "react";
import styles from "./Newsletter.module.css";
import FootballSvg from "../../images/football.svg";
import axios from "axios";
const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [done, setDone] = useState("Let's Go!");
  const addNewsLetter = () => {
    setDone("Please wait...");
    axios
      .post("https://3.106.200.146:3001/add-newsletter", {
        email: email,
      })
      .then((response) => {
        if (response.code == 0) {
          alert("Error inserting email.");
        } else {
          setDone("Subscribed");
        }
      });
  };

  return (
    <div className={`${styles.mainContainer}`}>
      <center>
        <h2 className="mainFont color__white fontMedium">Newsletter</h2>
        <p className="color__white mainFont">
          Yay! You made it to the end. Subscribe to our Newsletter for future
          Updates.
        </p>
        <div className={`${styles.inputDiv}`}>
          <input
            placeholder="Enter your email address"
            className="mainFont fontThin"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className="mainFont"
            disabled={done == "Subscribed" ? true : false}
            onClick={addNewsLetter}
          >
            {done}
          </button>
        </div>
      </center>
      <img src={FootballSvg} className={`${styles.basketball}`} />
    </div>
  );
};

export default Newsletter;
