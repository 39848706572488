import React from "react";
import { BlueBgButtonRounded } from "../../components/Buttons";

const Growing = ({ styles }) => {
  return (
    <div className={`${styles.growing__container}`}>
      <h3 className="color__blue mainFont text-center fontBold">
        Growing Athletes
      </h3>
      <h2 className="color__orange mainFont text-center fontBold">
        Then Host a <br /> GAME DAY
      </h2>
      <p className="color__white mainFont fontRegular">
        where GA members and others can bring their own team along for a day of
        fun to help test their new skills.
      </p>
      <BlueBgButtonRounded
        text="Check Out The Game Day"
        external="yes"
        link="/gameday"
      />
    </div>
  );
};
export default Growing;
