import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import styles from "./MemberDashboard.module.css";
import Footer from "../../components/Footer";
import axios from "axios";
function formatDate(inputDate) {
  const options = { day: "numeric", month: "long" };
  const date = new Date(inputDate);
  const formattedDate = date.toLocaleDateString(undefined, options);
  return formattedDate;
}

const MemberList = ({ styles, teamCode }) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    axios
      .get("https://3.106.200.146:3001/teamMembers/" + teamCode)
      .then((response) => {
        setMembers(response.data.result);
      });
  }, []);

  return (
    <>
      <table class="table table-dark mt-5">
        <thead>
          <tr>
            <th scope="col">Serial</th>
            <th scope="col">Member Name</th>
            <th scope="col">Member DOB</th>
            <th scope="col">Member Phone</th>
            <th scope="col">Member Email</th>
            <th scope="col">Description</th>
            <th scope="col">Team Code</th>
          </tr>
        </thead>
        <tbody>
          {members.map((e, index) => (
            <tr>
              <td>{e.member_id}</td>
              <td>{e.member_name}</td>
              <td>{e.member_phone}</td>
              <td>{e.team_code}</td>
              <td>{e.member_email}</td>
              <td>{e.member_dob}</td>
              <td>{e.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <center className="color__blue mainFont" style={{ cursor: "pointer" }}>
        <p onClick={() => window.location.reload()}>Go Back</p>
      </center>
    </>
  );
};

const MemberDashboard = () => {
  const [events, setEvents] = useState([]);
  const [collapsedState, setCollapsedState] = useState({});
  const [teamCode, setTeamCode] = useState("");
  const toggleCollapse = (eventIndex) => {
    setCollapsedState((prevState) => ({
      ...prevState,
      [eventIndex]: !prevState[eventIndex],
    }));
  };
  useEffect(() => {
    axios
      .get(
        "https://3.106.200.146:3001/eventByEmail/" +
          localStorage.getItem("member_email")
      )
      .then((response) => {
        setEvents(response.data.result);
      });
  }, []);
  return (
    <>
      <Navbar />
      <div className="container">
        <h2 className={`${styles.heading} mainFont color__orange fontBold`}>
          Upcoming Events
        </h2>
        {teamCode != "" ? (
          <MemberList styles={styles} teamCode={teamCode} />
        ) : (
          <div className="row mb-5">
            {events.map((e, index) => (
              <div className="col-12 col-md-6 mb-3 mt-3">
                <div className={`${styles.availBox}`}>
                  <div className="d-flex align-items-center">
                    <h2 className="mainFont color__white fontBold">
                      {formatDate(e.event_date)}
                    </h2>
                    <h4 className="mainFont color__grey fontRegular mx-2">
                      {e.event_location}
                    </h4>
                  </div>
                  <p className="mainFont color__grey">
                    {!collapsedState[index] ? (
                      <>
                        {e.event_description.slice(0, 100)}...
                        <span
                          className="color__blue"
                          style={{ paddingLeft: "10px" }}
                          onClick={() => toggleCollapse(index)}
                        >
                          Read More
                        </span>
                      </>
                    ) : (
                      <>
                        {e.event_description}
                        <span
                          className="color__blue"
                          onClick={() => toggleCollapse(index)}
                        >
                          Read Less
                        </span>
                      </>
                    )}
                  </p>
                  <h4
                    className="color__blue mainFont"
                    style={{ cursor: "pointer" }}
                    onClick={() => setTeamCode(e.team_code)}
                  >
                    See Members
                  </h4>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div style={{ marginTop: "250px" }}>
        <Footer />
      </div>
    </>
  );
};

export default MemberDashboard;
