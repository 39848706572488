import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import styles from "./blogs.module.css";

import Blog1 from "../../images/power_of_play.jpg";
import Blog2 from "../../images/team_sports.jpg";
import Blog3 from "../../images/link_between.jpg";
import Blog4 from "../../images/game_day.jpg";

import { useNavigate } from "react-router-dom";
const Blogs = () => {
  const blogs = [
    {
      index: 1,
      image: Blog1,
      title:
        "The Power of Play: Why Physical Activity is Crucial for Children's Development",
      slug: "the-power-of-play",
      summary: `In a world dominated by screens and technology, the significance of physical activity for children
        cannot be overstated. Engaging in regular physical activity is not just about building healthy
        bodies; it is a key element in promoting overall well-being, fostering social skills, and enhancing
        cognitive development in our youngest generation.`,
    },
    {
      index: 2,
      image: Blog2,
      title:
        "Team sport benefits: Growing Athletes Explains Why Team Sports at Game Day are so important.",
      slug: "team-sport-benefits",
      summary: `I’m booooreeed… As parents we’re all too familiar with this saying happening multiple times on the
        daily from our kids whilst on school holidays. Let’s face it, 6 weeks off over the Christmas / New
        Year break can be both great for spending time together as a family with loved one and having
        time to do those things you’ve waited all year to do`,
    },
    {
      index: 3,
      image: Blog3,
      title:
        "The Link Between Exercise and Well-Behaved Children: A Closer Look",
      slug: "link-between-exercise-and-well-behaved-children",
      summary: `In today's fast-paced digital age, where children are constantly bombarded with screens and
        electronic devices, maintaining their physical health has never been more crucial. However, the
        benefits of exercise extend beyond mere physical well-being; they also play a pivotal role in
        shaping a child's behavior and overall demeanor.`,
    },
    {
      index: 4,
      image: Blog4,
      title: "GAME DAY The Place to be this School Holidays.",
      slug: "game-day-place-to-be",
      summary: `Week to week trainers at Growing Athletes have decided to run a very special event in the
      summer school holidays. Game Day in Kincumber on the Central Coast will be a fun day of sports
      and activities for children and their friends to participate in.`,
    },
  ];
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <div className="container">
        {/* LATEST BLOG */}
        <section
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/blog-inner/the-power-of-play")}
        >
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6">
              <img src={Blog1} className={`${styles.featuredImage} w-100`} />
            </div>
            <div
              className={`${styles.featuredContainer} col-12 col-md-12 col-lg-6`}
            >
              <small className="mainFont color__orange fontMedium">
                Trending
              </small>
              <h2 className={`${styles.featuredHeadline} mainFont fontBold`}>
                The Power of Play: Why Physical Activity is Crucial for
                Children's Development
              </h2>
              <p className={`${styles.featuredDescription} mainFont fontLight`}>
                In a world dominated by screens and technology, the significance
                of physical activity for children cannot be overstated. Engaging
                in regular physical activity is not just about building healthy
                bodies; it is a key element in promoting overall well-being,
                fostering social skills, and enhancing cognitive development in
                our youngest generation.
              </p>
              <div>
                <span className="color__white">By GrowingAthletes</span>
              </div>
            </div>
          </div>
        </section>
        {/* LATEST BLOGS END */}

        {/* All blogs start */}
        <section className="mt-5">
          <div className="row">
            {blogs.map((b) => (
              <div
                className="col-12 col-md-4"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/blog-inner/" + b.slug)}
              >
                <div className={`${styles.blogContainer}`}>
                  <div
                    className={`${styles.blogThumbnail}`}
                    style={{ backgroundImage: `url(${b.image})` }}
                  ></div>
                  <div className="px-4">
                    <h3 className="mainFont fontBold pt-3 pb-3">{b.title}</h3>
                    <p className="mainFont fontRegular pb-3">{b.summary}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        {/* All blogs end */}
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
