import React from "react";
import Frisbee from "../../images/frisbee.png";
import Baseball from "../../images/baseball.png";
import KBaseball from "../../images/kick_baseball.png";
import Dodgeball from "../../images/dodge_ball.png";
import Football from "../../images/football.png";
import WhistleIcon from "../../images/whistle.svg";
import HT1 from "../../images/h_t1.png";
import HT2 from "../../images/h_t2.png";

import { BlueBgButtonRounded } from "../../components/Buttons";
import { useNavigate } from "react-router-dom";
const SportsPlayed = ({ styles }) => {
  const navigate = useNavigate();
  return (
    <div className={`${styles.sports__container} container`}>
      <img className={`${styles.whistle}`} src={WhistleIcon} />
      <div className={`${styles.sports__headlineContainer}`}>
        <h2 className="mainFont fontBlack color__orange">
          Start your
          <br /> Journey Today
        </h2>
        <br />
      </div>
      <div className={`${styles.sports__row}`}>
        <div className={`${styles.sportsRow__inner}`}>
          <div
            onClick={() => navigate("/gameday")}
            style={{ cursor: "pointer" }}
          >
            <div style={{ backgroundImage: `url(${HT1})` }}></div>
            <h4 className="color__white mainFont">
              Find a School Holiday Game Day
            </h4>
          </div>
          <div
            onClick={() => navigate("/weekly-training")}
            style={{ cursor: "pointer" }}
          >
            <div style={{ backgroundImage: `url(${HT2})` }}></div>
            <h4 className="color__white mainFont">
              Join a Weekly Training Camp
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SportsPlayed;
