import React, { useEffect, useState } from "react";
import styles from "./Events.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchEventName, setSearchEventName] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [searchDeposit, setSearchDeposit] = useState("");
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    axios.get("https://3.106.200.146:3001/allEvents").then((response) => {
      setLoading(false);
      setEvents(response.data.result);
    });
  }, [refresh]);
  const filteredEvents = events.filter((e) => {
    const eventNameMatch = e.event_name
      .toLowerCase()
      .includes(searchEventName.toLowerCase());
    const locationMatch = e.event_location
      .toLowerCase()
      .includes(searchLocation.toLowerCase());
    const depositMatch = e.deposit.toString().includes(searchDeposit);

    return eventNameMatch && locationMatch && depositMatch;
  });

  const deleteHandler = (e) => {
    axios
      .get("https://3.106.200.146:3001/delete-events/" + e)
      .then((response) => {
        if (response.data.code == 1) {
          setRefresh(!refresh);
        }
      });
  };

  return (
    <>
      <div className="container">
        <h3 className="mainFont">All Events</h3>
      </div>

      <div className={`${styles.tableContainer} container`}>
        <div className="d-flex">
          <input
            className={`${styles.eventNameSearch} w-100 mainFont fontThin`}
            placeholder="Search by event name..."
            style={{ marginRight: "10px" }}
            value={searchEventName}
            onChange={(e) => setSearchEventName(e.target.value)}
          />
          <input
            className={`${styles.eventNameSearch} w-40 mainFont fontThin`}
            placeholder="Location"
            style={{ marginRight: "10px" }}
            value={searchLocation}
            onChange={(e) => setSearchLocation(e.target.value)}
          />
          <input
            className={`${styles.eventNameSearch} w-40 mainFont fontThin`}
            placeholder="Deposit"
            value={searchDeposit}
            onChange={(e) => setSearchDeposit(e.target.value)}
          />
        </div>
        <table className={`${styles.customTable} table`}>
          <thead>
            <tr className="mainFont fontThin">
              <th scope="col">Event Name</th>
              <th scope="col">Deposit</th>
              <th scope="col">Location</th>
              <th scope="col">Dates</th>
              <th scope="col">Teams</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading
              ? filteredEvents.map((e) => (
                  <tr key={e.id} className="mainFont fontThin">
                    <td style={{ paddingTop: "15px" }}>{e.event_name}</td>
                    <td style={{ paddingTop: "15px" }}>{e.deposit}</td>
                    <td style={{ paddingTop: "15px" }}>{e.event_location}</td>
                    <td style={{ paddingTop: "15px" }}>{e.date_count}</td>
                    <td style={{ paddingTop: "15px" }}>{e.team_count}</td>
                    <td className="d-flex" style={{ paddingTop: "15px" }}>
                      <p
                        className="color__red"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => {
                          deleteHandler(e.id);
                        }}
                      >
                        Delete
                      </p>
                      <p
                        className="color__blue"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => navigate("/admin/teams/" + e.id)}
                      >
                        View Teams
                      </p>
                      <p
                        className="color__blue"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/admin/edit-event/" + e.id)}
                      >
                        Edit Event
                      </p>
                    </td>
                  </tr>
                ))
              : "Loading..."}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Events;
