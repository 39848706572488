import { Outlet, useNavigate } from "react-router-dom";
import styles from "./Admin.module.css";
import Logo from "../../images/logo.png";
import { useEffect, useState } from "react";
const AdminLayout = () => {
  const navigate = useNavigate();
  const [pageSelected, setPageSelected] = useState("home");
  useEffect(() => {
    if (localStorage.getItem("YWRtaW5fbG9naW4") != "dXNlciBpcyBsb2dnZWQgaW4=") {
      navigate("/admin-login");
    }
  }, []);
  return (
    <div>
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className={`${styles.sideBarRow} col-0 col-md-2 g-0`}>
            <div className={`${styles.sidebarContainer}`}>
              <img src={Logo} width="120" />
              <div className={`${styles.sidebarItems__container}`}>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "home" ? styles.sidebar__active : ""
                  }`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin");
                      setPageSelected("home");
                    }}
                  >
                    Overview
                  </p>
                </div>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "events" ? styles.sidebar__active : ""
                  } mt-2`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin/events");
                      setPageSelected("events");
                    }}
                  >
                    Events
                  </p>
                </div>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "addevent" ? styles.sidebar__active : ""
                  } mt-2`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin/addevent");
                      setPageSelected("addevent");
                    }}
                  >
                    Add Event
                  </p>
                </div>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "teams" ? styles.sidebar__active : ""
                  } mt-2`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin/teams/all");
                      setPageSelected("teams");
                    }}
                  >
                    Teams
                  </p>
                </div>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "individual-teams"
                      ? styles.sidebar__active
                      : ""
                  } mt-2`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin/individual-teams/all");
                      setPageSelected("individual-teams");
                    }}
                  >
                    Individual Registrations
                  </p>
                </div>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "gameday-leads"
                      ? styles.sidebar__active
                      : ""
                  } mt-2`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin/gameday-leads");
                      setPageSelected("gameday-leads");
                    }}
                  >
                    Gameday Leads
                  </p>
                </div>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "add-weekly-training"
                      ? styles.sidebar__active
                      : ""
                  } mt-2`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin/add-weekly-training");
                      setPageSelected("add-weekly-training");
                    }}
                  >
                    Add Weekly Training
                  </p>
                </div>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "weeklyevents" ? styles.sidebar__active : ""
                  } mt-2`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin/weeklyevents");
                      setPageSelected("weeklyevents");
                    }}
                  >
                    Weekly Events
                  </p>
                </div>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "discounts" ? styles.sidebar__active : ""
                  } mt-2`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin/discounts");
                      setPageSelected("weeklyevents");
                    }}
                  >
                    Discounts
                  </p>
                </div>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "newsletter" ? styles.sidebar__active : ""
                  } mt-2`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin/newsletter");
                      setPageSelected("newsletter");
                    }}
                  >
                    Newsletter Emails
                  </p>
                </div>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "waivers" ? styles.sidebar__active : ""
                  } mt-2`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin/waivers");
                      setPageSelected("waivers");
                    }}
                  >
                    Waivers
                  </p>
                </div>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "settings" ? styles.sidebar__active : ""
                  } mt-2`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin/settings");
                      setPageSelected("settings");
                    }}
                  >
                    Settings
                  </p>
                </div>
                <div
                  className={`${styles.sidebar__item} ${
                    pageSelected == "logout" ? styles.sidebar__active : ""
                  } mt-2`}
                >
                  <i className="fa fa-signal" aria-hidden="true"></i>
                  <p
                    className="mainFont fontMedium"
                    onClick={() => {
                      navigate("/admin-login");
                      localStorage.setItem("YWRtaW5fbG9naW4", "");
                    }}
                  >
                    Logout
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-10">
            <div className={`${styles.navbar__container}`}>
              <div style={{ height: "100px" }}></div>
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminLayout;
