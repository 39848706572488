import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./Colors.css";
import About from "./Pages/about/About";
import Home from "./Pages/homepage/Home";
import Purchase from "./Pages/purchase/Purchase";
import AdminLayout from "./Pages/admin/AdminLayout";
import Dashboard from "./Pages/admin/Dashboard";
import AddEvent from "./Pages/admin/AddEvent";
import Events from "./Pages/admin/Events";
import Faq from "./Pages/faq/Faq";
import Weekly from "./Pages/weekly/Weekly";
import GamePage from "./Pages/game/GamePage";
import MemberLogin from "./Pages/member_login/MemberLogin";
import Teams from "./Pages/admin/Teams";
import MemberDashboard from "./Pages/member_dashboard/MemberDashboard";
import WeeklyPurchase from "./Pages/weekly_purchase/WeeklyPurchase";
import AddWeekly from "./Pages/admin/AddWeekly";
import WeeklyEvents from "./Pages/admin/WeeklyEvents";
import ViewBooking from "./Pages/admin/ViewBooking";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import AdminLogin from "./Pages/admin_login/AdminLogin";
import Waiver from "./Pages/admin/Waiver";
import Settings from "./Pages/admin/Settings";
import EditEvent from "./Pages/admin/EditEvent";
import Discounts from "./Pages/admin/Discounts";
import Blogs from "./Pages/blogs/blogs";
import BlogInner from "./Pages/blogs/BlogInner";
import AloneTeams from "./Pages/admin/AloneTeams";
import GamePageLead from "./Pages/game/GamePageLead";
import GameDayLeads from "./Pages/admin/GameDayLeads";
import Newsletter from "./Pages/admin/Newsletter";

const initialOptions = {
  clientId:
    "AdMse7Qc7E3cqXOVsllY9K-ETaVxyPvEif8N1YZ3O-CiKZyr_RlaEjSHEpCLW1mmOpdjNVyFbGXdbVgJ",
  currency: "AUD",
};

function App() {
  return (
    <PayPalScriptProvider options={initialOptions}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="event-details/:eventId" element={<Purchase />} />
          <Route path="weekly-details/:eventId" element={<WeeklyPurchase />} />
          <Route path="faq" element={<Faq />} />
          <Route path="weekly-training" element={<Weekly />} />
          <Route path="member-login" element={<MemberLogin />} />
          <Route path="member-dashboard" element={<MemberDashboard />} />
          <Route path="gameday" element={<GamePage />} />
          <Route path="gameday-leads" element={<GamePageLead />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="blog-inner/:slug" element={<BlogInner />} />
          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="addevent" element={<AddEvent />} />
            <Route path="events" element={<Events />} />
            <Route path="teams/:filter" element={<Teams />} />
            <Route path="add-weekly-training" element={<AddWeekly />} />
            <Route path="weeklyevents" element={<WeeklyEvents />} />
            <Route path="waivers" element={<Waiver />} />
            <Route path="settings" element={<Settings />} />
            <Route path="edit-event/:eventId" element={<EditEvent />} />
            <Route path="discounts" element={<Discounts />} />
            <Route path="individual-teams/:filter" element={<AloneTeams />} />
            <Route path="gameday-leads" element={<GameDayLeads />} />
            <Route path="newsletter" element={<Newsletter />} />
            <Route
              path="booking-details/:bookingId"
              element={<ViewBooking />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </PayPalScriptProvider>
  );
}

export default App;
