import React, { useState } from "react";
import Logo from "../images/logo.png";
import styles from "./Navbar.module.css";
import { BlueBgButtonRounded, RedBgButtonRounded } from "./Buttons";
import FootballSvg from "../images/football.svg";
import { useNavigate } from "react-router-dom";
import hamburger from "../images/hamburger.svg";
import Cross from "../images/cross.svg";
const Navbar = ({ icon }) => {
  const [navActive, setNavActive] = useState(false);
  const [pageActive, setPageActive] = useState("home");
  const navigate = useNavigate();
  return (
    <div className={`${styles.nav__container} container`}>
      {icon == "yes" ? (
        <img src={FootballSvg} className={`${styles.footBallIcon}`} />
      ) : (
        ""
      )}
      <nav className={`${styles.desktopNav} navbar navbar-expand-lg`}>
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src={Logo} width="150" onClick={() => navigate("/")} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 mainFont fontSemiBold allCaps">
              <li
                className={`${styles.nav__item} nav-link`}
                onClick={() => {
                  navigate("/");
                  setPageActive("home");
                }}
              >
                <a
                  className={`${
                    pageActive == "home" ? "color__blue" : ""
                  } nav-link navLink__inactive`}
                  aria-current="page"
                  href="#"
                >
                  Home
                </a>
              </li>
              <li className={`${styles.nav__item} nav-link`}>
                <a
                  className={`${
                    pageActive == "about" ? "color__blue" : ""
                  } nav-link navLink__inactive`}
                  aria-current="page"
                  href="#"
                  onClick={() => {
                    navigate("/about");
                    setPageActive("about");
                  }}
                >
                  About
                </a>
              </li>
              <li
                className={`${styles.nav__item} nav-link`}
                onClick={() => {
                  navigate("/weekly-training");
                  setPageActive("weekly");
                }}
              >
                <a
                  className={`${
                    pageActive == "weekly" ? "color__blue" : ""
                  } nav-link navLink__inactive`}
                  aria-current="page"
                  href="#"
                >
                  Weekly Training
                </a>
              </li>
              <li
                className={`${styles.nav__item} nav-link`}
                onClick={() => {
                  navigate("/gameday");
                  setPageActive("gameday");
                }}
              >
                <a
                  className={`${
                    pageActive == "gameday" ? "color__blue" : ""
                  } nav-link navLink__inactive`}
                  aria-current="page"
                  href="#"
                >
                  Gameday
                </a>
              </li>
              <li
                className={`${styles.nav__item} nav-link`}
                onClick={() => {
                  navigate("/blogs");
                  setPageActive("blogs");
                }}
              >
                <a
                  className={`${
                    pageActive == "blogs" ? "color__blue" : ""
                  } nav-link navLink__inactive`}
                  aria-current="page"
                >
                  Blogs
                </a>
              </li>
              <li
                className={`${styles.nav__itemButton} nav-link`}
                onClick={() => navigate("/member-login")}
              >
                <BlueBgButtonRounded text="Sign In" external="no" />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <nav
        className={`${styles.mobileNav} ${
          navActive ? styles.mobileNavActive : ""
        }`}
      >
        <div>
          <img src={Logo} width="150" onClick={() => navigate("/")} />

          <img
            src={navActive ? Cross : hamburger}
            onClick={() => setNavActive(!navActive)}
          />
        </div>
        <div
          className={`${styles.navContent} `}
          style={{ display: navActive ? "flex" : "none" }}
        >
          <a className="mainFont color__white" onClick={() => navigate("/")}>
            Home
          </a>
          <a
            className="mainFont color__white fontThin"
            onClick={() => navigate("/weekly-training")}
          >
            Weekly Training Camp
          </a>
          <a
            className="mainFont color__white fontThin"
            onClick={() => navigate("/gameday")}
          >
            Game Day
          </a>
          <a
            className="mainFont color__white fontThin"
            style={{ paddingBottom: "20px" }}
            onClick={() => navigate("/blogs")}
          >
            Blogs
          </a>
          <a
            className="mainFont color__white fontThin"
            style={{ border: "none", paddingBottom: "20px" }}
            onClick={() => navigate("/about")}
          >
            About
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
