import React from "react";
import Swimming from "../../images/swimming.png";
import Alarm from "../../images/alarm.svg";
import Baseball from "../../images/baseball.svg";
import Basketball from "../../images/football.svg";
const ContactUs = ({ styles }) => {
  return (
    <div className={`${styles.contact__container}`}>
      <img src={Swimming} className={`${styles.swimmingImage}`} />
      <img src={Alarm} className={`${styles.alarm}`} />
      <img src={Basketball} className={`${styles.basketball}`} />
      <div className="container">
        <div>
          <h3
            className={`${styles.contact__heading} color__blue mainFont fontBold`}
          >
            CONTACT <br /> US
          </h3>
        </div>
        <div className={`${styles.contact__formContainer}`}>
          <div>
            <h2 className="hitUp color__white mainFont fontBold">
              HIT US UP IF YOU HAVE ANY QUESTIONS!
            </h2>
            <input
              placeholder="Full Name"
              className="mainFont fontMedium mb-3"
            />
            <input
              placeholder="Email Address"
              className="mainFont fontMedium"
            />
            <label className="color__grey mainFont fontMedium mt-3">
              Query
            </label>{" "}
            <br />
            <textarea className="w-100"></textarea>
            <div>
              <button className="mainFont fontMedium">SUBMIT</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
