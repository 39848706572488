import axios from "axios";
import React, { useEffect, useState } from "react";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import PP_Logo from "../../images/paypal_logo.svg";
import Mastercard from "../../images/mastercard.svg";
import Visa from "../../images/visa.svg";
import { PayPalButtons } from "@paypal/react-paypal-js";
import Modal from "react-bootstrap/Modal";
const PaypalCheckoutButton = (props) => {
  const {
    product,
    deposit,
    cName,
    cDob,
    cEmail,
    cPhone,
    date,
    eventId,
    diet,
    kinName,
    secondPhone,
  } = props;
  const [payPass, setPayPass] = useState(false);
  const handleApprove = () => {
    // No need to set payPass to true again here, you already did it in the onApprove callback
    // setPayPass(true);

    // Check if payPass is true before showing the alert
    if (payPass) {
      alert("Thank you for your payment!");
    }
  };

  return (
    <>
      {payPass ? (
        <h3 className="mainFont color__white fontBold">
          Thank you for your payment! <br /> You'll receive a confirmation email
          shortly!
        </h3>
      ) : (
        <PayPalButtons
          styles={{
            layout: "horizontal",
            height: 48,
            tagline: true,
            shape: "pill",
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: product.description,
                  amount: {
                    value: deposit,
                  },
                },
              ],
            });
          }}
          onApprove={async (data, actions) => {
            const order = await actions.order.capture();
            // Set payPass to true when the payment is approved
            setPayPass(true);
            handleApprove(); // Call the handleApprove function
            console.log(order);
            axios
              .post("https://3.106.200.146:3001/registerWeekly", {
                child_name: cName,
                child_dob: cDob,
                child_email: cEmail,
                child_phone: cPhone,
                event_id: eventId,
                event_date: date,
                diet: diet,
                kinName: kinName,
                secondPhone: secondPhone,
              })
              .then((response) => console.log(response));
          }}
          onError={(err) => {
            alert("An error occurred: " + err);
          }}
        />
      )}
    </>
  );
};

const MyPaymentForm = ({
  deposit,
  teamName,
  cName,
  cDob,
  cEmail,
  cPhone,
  date,
  eventId,
  diet,
  kinName,
  secondPhone,
}) => {
  const [message, setMessage] = useState("");
  const [paymentDone, setPaymentDone] = useState(null);
  return (
    <PaymentForm
      /**
       * Identifies the calling form with a verified application ID generated from
       * the Square Application Dashboard.
       */
      applicationId="sq0idp-kmDHjEZQNpbilIamooBwPw"
      /**
       * Invoked when payment form receives the result of a tokenize generation
       * request. The result will be a valid credit card or wallet token, or an error.
       */
      cardTokenizeResponseReceived={(token, buyer) => {
        axios
          .post("https://3.106.200.146:3001/pay", {
            amount: deposit,
            token: token.token,
          })
          .then((response) => {
            if (response.data.code == 1) {
              axios
                .post("https://3.106.200.146:3001/registerWeekly", {
                  child_name: cName,
                  child_dob: cDob,
                  child_email: cEmail,
                  child_phone: cPhone,
                  event_id: eventId,
                  event_date: date,
                  diet: diet,
                  kinName: kinName,
                  secondPhone: secondPhone,
                })
                .then((response) => console.log(response));
              setPaymentDone(true);
            } else {
              setPaymentDone(false);
              setMessage("There was an error processing your payment.");
            }
          })
          .catch((error) => {
            // Handle errors here and log the error details

            // If the error object contains more specific details, you can access them
            if (error.response) {
              console.error("Response Data:", error.response.data);
            }
          });
      }}
      locationId="LC4Y26181PE85"
    >
      {!paymentDone && paymentDone != null ? (
        <h3 className="mainFont fontMedium color__white">
          Pay Deposit - ${deposit}
        </h3>
      ) : (
        ""
      )}
      {!paymentDone && paymentDone != null ? (
        <div className="alert alert-danger">{message}</div>
      ) : (
        ""
      )}
      {paymentDone ? (
        <h3 className="mainFont color__white fontBold">
          Thank you for your payment! <br /> You'll receive a confirmation email
          shortly!
        </h3>
      ) : (
        <div style={{ marginRight: "40px" }}>
          <CreditCard />
          <p className="mainFont color__white fontThin mt-2">
            Payment gateway by SquareUp
          </p>
        </div>
      )}
    </PaymentForm>
  );
};

const TeamJoinForm = ({ styles, eventId, date, deposit, eventLocation }) => {
  const [teamCode, setTeamCode] = useState("");
  const [childName, setChildName] = useState();
  const [childDob, setChildDob] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showPayment, setShowPayment] = useState(false);
  const [message, setMessage] = useState("");
  const [diet, setDiet] = useState("");
  const [joined, setJoined] = useState(false);
  const [kinName, setKinName] = useState("");
  const [secondPhone, setSecondPhone] = useState("");
  const [method, setMethod] = useState("");

  const [showAg, setShowAg] = useState(false);
  const [waiver, setWaiver] = useState("");

  const [agreed, setAgreed] = useState(false);

  const [discountCode, setDiscountCode] = useState("");
  const [discountDone, setDiscountDone] = useState(false);

  const [newDeposit, setNewDeposit] = useState(9999);
  const [amountOff, setAmountOff] = useState(0);
  const [discountType, setDiscountType] = useState("");

  useEffect(() => {
    axios
      .get("https://3.106.200.146:3001/get-waiver/1")
      .then((response) => setWaiver(response.data.result[0].agreement));
  }, []);

  let product = {
    description: "Weekly training camp registration for date " + date,
    price: deposit,
  };

  const getDiscount = () => {
    if (!discountDone) {
      axios
        .get("https://3.106.200.146:3001/check-discount/" + discountCode)
        .then((response) => {
          if (response.data.code == 99) {
            alert("Discount Code Is Incorrect!");
          } else {
            deposit = deposit - response.data.result[0].amount;
            setDiscountDone(true);
            setNewDeposit(deposit);
            setAmountOff(response.data.result[0].amount);
            setDiscountType(response.data.result[0].type);
          }
        });
    } else {
      alert("Discount already applied.");
    }
  };

  let userDetails;
  const addTeam = () => {
    if (
      childName != "" &&
      childDob != "" &&
      email != "" &&
      phone != "" &&
      kinName != "" &&
      secondPhone != ""
    ) {
      if (phone == secondPhone) {
        setMessage("Alternate Phone must be different from contact number");
        setTimeout(() => setMessage(""), 2000);
      } else {
        if (method == "") {
          setMessage("Select a payment method.");
          setTimeout(() => setMessage(""), 2000);
        } else {
          userDetails = {
            deposit: deposit,
            cName: childName,
            cDob: childDob,
            cEmail: email,
            cPhone: phone,
            date: date,
            eventId: eventId,
            diet: diet,
            kinName: kinName,
            secondPhone: secondPhone,
          };
          setShowPayment(true);
        }
      }
    } else {
      setMessage("All fields are required");
      setTimeout(() => setMessage(""), 2000);
    }
  };

  return (
    <>
      <div className={`${styles.registerBox} mb-5`}>
        {!showPayment ? (
          <>
            <h1 className="mainFont fontMedium color__white">
              Register for our Training Camp!
            </h1>
            {message == "" ? (
              ""
            ) : (
              <div className="alert alert-danger">{message}</div>
            )}
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-12 col-md-12 col-lg-6"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin mt-3">
                      Child Name
                    </label>
                    <br />
                    <input
                      value={childName}
                      onChange={(e) => setChildName(e.target.value)}
                      placeholder="Henry"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin mt-3">
                      Child DOB
                    </label>
                    <br />
                    <input
                      type="date"
                      value={childDob}
                      onChange={(e) => setChildDob(e.target.value)}
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Email Address
                    </label>
                    <br />
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="yourmail@gmail.com"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Contact Number
                    </label>
                    <br />
                    <input
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="+61455512850"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Next Of Kin (Name)
                    </label>
                    <br />
                    <input
                      value={kinName}
                      onChange={(e) => setKinName(e.target.value)}
                      placeholder="Richard"
                      className="w-100"
                    />
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-6 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont fontThin">
                      Alt Phone Number (different to main number)
                    </label>
                    <input
                      value={secondPhone}
                      onChange={(e) => setSecondPhone(e.target.value)}
                      placeholder="+61455512555"
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="col-12 mt-3" style={{ paddingLeft: "0px" }}>
                  <div>
                    <label className="color__white mainFont fontThin">
                      Health concerns and previous injuries to be noted for
                      coaches
                    </label>
                    <textarea
                      style={{
                        background: "transparent",
                        width: "100%",
                        border: "1px solid white",
                        height: "150px",
                      }}
                      className="color__white"
                      value={diet}
                      onChange={(e) => setDiet(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div
                  className="col-12 col-md-12 col-lg-12 mt-3"
                  style={{ paddingLeft: "0px" }}
                >
                  <div>
                    <label className="color__white mainFont">
                      Discount Code:
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        className="form-control mainFont color__white"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        style={{
                          background: "transparent",
                          color: "white",
                          borderRadius: "0px",
                        }}
                        disabled={discountDone}
                        value={discountCode}
                        onChange={(e) => setDiscountCode(e.target.value)}
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-light"
                          type="button"
                          style={{ borderRadius: "0px" }}
                          onClick={getDiscount}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="color__white mainFont">
              Total: {newDeposit === 9999 ? deposit : newDeposit}${" "}
              {newDeposit === 9999
                ? ""
                : `(${amountOff}${discountType === "fixed" ? "$" : "%"} Off)`}
            </h3>

            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                maxWidth: "100%",
              }}
            >
              <input
                type="checkbox"
                onClick={() => setShowAg(true)}
                checked={agreed}
              />
              <label
                className="color__white mainFont fontThin mt-2"
                style={{ marginLeft: "10px" }}
              >
                I Agree to all the Terms and Conditions present for the specific
                action.
              </label>
            </div>
            <label className="color__grey mainFont fontThin mt-3 mb-2">
              Select Payment Method
            </label>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <input
                  type="radio"
                  onChange={(e) => setMethod(e.target.value)}
                  style={{ marginRight: "10px" }}
                  value="paypal"
                />
                <img src={PP_Logo} width={70} />
              </div>
              <div className="mx-4">
                <input
                  type="radio"
                  onChange={(e) => setMethod(e.target.value)}
                  style={{ marginRight: "10px" }}
                  value="square"
                />
                <img src={Mastercard} width={90} />
              </div>
              <div>
                <input
                  type="radio"
                  onChange={(e) => setMethod(e.target.value)}
                  style={{ marginRight: "10px" }}
                  value="square"
                />
                <img src={Visa} width={70} />
              </div>
            </div>
            <div>
              <button
                className="bg__blue px-5 color__white mainFont allCaps"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => {
                  addTeam();
                }}
              >
                Pay Now
              </button>
            </div>
            <Modal show={showAg} onHide={() => setShowAg(!showAg)} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Terms & Conditions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  dangerouslySetInnerHTML={{
                    __html: waiver
                      .replace("[Event Date]", date)
                      .replace("[Event Location]", eventLocation),
                  }}
                  style={{
                    height: "400px",
                    overflow: "auto",
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setAgreed(true);
                    setShowAg(false);
                  }}
                >
                  I Agree
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setAgreed(false);
                    setShowAg(false);
                  }}
                >
                  Decline
                </button>
              </Modal.Footer>
            </Modal>
          </>
        ) : showPayment && method == "square" ? (
          <MyPaymentForm
            deposit={discountDone ? newDeposit : deposit}
            cName={childName}
            cDob={childDob}
            cEmail={email}
            cPhone={phone}
            date={date}
            eventId={eventId}
            diet={diet}
            kinName={kinName}
            secondPhone={secondPhone}
          />
        ) : (
          <PaypalCheckoutButton
            product={product}
            deposit={discountDone ? newDeposit : deposit}
            cName={childName}
            cDob={childDob}
            cEmail={email}
            cPhone={phone}
            date={date}
            eventId={eventId}
            diet={diet}
            kinName={kinName}
            secondPhone={secondPhone}
          />
        )}
      </div>
    </>
  );
};
const WeeklyTeam = ({ styles, eventId, date, deposit, eventLocation }) => {
  return (
    <TeamJoinForm
      styles={styles}
      eventId={eventId}
      date={date}
      deposit={deposit}
      eventLocation={eventLocation}
    />
  );
};

export default WeeklyTeam;
