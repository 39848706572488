import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import styles from "./Weekly.module.css";
import WeeklyImage from "../../images/weekly_image.png";
import FourFeatures from "../../images/four_features.png";
import MapIcon from "../../images/map_icon.svg";
import ClockIcon from "../../images/clock_icon_blue.svg";
import ClockIconWhite from "../../images/clock_white.svg";
import MapIconWhite from "../../images/map_icon_white.svg";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import NewFour from "../../images/new4features.png";
import MobileWeekly from "../../images/mobile_weekly.png";
import axios from "axios";
const Weekly = () => {
  const [weeklyEvents, setWeeklyEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    axios.get("https://3.106.200.146:3001/getWeeklyEvents").then((response) => {
      setWeeklyEvents(response.data.result);
      setLoading(false);
    });
  }, []);
  return (
    <>
      <Navbar />
      <div
        className={`container-fluid ${styles.desktopView}`}
        style={{ position: "absolute", overflow: "hidden" }}
      >
        <img src={WeeklyImage} className={`${styles.weeklyImage}`} />
        <img src={MobileWeekly} className={`${styles.weeklyImage2}`} />
        <div className="container">
          <div className={`${styles.mainBox}`}>
            <div className={`${styles.boxOne}`}>
              <h1 className="mainFont color__orange fontBold">
                Weekly Training Camp
              </h1>
              <h3 className="mainFont color__blue fontBold">
                Build your child’s confidence.
              </h3>
              <p className="color__white mainFont">
                Our training camps cater to all levels of ability, ensuring that
                every child receives personalized attention and support.
                <br /> With a maximum of ten participants per session, we focus
                on essential physical attributes such as <br />
                strength, stamina, agility, and speed. By tailoring our approach
                to each child’s needs, we help
                <br /> them develop into their best selves while also fostering
                resilience and mental fortitude.
                <br /> Our goal is to empower children to overcome challenges
                and persevere through difficult situations, <br />
                both in sports and in life. <br /> <br /> To track progress and
                motivate improvement, we periodically conduct milestone tests.
                These assessments allow <br />
                children to see their own growth and achievements, reinforcing a
                personal journey of development rather than
                <br /> competition with others. Results are kept private between
                the child and their parents, ensuring a supportive
                <br /> and pressure-free environment where the focus remains on
                individual progress and self-improvement.
              </p>
            </div>
            <img src={FourFeatures} className={`${styles.fourFeatures}`} />
          </div>

          <div className={`${styles.disclaimer}`}>
            <p className="mainFont color__white">
              *From just starting out to established athletes, with a maximum of
              10 per session, ‘Growing Athletes’ trainers cater for everyone at
              our training camps
            </p>
          </div>
          <br />
          <br />
          <h2
            className={`${styles.training_heading} color__blue mainFont fontBold`}
          >
            Choose Your <br /> Session
          </h2>
          <div className="row">
            {loading
              ? "Loading..."
              : weeklyEvents.map((e) => {
                  if (e.visible == "yes") {
                    return (
                      <div
                        className="col-12 col-md-6 col-lg-6 mb-5"
                        key={e.id}
                        onClick={() => setSelectedEvent(e.id)}
                      >
                        <div
                          className={`${styles.locationContainer} ${
                            selectedEvent == e.id ? styles.locationActive : ""
                          }`}
                        >
                          <div className="">
                            <div className="d-flex">
                              <img
                                src={
                                  selectedEvent == e.id ? MapIconWhite : MapIcon
                                }
                                width={30}
                              />
                              <p
                                className={`mainFont mt-3 fontRegular ${
                                  selectedEvent == e.id
                                    ? "color__white"
                                    : "color__blue"
                                }`}
                              >
                                {e.weekly_location}
                              </p>
                            </div>
                            <div className="d-flex">
                              <img
                                src={
                                  selectedEvent == e.id
                                    ? ClockIconWhite
                                    : ClockIcon
                                }
                                width={30}
                              />
                              <p
                                className={`${
                                  selectedEvent == e.id
                                    ? "color__white"
                                    : "color__blue"
                                } mainFont mt-3 fontRegular`}
                              >
                                {e.weekly_time} {e.weekly_day}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
          </div>
          <center className="mb-5">
            <button
              className={`${styles.bookNowBtn} ${
                selectedEvent == 0 ? styles.disabled : ""
              } mainFont fontMedium`}
              onClick={() => {
                if (selectedEvent != 0) {
                  navigate("/weekly-details/" + selectedEvent);
                }
              }}
            >
              {selectedEvent == 0 ? "Select An Event" : "Book Now"}
            </button>
          </center>
          <center>
            <div
              className={`mb-5 mainFont color__white pt-3 pb-3 ${styles.noteContainer}`}
              style={{
                backgroundColor: "#454E59",
                width: "500px",
                borderRadius: "50px",
                textAlign: "left",
                paddingLeft: "50px",
              }}
            >
              *Available during the school terms. <br />
              **Pay for the term in advance for a discount.
            </div>
          </center>
        </div>
        <Footer />
      </div>

      <div className={`${styles.mobileView}`}>
        <div className={`${styles.firstContainer}`}>
          <img src={MobileWeekly} className={`${styles.weekly2}`} />
          <h1 className="color__orange mainFont fontBold">
            Weekly Training Camps
          </h1>
          <h2 className="color__blue mainFont fontBold">
            Build your Child's Confidence
          </h2>
          <p className="color__white mainFont fontThin">
            Our training camps cover all levels of abilities. Maxed out at ten
            per session also means that each child gets the attention they need.
            <br />
            <br />
            Physically focusing on Strength, Stamina, Agility and Speed, we help
            children become the best version of themselves while also instilling
            a mental capacity to push through difficult situations, both
            physically and mentally.
            <br />
            <br />
            We sporadically run milestone tests so children can see their
            improvements. They only compete with themselves at training camps.
            Results are only shared with them and their parents if they wish.
          </p>
          <img src={NewFour} />
        </div>
        <div className={`${styles.disclaimer}`}>
          <p className="mainFont color__white">
            *From just starting out to established athletes, with a maximum of
            10 per session, ‘Growing Athletes’ trainers cater for everyone at
            our training camps
          </p>
        </div>
        <div className={`${styles.thirdSection}`}>
          <h2
            className={`${styles.training_heading} color__orange mainFont fontBold`}
          >
            Choose Your <br /> Session
          </h2>
          <div className="row">
            {loading
              ? "Loading..."
              : weeklyEvents.map((e) => (
                  <div
                    className="col-12 col-md-6 col-lg-6 mb-5"
                    key={e.id}
                    onClick={() => setSelectedEvent(e.id)}
                  >
                    <div
                      className={`${styles.locationContainer} ${
                        selectedEvent == e.id ? styles.locationActive : ""
                      }`}
                    >
                      <div className="">
                        <div className="d-flex">
                          <img
                            src={selectedEvent == e.id ? MapIconWhite : MapIcon}
                            width={30}
                          />
                          <p
                            className={`mainFont mt-3 fontRegular ${
                              selectedEvent == e.id
                                ? "color__white"
                                : "color__blue"
                            }`}
                          >
                            {e.weekly_location}
                          </p>
                        </div>
                        <div className="d-flex">
                          <img
                            src={
                              selectedEvent == e.id ? ClockIconWhite : ClockIcon
                            }
                            width={30}
                          />
                          <p
                            className={`${
                              selectedEvent == e.id
                                ? "color__white"
                                : "color__blue"
                            } mainFont mt-3 fontRegular`}
                          >
                            {e.weekly_time} {e.weekly_day}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          <center className="mb-5">
            <button
              className={`${styles.bookNowBtn} ${
                selectedEvent == 0 ? styles.disabled : ""
              } mainFont fontMedium`}
              onClick={() => {
                if (selectedEvent != 0) {
                  navigate("/weekly-details/" + selectedEvent);
                }
              }}
            >
              {selectedEvent == 0 ? "Select An Event" : "Book Now"}
            </button>
          </center>
        </div>
        <div className={`${styles.disclaimer}`}>
          <p className="mainFont color__grey">
            *Available during the school terms.
            <br />
            **Pay for the term in advance for a discount.
          </p>
        </div>
        <div className="mt-5">
          <Footer />
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default Weekly;
