import React, { useEffect, useState } from "react";
import styles from "./AddEvent.module.css";
import axios from "axios";
const Settings = () => {
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPassword, setAdminPassword] = useState("");

  const [gmail, setGmail] = useState("");
  const [gpassword, setGpassword] = useState("");

  useEffect(() => {
    axios.get("https://3.106.200.146:3001/getAdminDetails").then((response) => {
      setAdminEmail(response.data.result[0].email);
    });
    axios.get("https://3.106.200.146:3001/getGmailDetails").then((response) => {
      setGmail(response.data.result[0].email);
    });
  }, []);

  const changeAdminDetails = () => {
    axios
      .post("https://3.106.200.146:3001/updateAdmin", {
        email: adminEmail,
        password: adminPassword,
      })
      .then((response) => {
        if (response.data.code == 1) {
          alert("Admin Data Changed");
        } else {
          alert("An error occured!");
        }
      });
  };

  const changeGmailDetails = () => {
    axios
      .post("https://3.106.200.146:3001/updateGmail", {
        email: gmail,
        password: gpassword,
      })
      .then((response) => {
        if (response.data.code == 1) {
          alert("Gmail Data Changed");
        } else {
          alert("An error occured!");
        }
      });
  };

  return (
    <div className="container-fluid mt-5">
      <div className={`${styles.formBackground} mt-3 mb-3`}>
        <h5 className="mainFont fontMediumBold">Admin Details</h5>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Email Address</label>
          <input
            className={`${styles.eventForm} w-100`}
            value={adminEmail}
            onChange={(e) => setAdminEmail(e.target.value)}
          />
        </div>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Password</label>
          <input
            className={`${styles.eventForm} w-100`}
            value={adminPassword}
            type="password"
            onChange={(e) => setAdminPassword(e.target.value)}
            placeholder="*******"
          />
        </div>
      </div>
      <center>
        <button
          className="btn btn-primary px-5 mb-5"
          onClick={changeAdminDetails}
        >
          Save
        </button>
      </center>

      <div className={`${styles.formBackground} mt-3 mb-3`}>
        <h5 className="mainFont fontMediumBold">Email Details</h5>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Gmail Address:</label>
          <input
            className={`${styles.eventForm} w-100`}
            value={gmail}
            onChange={(e) => setGmail(e.target.value)}
          />
        </div>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Password</label>
          <input
            className={`${styles.eventForm} w-100`}
            value={gpassword}
            type="password"
            onChange={(e) => setGpassword(e.target.value)}
            placeholder="*******"
          />
        </div>
      </div>
      <center>
        <button
          className="btn btn-primary px-5 mb-5"
          onClick={changeGmailDetails}
        >
          Save
        </button>
      </center>
    </div>
  );
};

export default Settings;
