import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import styles from "./gameday.module.css";
import { BlueBgButtonRounded } from "../../components/Buttons";
import MainImage from "../../images/gameday_image.png";
import Basketball from "../../images/game_basket.png";
import TeamIcon from "../../images/team__icon.svg";
import Frisbee from "../../images/game_frisbee.png";
import KickBasketball from "../../images/game_kball.png";
import CarIcon from "../../images/car_image.PNG";
import GiftIcon from "../../images/gift_icon.PNG";
import GameFrisbee from "../../images/gameday_frisbee.png";
import Oztag from "../../images/oztag.png";
import MapIcon from "../../images/location_btn.png";
import axios from "axios";
import Footer from "../../components/Footer";
import { Navigate, useNavigate } from "react-router-dom";

import WhistleIcon from "../../images/whistle.svg";
import ClockIcon from "../../images/alarm.svg";
import BasketballOne from "../../images/football.svg";
import Carousel from "react-grid-carousel";
import Soccer from "../../images/Soccer.png";
import NFL from "../../images/NFL.png";
function formatDate(inputDate) {
  const options = { day: "numeric", month: "long" };
  const date = new Date(inputDate);
  const formattedDate = date.toLocaleDateString(undefined, options);
  return formattedDate;
}

const GamePage = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [collapsedState, setCollapsedState] = useState({});
  useEffect(() => {
    axios.get("https://3.106.200.146:3001/allEventsSecond").then((response) => {
      setEvents(response.data.result);
      setLoading(false);
    });
  }, []);
  const navigate = useNavigate();
  const toggleCollapse = (eventIndex) => {
    setCollapsedState((prevState) => ({
      ...prevState,
      [eventIndex]: !prevState[eventIndex],
    }));
  };

  const handleScroll = () => {
    let element = document.getElementById("gameDaySection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Navbar />
      <div style={{ position: "relative", overflow: "hidden" }}>
        <img src={WhistleIcon} className={`${styles.whistle}`} />
        <img src={BasketballOne} className={`${styles.b_one}`} />
        <img src={ClockIcon} className={`${styles.clock}`} />
        <img src={MainImage} className={`${styles.mainImage}`} />
        <div className="container mb-5">
          <h1
            className={`${styles.mainHeading} color__orange mainFont fontBold`}
          >
            Put Your Skills To The <br /> Test On Game Day!
          </h1>
          <div className={`${styles.hero__paragraph}`}>
            <p className="mainFont color__white" style={{ textAlign: "right" }}>
              Are you ready for an action-packed day of fun, teamwork, and
              sports? Join us for an exhilarating day camp where kids can <br />
              unleash their energy, make new friends, and dive into a variety of
              exciting games and activities!
              <br />
              <br /> 🏅 Team Challenges: Kids will be divided into teams, each
              with its own fun and creative team name and
              <br /> work together to conquer a series of team-based challenges
              and games. <br />
              <br />⚽ Sports Galore: From soccer to dodgeball, basketball to
              relay races, our school holiday camp is packed
              <br /> with a wide range of sports and physical activities. Each
              sport is tailored to be inclusive and
              <br /> enjoyable for all skill levels. <br />
              <br />
              🎨 Fun & Games: It’s not just about sports! We’ve got a fantastic
              lineup of non-stop fun, <br />
              including obstacle courses, scavenger hunts, and creative <br />
              games that spark imagination and teamwork.
              <br />
              <br /> 🤹 Skill Stations: Kids can rotate through various skill
              stations, where they’ll have the chance to hone their
              <br /> abilities, try new sports, and even take part in
              mini-tournaments.
              <br />
              <br /> 🏆 Awards & Prizes: Every team will have the chance to earn
              points and compete for fun awards and
              <br /> prizes at the end of the day. It’s all about celebrating
              effort, sportsmanship, and enthusiasm!
              <br />
              <br /> 🎈 Additional Activities: Keep the excitement going with
              more fun activities like waterslides and <br />
              jumping castles, face painting, balloon shaping and more! Plus a
              special snack break
              <br /> featuring delicious treats and refreshing drinks.
              <br />
              <br /> Who Can Join? <br />
              Kids aged 6-12 are invited to participate. No prior experience is
              necessary—just bring your enthusiasm <br /> and a willingness to
              have a blast! <br />
              <br />
              What to Bring:
              <br /> Comfortable sportswear and a water bottle
              <br /> A positive attitude and a big smile
              <br /> Hat is a must as we'll be outside! And a change of clothes
              if needed. We'll have sunscreen onsite.
              <br />
              <br /> How to Register:
              <br /> Spaces are limited, so be sure to <u>hit the link</u> below
              and secure your spot!
              <br />
              <br /> Don't miss out on this opportunity for your child to
              experience a day of laughter, activity, and teamwork.
              <br /> We can’t wait to see your child at our next School Holiday
              Game Day! <br />
              For more information, contact Holly on{" "}
              <a href="tel:0431192867">0431192867</a> or email
              <a href="mailto:growingathletes1@gmail.com">
                growingathletes1@gmail.com
              </a>{" "}
              <br />
              <br />
              Let the games begin! 🏅🎉
            </p>
          </div>
          <div
            className={`${styles.blueButton} d-flex justify-content-end mt-4`}
            onClick={handleScroll}
          >
            <BlueBgButtonRounded text="LET'S DO IT!" external="yes" />
          </div>
          <div className={`${styles.gap}`}></div>
        </div>
        <div className={`${styles.desktopBlur}`}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className={`${styles.box}`}>
                  <img src={TeamIcon} width="120" />
                  <div>
                    <h2 className="color__white mainFont fontBold">Team Up!</h2>
                    <p className="color__white mainFont fontThin">
                      Get your friends together and book in for a day of School
                      Holiday Fun!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className={`${styles.box}`}>
                  <img src={CarIcon} width="120" />
                  <div>
                    <h2 className="color__white mainFont fontBold">
                      Pick-Up & <br /> Drop-off
                    </h2>
                    <p className="color__white mainFont fontThin">
                      Drop off 8:30am
                      <br />
                      Pickup 3:30pm
                      <br /> <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className={`${styles.box}`}>
                  <img src={GiftIcon} width="120" />
                  <div>
                    <h2 className="color__white mainFont fontBold">
                      Additional <br /> Perks
                    </h2>
                    <p className="color__white mainFont fontThin">
                      Lunch provided.
                      <br /> <br /> Every team member receives a sports gift
                      upon arrival! <br /> <br /> There’s also a surprise fun
                      activity to end the day.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${styles.availContainer} container`}
          style={{ marginTop: "150px !important", marginBottom: "150px" }}
        >
          <center>
            <h2 className="mainFont color__blue fontBold">
              Choose A Game Day!
            </h2>
          </center>
          <div className="row mt-5 mb-5" id="gameDaySection">
            {loading
              ? "Loading..."
              : events.map((e, index) => {
                  if (e.closed == "NO") {
                    return (
                      <div className="col-12 col-md-6 mb-3">
                        <div className={`${styles.availBox}`}>
                          <p className="mainFont color__white fontRegular">
                            {e.team_count} / 10 Teams
                          </p>
                          <div className="d-flex align-items-center">
                            <h2 className="mainFont color__white fontBold">
                              {formatDate(e.event_date)}
                            </h2>
                            <h4 className="mainFont color__grey fontRegular mx-2">
                              {e.event_location}
                            </h4>
                          </div>
                          <p className="mainFont color__grey">
                            {!collapsedState[index] ? (
                              <>
                                {e.event_description.slice(0, 100)}...
                                <span
                                  className="color__blue"
                                  style={{ paddingLeft: "10px" }}
                                  onClick={() => toggleCollapse(index)}
                                >
                                  Read More
                                </span>
                              </>
                            ) : (
                              <>
                                {e.event_description}
                                <span
                                  className="color__blue"
                                  onClick={() => toggleCollapse(index)}
                                >
                                  Read Less
                                </span>
                              </>
                            )}
                          </p>
                          <div
                            className={`${styles.availButtons} d-flex justify-content-center `}
                          >
                            <buton
                              className="bg__blue color__white mainFont w-100 text-center"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "30px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate("/event-details/" + e.event_id)
                              }
                            >
                              BOOK NOW
                            </buton>
                            <img
                              src={MapIcon}
                              width={50}
                              className="mx-2"
                              onClick={() => {
                                window.location.href = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                  e.event_location
                                )}`;
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    // return (
                    //   <div className="col-12 col-md-6 mb-3">
                    //     <div className={`${styles.availBox}`}>
                    //       <p className="mainFont color__white fontRegular">
                    //         10 / 10 Teams
                    //       </p>
                    //       <div className="d-flex align-items-center">
                    //         <h2 className="mainFont color__white fontBold">
                    //           {formatDate(e.event_date)}
                    //         </h2>
                    //         <h4 className="mainFont color__grey fontRegular mx-2">
                    //           {e.event_location}
                    //         </h4>
                    //       </div>
                    //       <p className="mainFont color__grey">
                    //         {!collapsedState[index] ? (
                    //           <>
                    //             {e.event_description.slice(0, 100)}...
                    //             <span
                    //               className="color__blue"
                    //               style={{ paddingLeft: "10px" }}
                    //               onClick={() => toggleCollapse(index)}
                    //             >
                    //               Read More
                    //             </span>
                    //           </>
                    //         ) : (
                    //           <>
                    //             {e.event_description}
                    //             <span
                    //               className="color__blue"
                    //               onClick={() => toggleCollapse(index)}
                    //             >
                    //               Read Less
                    //             </span>
                    //           </>
                    //         )}
                    //       </p>
                    //       <div
                    //         className={`${styles.availButtons} d-flex justify-content-center `}
                    //       >
                    //         <buton
                    //           className="bg__blue color__white mainFont w-100 text-center"
                    //           style={{
                    //             display: "flex",
                    //             justifyContent: "center",
                    //             alignItems: "center",
                    //             borderRadius: "30px",
                    //             cursor: "pointer",
                    //             backgroundColor: "grey",
                    //           }}
                    //         >
                    //           BOOKING CLOSED
                    //         </buton>
                    //         <img
                    //           src={MapIcon}
                    //           width={50}
                    //           className="mx-2"
                    //           onClick={() => {
                    //             window.location.href = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    //               e.event_location
                    //             )}`;
                    //           }}
                    //         />
                    //       </div>
                    //     </div>
                    //   </div>
                    // );
                  }
                })}
          </div>
        </div>
        <div className={`${styles.toPlayContainer} container`}>
          <center>
            <h2 className="mainFont color__blue fontBold">Sports To Play</h2>
            <br />
          </center>
        </div>
      </div>
      <div className={`${styles.desktopCarousel} container`}>
        <Carousel cols={3} rows={1} gap={10} loop>
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="70%" src={Frisbee} />
              <h2 className="text-center color__white mainFont mt-3">
                Frisbee
              </h2>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="70%" src={GameFrisbee} />
              <h2 className="text-center color__white mainFont mt-3">
                Dodgeball
              </h2>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="70%" src={Oztag} />
              <h2 className="text-center color__white mainFont mt-3">
                Tag Football
              </h2>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="70%" src={Soccer} />
              <h2 className="text-center color__white mainFont mt-3">Soccer</h2>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="70%" src={NFL} />
              <h2 className="text-center color__white mainFont mt-3">NFL</h2>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="70%" src={Basketball} />
              <h2 className="text-center color__white mainFont mt-3">
                Basketball
              </h2>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div
        style={{
          overflow: "auto",
          marginLeft: "10px",
          marginBottom: "100px",
        }}
        className={`${styles.mobileCarousel}`}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <img width="200px" src={Frisbee} />
          <h2 className="text-center color__white mainFont mt-3">Frisbee</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <img width="200px" src={GameFrisbee} />
          <h2 className="text-center color__white mainFont mt-3">Dodgeball</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <img width="200px" src={Oztag} />
          <h2 className="text-center color__white mainFont mt-3">
            Tag Football
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <img width="200px" src={Soccer} />
          <h2 className="text-center color__white mainFont mt-3">Soccer</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <img width="200px" src={NFL} />
          <h2 className="text-center color__white mainFont mt-3">NFL</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <img width="200px" src={Basketball} />
          <h2 className="text-center color__white mainFont mt-3">Basketball</h2>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};
export default GamePage;
