import React, { useEffect, useState } from "react";
import styles from "./AdminLogin.module.css";
import Logo from "../../images/logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const AdminLogin = () => {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("YWRtaW5fbG9naW4") == "dXNlciBpcyBsb2dnZWQgaW4=") {
      navigate("/admin/");
    }
  }, []);

  const loginHandler = () => {
    axios
      .post("https://3.106.200.146:3001/admin-login", {
        email: email,
        password: password,
      })
      .then((response) => {
        console.log(response);
        if (response.data.code == 99) {
          setMessage("An error occured!");
          setTimeout(() => setMessage(""), 3000);
        } else if (response.data.code == 0) {
          setMessage("Incorrect credentials!");
          setTimeout(() => setMessage(""), 3000);
        } else {
          setMessage("Logged in! Redirecting...");
          localStorage.setItem("YWRtaW5fbG9naW4", "dXNlciBpcyBsb2dnZWQgaW4=");
          navigate("/admin/");
          setTimeout(() => setMessage(""), 7000);
        }
      });
  };

  return (
    <div className={`${styles.adminLoginContainer}`}>
      <div className={`${styles.loginBox}`}>
        <img src={Logo} width={150} />
        <h3 className="mainFont color__grey">Admin Login</h3>
        <div className="mt-2">
          <label className="mainFont">Email Address</label>
          <br />
          <input
            className="w-100 mb-2"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
          />
          <label className="mainFont ">Password</label>
          <br />
          <input
            className="w-100"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
          />
        </div>
        {message != "" ? (
          <label className="color__red mainFont mt-2">{message}</label>
        ) : (
          ""
        )}
        <button className="bg__blue mt-4" onClick={loginHandler}>
          Log In
        </button>
      </div>
    </div>
  );
};

export default AdminLogin;
