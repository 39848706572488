import React, { useEffect, useState } from "react";
import styles from "./AddEvent.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const AddWeekly = () => {
  const [status, setStatus] = useState("none");
  const [eventLocation, setEventLocation] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [deposit, setDeposit] = useState(0);
  const [dateSelected, setDateSelected] = useState("");
  const [time, setTime] = useState("");
  const navigate = useNavigate();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const sendEventData = () => {
    let insertedId = 0;
    setStatus("Inserting Event...");
    let day = new Date(dateSelected).getDay();

    axios
      .post("https://3.106.200.146:3001/addWeeklyEvent", {
        weekly_location: eventLocation,
        weekly_time: time,
        weekly_day: daysOfWeek[day],
        weekly_date: dateSelected,
        weekly_description: eventDescription,
        deposit: deposit,
      })
      .then((response) => {
        if (response.data.code == 0) {
          alert("An error occured!");
        } else {
          alert("Data inserted!");
          navigate("/admin/weeklyevents");
        }
        setStatus("none");
      });
  };
  return (
    <div className="container-fluid mt-5">
      <div className={`${styles.formBackground}`}>
        <h5 className="mainFont fontMediumBold">Weekly Training Details</h5>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Weekly Training Location</label>
          <input
            className={`${styles.eventForm} w-100`}
            placeholder="Mackillop Ova"
            value={eventLocation}
            onChange={(e) => setEventLocation(e.target.value)}
          />
        </div>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">
            Weekly Training Description
          </label>
          <textarea
            className={`${styles.eventForm} w-100`}
            value={eventDescription}
            onChange={(e) => setEventDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="d-flex justify-content-end"></div>
      </div>

      <div className={`${styles.formBackground} mt-3`}>
        <h5 className="mainFont fontMediumBold">Date Details</h5>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Weekly Training Date</label>
          <input
            className={`${styles.eventForm} w-100`}
            type="date"
            onChange={(e) => setDateSelected(e.target.value)}
          />
        </div>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Time</label>
          <div className="d-flex">
            <select
              className={`${styles.eventForm}`}
              onChange={(e) => setTime(e.target.value)}
              style={{ marginRight: "10px" }}
              name="relation"
            >
              <option value="12 AM">12 AM</option>
              <option value="1 AM">1 AM</option>
              <option value="2 AM">2 AM</option>
              <option value="3 AM">3 AM</option>
              <option value="4 AM">4 AM</option>
              <option value="5 AM">5 AM</option>
              <option value="6 AM">6 AM</option>
              <option value="7 AM">7 AM</option>
              <option value="8 AM">8 AM</option>
              <option value="9 AM">9 AM</option>
              <option value="10 AM">10 AM</option>
              <option value="11 AM">11 AM</option>
              <option value="12 PM">12 PM</option>
              <option value="1 PM">1 PM</option>
              <option value="2 PM">2 PM</option>
              <option value="3 PM">3 PM</option>
              <option value="4 PM">4 PM</option>
              <option value="5 PM">5 PM</option>
              <option value="6 PM">6 PM</option>
              <option value="7 PM">7 PM</option>
              <option value="8 PM">8 PM</option>
              <option value="9 PM">9 PM</option>
              <option value="10 PM">10 PM</option>
              <option value="11 PM">11 PM</option>
            </select>
          </div>
        </div>
      </div>
      <div className={`${styles.formBackground} mt-3 mb-3`}>
        <h5 className="mainFont fontMediumBold">Deposit Details</h5>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Amount</label>
          <input
            className={`${styles.eventForm} w-100`}
            placeholder="30"
            value={deposit}
            onChange={(e) => setDeposit(e.target.value)}
          />
        </div>
      </div>
      <center>
        <button className="btn btn-primary px-5 mb-5" onClick={sendEventData}>
          {status == "none" ? "Add Event" : status}
        </button>
      </center>
    </div>
  );
};

export default AddWeekly;
