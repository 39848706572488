import React from "react";
import FirstImage from "../../images/about_image1.png";
import AboutImageBlue from "../../images/about_image_blue.png";
import SecondImage from "../../images/about_image2.png";
import ThirdImage from "../../images/about_image3.png";
import FourthImage from "../../images/about_image4.png";
import WhistleImage from "../../images/whistle.svg";
import AlarmIcon from "../../images/alarm.svg";
import BasketballIcon from "../../images/football.svg";
import Baseball from "../../images/baseball.svg";
import Rugby from "../../images/rugby.svg";
import { BlueBgButtonRounded } from "../../components/Buttons";
const AboutHero = ({ styles }) => {
  return (
    <div>
      <div className={`${styles.hero__container}`}>
        <img src={AboutImageBlue} />
        <div className={`${styles.rightSide}`}>
          <h2 className={`${styles.meet} color__orange mainFont fontBold`}>
            Meet
          </h2>
          <h2
            className={`${styles.holly_scout} color__orange mainFont fontBold`}
          >
            <br /> HOLLY AND SCOTT
          </h2>
          <p className={`${styles.hero__para} mainFont color__white`}>
            proud parents of three energetic boys who are always on the move.
            <br />
            <br />
            Their eldest boy suffered with some physical challenges early on in
            life, and they soon learned that he still wanted to keep up with his
            friends.
          </p>
        </div>
      </div>
      <div className={`${styles.secondHero__container}`}>
        <div className={`${styles.secondHero__textContainer}`}>
          <div className={`${styles.text__container}`}>
            <p className="color__white mainFont">
              This meant a little extra work from them all to improve his
              physicality so he didn’t feel left behind in sports. This is how{" "}
              <span className="color__blue mainFont fontBold">
                Growing Athletes TRAINING CAMPS
              </span>{" "}
              came about.
            </p>
          </div>
          <BlueBgButtonRounded
            text="Check Training Camp"
            external="yes"
            link="/weekly-training"
          />
          <br />
          <br />
          <div className={`${styles.text__container}`}>
            <p className="color__white mainFont">
              With custom programs created around children, GA’s trainers focus
              on helping kids build strength, stamina, speed and agility as well
              as understand how important daily exercise is for their health and
              wellbeing.
            </p>
          </div>
        </div>
        <img src={SecondImage} />
      </div>

      <div className={`${styles.mobile__containers}`}>
        <img src={AlarmIcon} className={`${styles.m_alarm}`} />
        <img src={BasketballIcon} className={`${styles.m_football}`} />
        <img src={Baseball} className={`${styles.m_baseball}`} />
        <img src={Rugby} className={`${styles.m_rugby}`} />
        <div className={`${styles.m_firstRow}`}>
          <img
            src={ThirdImage}
            width={230}
            style={{ paddingLeft: "10px", paddingTop: "83px" }}
          />
          <div className={`${styles.m_hollybox}`}>
            <h2 className="mainFont color__orange fontBold">Meet</h2>
            <h3 className="mainFont color__orange fontBold">
              HOLLY AND <br /> SCOTT
            </h3>
          </div>
          <div
            className="d-flex justify-content-end"
            style={{ paddingRight: "20px" }}
          >
            <p className={`${styles.holly_text} color__white mainFont`}>
              proud parents of three energetic boys who are always on the move.{" "}
              <br /> <br />
              Their eldest boy suffered with some physical challenges early on
              in life, and they soon learned that he still wanted to keep up
              with his friends.
            </p>
          </div>
        </div>

        <div className={`${styles.m_secondRow}`}>
          <div>
            <p className="color__white mainFont">
              This meant a little extra work from them all to improve his
              physicality so he didn’t feel left behind in sports. This is how
              Growing Athletes TRAINING CAMPS came about.
            </p>
            <div>
              <BlueBgButtonRounded
                text="Check Training Camps"
                external="yes"
                link="/weekly-training"
              />
            </div>
            <p className="color__white mainFont">
              With custom programs created around children, GA’s trainers focus
              on helping kids build strength, stamina, speed and agility as well
              as understand how important daily exercise is for their health and
              wellbeing.
            </p>
          </div>
          <img src={FourthImage} />
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
