import React from "react";
import styles from "./Buttons.module.css";
import ExternalIcon from "../images/external_link_icon.png";
import { useNavigate } from "react-router-dom";

const RedBgButtonRounded = (props) => {
  return (
    <button className={`${styles.RedRoundedBtn} fontMain fontSemiBold`}>
      {props.text}{" "}
      {props.external == "yes" ? (
        <img className={`${styles.externalIcon}`} src={ExternalIcon} />
      ) : (
        ""
      )}
    </button>
  );
};
const BlueBgButtonRounded = (props) => {
  const navigate = useNavigate();
  return (
    <button
      className={`${styles.BlueRoundedBtn} fontMain fontSemiBold`}
      onClick={() => {
        navigate(props.link);
      }}
    >
      {props.text}{" "}
      {props.external == "yes" ? (
        <img className={`${styles.externalIcon}`} src={ExternalIcon} />
      ) : (
        ""
      )}
    </button>
  );
};

export { RedBgButtonRounded, BlueBgButtonRounded };
