import React, { useEffect, useState } from "react";
import styles from "./Purchase.module.css";
import Navbar from "../../components/Navbar";
import PurchaseImage from "../../images/purchase_img.png";
import TeamBox from "./TeamBox";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import PP_Logo from "../../images/paypal_logo.svg";
import Mastercard from "../../images/mastercard.svg";
import Visa from "../../images/visa.svg";

import A1 from "../../images/a_1.png";
import A2 from "../../images/a_2.png";

import B1 from "../../images/b_1.png";
import B2 from "../../images/b_2.png";
import B3 from "../../images/b_3.png";
function formatDate(inputDate) {
  const options = { day: "numeric", month: "long" };
  const date = new Date(inputDate);
  const formattedDate = date.toLocaleDateString(undefined, options);
  return formattedDate;
}

const AllOut = ({ styles }) => {
  return (
    <div
      className="container"
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={`${styles.popUp}`}>
        <center>
          <h1 className="color__orange mainFont fontMedium">
            SORRY WE'RE ALL BOOKED OUT ):
          </h1>
          <p className="color__white mainFont">
            Submit your email to get notified for availability
          </p>
          <div>
            <label className="mainFont color__white ">
              Enter Email Address
            </label>
            <input />
          </div>
          <button className="mainFont bg__blue color__white">
            JOIN WAITLIST
          </button>
        </center>
      </div>
    </div>
  );
};

const Purchase = () => {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [eventData, setEventData] = useState([]);
  const [dates, setDates] = useState([]);
  const [showBox, setShowBox] = useState(true);
  const [dateSelected, setDateSelected] = useState(0);
  const [role, setRole] = useState(0);
  const [teamsNo, setTeamsNo] = useState(0);
  const [eventDate, setEventDate] = useState("");
  const changeRole = (e) => {
    setRole(e);
  };

  useEffect(() => {
    axios
      .get("https://3.106.200.146:3001/getEvent/" + eventId)
      .then((response) => setEventData(response.data[0]));
    axios
      .get("https://3.106.200.146:3001/getEventDates/" + eventId)
      .then((response) => {
        setDates(response.data);
        setEventDate(response.data[0].event_date);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    if (!loading) {
      axios
        .get(
          "https://3.106.200.146:3001/teamsNumber/" +
            eventData.id +
            "/" +
            eventDate
        )
        .then((response) => {
          setTeamsNo(response.data.result);
        });
    }
  }, [loading, dateSelected]);
  return (
    <>
      <Navbar />
      {/* <AllOut styles={styles} /> */}
      {teamsNo == 10 ? (
        <AllOut styles={styles} />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className={`${styles.hideDesktop}`}>
                <p className="color__white mainFont fontThin">
                  {teamsNo}/10 Teams
                </p>
                <h3 className="color__white mainFont fontMedium">
                  {loading
                    ? "Loading..."
                    : dates[dateSelected].age_relation == "And"
                    ? "For kids born in years " +
                      dates[dateSelected].age_from +
                      " and " +
                      dates[dateSelected].age_to
                    : dates[dateSelected].age_relation == "Between"
                    ? "For kids born between years " +
                      dates[dateSelected].age_from +
                      " - " +
                      dates[dateSelected].age_to
                    : "For kids born in years " + dates[dateSelected].age_from}
                </h3>
                <div
                  className={`${styles.dateAndLocation} d-flex align-items-center`}
                >
                  <h2
                    className={`${styles.date} color__orange mainFont fontBold allCaps`}
                  >
                    {loading
                      ? "Loading..."
                      : formatDate(dates[dateSelected].event_date)}
                  </h2>
                  <h4 className="color__white mainFont fontRegular">
                    {" "}
                    At {eventData.event_location}
                  </h4>
                  <br />
                </div>
              </div>
              <div
                className={`${styles.customScroller}`}
                style={{
                  width: "100%",
                  height: "800px",
                  overflow: "auto",
                }}
              >
                {role == 0 ? (
                  <>
                    <img src={A1} width="100%" />
                    <img src={A2} width="100%" />
                  </>
                ) : (
                  <>
                    {" "}
                    <img src={B1} width="100%" />
                    <img src={B2} width="100%" />
                    <img src={B3} width="100%" />
                  </>
                )}
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className={`${styles.hideMobile}`}>
                <p className="color__white mainFont fontThin">
                  {teamsNo}/10 Teams
                </p>
                <h3 className="color__white mainFont fontMedium">
                  {loading
                    ? "Loading..."
                    : dates[dateSelected].age_relation == "And"
                    ? "For kids born in years " +
                      dates[dateSelected].age_from +
                      " and " +
                      dates[dateSelected].age_to
                    : dates[dateSelected].age_relation == "Between"
                    ? "For kids born between years " +
                      dates[dateSelected].age_from +
                      " - " +
                      dates[dateSelected].age_to
                    : "For kids born in years " + dates[dateSelected].age_from}
                </h3>
                <div
                  className={`${styles.dateAndLocation} d-flex align-items-center`}
                >
                  <h2
                    className={`${styles.date} color__orange mainFont fontBold allCaps`}
                  >
                    {loading
                      ? "Loading..."
                      : formatDate(dates[dateSelected].event_date)}
                  </h2>
                  <h4 className="color__white mainFont fontRegular">
                    {" "}
                    At {eventData.event_location}
                  </h4>
                  <br />
                </div>
              </div>
              <div className={`${styles.datesContainer} d-flex`}>
                <p
                  className="color__white mainFont"
                  style={{ marginRight: "10px" }}
                >
                  Dates Available:{" "}
                </p>
                <div className="d-flex">
                  {dates.map((d, i) => (
                    <p
                      key={d.id}
                      className={`${
                        dateSelected == i ? "bg__blue" : ""
                      } color__white mainFont px-4`}
                      style={{
                        marginRight: "10px",
                        borderRadius: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => setDateSelected(i)}
                    >
                      {formatDate(d.event_date)}
                    </p>
                  ))}
                </div>
              </div>
              {role == 0 ? (
                <p className="color__grey mainFont fontRegular mt-3">
                  Fill in your child’s details below to register them for our
                  Game Day.
                  <br />
                  <br />
                  If you’re team Captain (first of your team), you will be given
                  a unique code to share with your teammates for them to join
                  your team.
                  <br />
                  <br />
                  If you are joining an existing team you will be given an
                  unique code.
                  <br />
                  <br />
                  Note* If you are registering your child individually, we will
                  do our best to place them with other kids they may know from
                  their school and or sports teams
                  <br />
                  <br />
                  Each child must enroll and pay individually.
                </p>
              ) : role == 1 ? (
                <p className="color__grey mainFont fontRegular mt-3">
                  Congrats! You're about to be named Team Captain!
                  <br />
                  <br />
                  Your registration fee pays for your child and secures your
                  team a spot in the Growing Athletes Game Day.
                  <br />
                  <br />
                  Once paid you wll receive a unique code to share with your
                  teammates so they too can register.
                  <br />
                  <br />
                  You will have 3 weeks for all teammates to join otherwise your
                  spot may be offered to teams on the waiting list.
                  <br />
                  <br />
                  Price: <b>${eventData.deposit} per child</b>
                  <br />
                  <br />
                  Note* If you are registering your child individually, we will
                  do our best to place them with other kids they may know from
                  their school and or sports teams
                  <br />
                  <br />
                  Each child must enroll and pay individually.
                </p>
              ) : role == 2 ? (
                <p className="color__grey mainFont fontRegular mt-3">
                  Congrats on being selected by your Captain to play in Growing
                  Athletes Game Day!
                  <br />
                  <br />
                  If you are joining an existing team you will be given an
                  unique code.
                  <br />
                  <br />
                  Note* If you are registering your child individually, we will
                  do our best to place them with other kids they may know from
                  their school and or sports teams
                  <br />
                  <br />
                  Each child must enroll and pay individually.
                </p>
              ) : (
                <p className="color__grey mainFont fontRegular mt-3">
                  Come and join us in the school holidays for a day out playing
                  sport with your friends.
                  <br />
                  <br />
                  It’s a full day of fun, games, prizes and surprises! Join as a
                  team or individually, but there’s limited spots so be quick!
                  <br />
                  <br />
                  More details and dates available below.
                </p>
              )}
              {loading ? (
                ""
              ) : (
                <TeamBox
                  styles={styles}
                  eventId={eventId}
                  date={dates[dateSelected].event_date}
                  deposit={eventData.deposit}
                  role={changeRole}
                  eventName={eventData.event_name}
                  eventLocation={eventData.event_location}
                />
              )}
            </div>
          </div>
          {/* <MyPaymentForm /> */}
        </div>
      )}
      <br />
      <br />
      <Footer />
    </>
  );
};
export default Purchase;
