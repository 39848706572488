import React from "react";
import Navbar from "../../components/Navbar";
import AboutHero from "./AboutHero";
import styles from "./About.module.css";
import Growing from "./Growing";
import Footer from "../../components/Footer";
import AboutMobile from "./AboutMobile";
const About = () => {
  return (
    <>
      <Navbar />
      <div className={`${styles.desktopView}`}>
        <AboutHero styles={styles} />
        <Growing styles={styles} />
        <br />
        <br />
      </div>
      <div
        style={{ overflow: "hidden", position: "relative" }}
        className={`${styles.mobileView}`}
      >
        <AboutMobile />
      </div>
      <Footer />
    </>
  );
};
export default About;
