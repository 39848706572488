import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import styles from "./BlogInner.module.css";
import Thumbnail from "../../images/blog1.png";
import { useNavigate, useParams } from "react-router-dom";
import Blog1 from "../../images/power_of_play.jpg";
import Blog2 from "../../images/team_sports.jpg";
import Blog3 from "../../images/link_between.jpg";
import Blog4 from "../../images/game_day.jpg";
const BlogInner = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  const navigate = useNavigate();
  const blogs = [
    {
      index: 1,
      image: Blog1,
      title:
        "The Power of Play: Why Physical Activity is Crucial for Children's Development",
      slug: "the-power-of-play",
      summary: `In a world dominated by screens and technology, the significance of physical activity for children
            cannot be overstated. Engaging in regular physical activity is not just about building healthy
            bodies; it is a key element in promoting overall well-being, fostering social skills, and enhancing
            cognitive development in our youngest generation.`,
      description: `In a world dominated by screens and technology, the significance of physical activity for children
                cannot be overstated. Engaging in regular physical activity is not just about building healthy
                bodies; it is a key element in promoting overall well-being, fostering social skills, and enhancing
                cognitive development in our youngest generation.
                <br />
                <br />
                <h2>1. Physical Health:</h2> It's no secret that physical activity is essential for the development of
                strong and healthy bodies. Regular exercise helps children build strong bones and muscles,
                maintain a healthy weight, and develop cardiovascular fitness. Additionally, physical activity in
                childhood has been linked to a reduced risk of chronic diseases such as obesity, diabetes, and
                heart disease later in life.
                <br />
                <br />
                <h2>2. Mental Health</h2> Physical activity is a powerful tool for promoting mental well-being in
                children. Exercise has been shown to reduce stress, anxiety, and symptoms of depression. When
                children engage in physical play, their bodies release endorphins, the &quot;feel-good&quot; hormones,
                which contribute to a positive mood and a sense of well-being.
                <br />
                <br />
                <h2>3. Cognitive Development:</h2> Beyond the playground, the benefits of physical activity extend to
                the classroom. Research indicates that children who are physically active demonstrate improved
                concentration, better academic performance, and enhanced cognitive function. Regular exercise
                has been associated with increased attention spans, improved memory, and better problem-
                solving skills, all of which contribute to a more effective learning experience.
                <br />
                <br />
                <h2>4. Social Skills</h2> Physical activity provides children with opportunities to interact with their
                peers, fostering the development of crucial social skills. Whether it's playing team sports or
                engaging in cooperative games, children learn how to communicate, collaborate, and resolve
                conflicts. These social interactions contribute to the development of important life skills that will
                serve them well in adulthood.
                <br />
                <br />
                <h2>5. Healthy Habits for Life</h2> Instilling a love for physical activity in childhood sets the
                foundation for a lifetime of healthy habits. Children who are active are more likely to carry these
                habits into adulthood, reducing the risk of sedentary-related health issues. Encouraging physical
                play from a young age helps establish a positive attitude toward exercise and promotes an active
                lifestyle throughout life.
                <br />
                <br />
                So as you can see, the importance of physical activity for children cannot be emphasized enough.
                It is a holistic approach to nurturing healthy, happy, and well-rounded individuals. As parents,
                educators, and caregivers, our role is to provide environments that encourage and support the
                physical development of our children, setting them on a path towards a lifetime of health and well-
                being. So, let's get our children moving, playing, and thriving!
                We at Growing Athletes are all about this! Getting childrens bodies moving daily to benefit their
                health and fitness as well as mental strength and confidence. Book your child into one of our
                Growing Athletes Training Camps <a href="https://growingathletes.com.au/weekly-training#">here</a>. `,
    },
    {
      index: 2,
      image: Blog2,
      title:
        "Team sport benefits: Growing Athletes Explains Why Team Sports at Game Day are so important.",
      slug: "team-sport-benefits",
      summary: `I’m booooreeed… As parents we’re all too familiar with this saying happening multiple times on the
            daily from our kids whilst on school holidays. Let’s face it, 6 weeks off over the Christmas / New
            Year break can be both great for spending time together as a family with loved one and having
            time to do those things you’ve waited all year to do`,
      description: `I’m booooreeed… As parents we’re all too familiar with this saying happening multiple times on the
            daily from our kids whilst on school holidays. Let’s face it, 6 weeks off over the Christmas / New
            Year break can be both great for spending time together as a family with loved one and having
            time to do those things you’ve waited all year to do, but also 6 weeks can also be a long time to fill
            our childrens calendars with activities. Leaning on grandparents, aunties and uncles, friends,
            holiday care and everyone in between, by the time back to school comes around, it’s always
            welcomed.
            <br />
            <br />
            Well, consider our GAME DAY a day to mark on the summer holidays calendar as ‘children taken
            care of’. We run a sport camp in Kincumber, on the Central Coast for kids to come along, join a
            team and play sports all day, having fun, building friendships and staying active and happy! You
            can check more out about it here <a href="https://growingathletes.com.au/gameday">https://growingathletes.com.au/gameday</a>
            <br />
            <br />
            The bonus of camps like this is the underlying fact that your children are not only taken care of for
            the day, they are also learning crucial to life team skills while participating without even realising it!
            There are so many reasons kids should include playing in team, some of which are:
            <br />
            <br />
            <h2>1. Physical Fitness: A Foundation for a Healthy Life</h2> 
            Growing Athletes is committed to providing a platform for children to experience the joy of
            movement and understand the importance of maintaining an active lifestyle from a young age. And
            their Game Day is no different! Coming together for a full day of playing sports is a fun and
            engaging way for children to stay physically active. Sports like soccer, basketball, oztag and more
            are played and all provides unique opportunities for cardiovascular exercise, muscle development,
            and overall physical fitness.
            <br />
            <br />
            <h2>2. Social Skills: Teamwork Makes the Dream Work</h2> 
            Participating in team sports fosters essential social skills that are invaluable throughout life.
            Learning to work with others, communicate effectively, and understand the dynamics of teamwork
            are crucial aspects of a child's social development. Growing Athletes Game Day recognizes the
            significance of sports in building camaraderie and friendships, enhancing a child's ability to
            collaborate with peers, and developing strong interpersonal skills.
            <br />
            <br />
            <h2>3. Discipline and Time Management: Lessons Beyond the Field</h2> 
            In addition to physical and social benefits, sports instill essential life skills such as discipline and
            time management. Committing to regular practice sessions and adhering to game schedules teach
            children the importance of responsibility and the value of dedicating time to various activities.
            Growing Athletes Game Day understands that these lessons extend far beyond the field, providing
            a solid foundation for success in academics and future endeavours.
            <br />
            <br />
            <h2>4. Confidence and Self-Esteem: Achieving Personal Bests</h2> 
            Engaging in sports helps children set and achieve personal goals, leading to increased confidence
            and self-esteem. Whether mastering a new skill, scoring a goal, or contributing to a team victory,
            these accomplishments contribute to a child's sense of pride and self-worth. Growing Athletes
            Game Day is dedicated to creating an environment where children can discover their strengths,
            celebrate achievements, and build a positive self-image through sports participation.
            <br />
            <br />
            <h2>5. Lifelong Passion for Fitness: Planting the Seeds for a Healthy Future</h2> 
            Growing Athletes Game Day envisions a future where the love for sports cultivated during
            childhood evolves into a lifelong passion for fitness. By introducing children to a variety of sports,
            the program aims to help them discover activities they genuinely enjoy, fostering a commitment to
            an active lifestyle that extends into adulthood.
            <br />
            <br />
            In conclusion, Growing Athletes Game Day believes that playing sports is not just about physical
            activity; it's about nurturing the holistic development of children. Through a commitment to physical
            fitness, social skills, discipline, confidence, and a lifelong passion for fitness, Growing Athletes
            Game Day is dedicated to creating an environment where children can thrive and grow into
            healthy, well-rounded individuals. Join us on this exciting journey as we empower the next
            generation through the power of play! Book your child’s spot today here
            <a href="https://growingathletes.com.au/gameday">https://growingathletes.com.au/gameday</a>`,
    },
    {
      index: 3,
      image: Blog3,
      title:
        "The Link Between Exercise and Well-Behaved Children: A Closer Look",
      slug: "link-between-exercise-and-well-behaved-children",
      summary: `In today's fast-paced digital age, where children are constantly bombarded with screens and
            electronic devices, maintaining their physical health has never been more crucial. However, the
            benefits of exercise extend beyond mere physical well-being; they also play a pivotal role in
            shaping a child's behavior and overall demeanor.`,
      description: `In today's fast-paced digital age, where children are constantly bombarded with screens and
            electronic devices, maintaining their physical health has never been more crucial. However, the
            benefits of exercise extend beyond mere physical well-being; they also play a pivotal role in
            shaping a child's behavior and overall demeanor. This article explores the profound connection
            between regular physical activity and well-behaved children.
            <br />
            <br />
            <h2>Physical Outlet for Energy</h2>
            Children are bundles of energy, and without a proper outlet, that energy can manifest in less
            desirable ways. Regular exercise provides an avenue for children to release pent-up energy,
            reducing restlessness and fidgeting. Engaging in activities that get their hearts pumping not only
            improves their physical health but also channels their energy positively, leading to a more focused
            and composed demeanor.
            <br />
            <br />
            <h2>Stress Reduction</h2>
            Just like adults, children can experience stress, whether it be from school, peer interactions, or
            family dynamics. Exercise is a natural stress reliever, triggering the release of endorphins, the
            body's feel-good hormones. When children engage in physical activities, they experience a natural
            boost in mood, which can significantly contribute to better behavior and a more positive outlook.
            <br />
            <br />
            <h2>Improved Sleep Patterns</h2>

            A well-rested child is more likely to exhibit good behavior. Regular exercise has been linked to
            improved sleep patterns, helping children achieve the recommended amount of rest for their age.
            Quality sleep is essential for cognitive function and emotional regulation, both of which are closely
            tied to behavior. By incorporating physical activity into a child's routine, parents can positively
            influence their sleep quality, indirectly contributing to well-mannered behavior.
            <br />
            <br />
            <h2>Enhanced Cognitive Function</h2>
            Exercise isn't just beneficial for the body; it also has a profound impact on cognitive function.
            Physical activity stimulates the brain, improving concentration, memory, and problem-solving
            skills. Children who engage in regular exercise are more likely to excel academically and exhibit
            better decision-making abilities. The positive cognitive effects of exercise extend to better self-
            control and a heightened ability to follow rules and instructions.
            <br />
            <br />
            <h2>Social Skills Development</h2>
            Many forms of exercise involve teamwork and social interaction, providing children with
            opportunities to develop crucial social skills. Team sports, group classes, or even simple
            playground activities help children learn how to cooperate, communicate effectively, and navigate
            social situations. These social skills, in turn, contribute to improved behavior, as children become
            more adept at expressing themselves and understanding others.
            <br />
            <br />
            <h2>Setting Healthy Habits for Life</h2>
            Encouraging regular exercise in childhood establishes a foundation for a healthy lifestyle that can
            persist into adulthood. Children who grow up understanding the importance of physical activity are
            more likely to prioritize their well-being as they age. This long-term perspective on health can lead
            to positive behavioural choices, reinforcing the link between exercise and well-rounded, well-
            behaved individuals.
            <br />
            <br />
            The connection between exercise and well-behaved children is multifaceted, encompassing
            physical, emotional, and cognitive benefits. At Growing Athletes, we believe that by incorporating
            regular physical activity into a child's routine, helps to mould children overall into better people as
            they grow.
            <br />
            <br />
            We run Weekly Training Camps for children to attend weekly which helps kids stay active and
            build good habits from a young age. You can find your local Training Camp here
            <a href="https://growingathletes.com.au/weekly-training">https://growingathletes.com.au/weekly-training</a>`,
    },
    {
      index: 4,
      image: Blog4,
      title: "GAME DAY The Place to be this School Holidays.",
      slug: "game-day-place-to-be",
      summary: `Week to week trainers at Growing Athletes have decided to run a very special event in the
          summer school holidays. Game Day in Kincumber on the Central Coast will be a fun day of sports
          and activities for children and their friends to participate in.`,
      description: `Week to week trainers at Growing Athletes have decided to run a very special event in the
            summer school holidays. Game Day in Kincumber on the Central Coast will be a fun day of sports
            and activities for children and their friends to participate in.
            <br />
            <br />
            <b>1. Variety of Sports Stations:</b>
            <ul><li>Sports included for the upcoming January Game Day camps are: soccer, basketball,
            dodgeball, tag NFL, oztag.</li></ul>

            <b>2. Age-Appropriate Activities:</b>
            <ul>
                <li>Split into 2 days to keep teams fair. 7 &amp; 8 years olds will play on the 17h Jan and 9 &amp;
                10 years olds on the 18 th January.</li>
                <li>These games will have refs and teams will have coaches to help them do their best.</li>
            </ul>
            <b>3. Team Building:</b>
            <ul>
                <li>All sports are team sports and children are encouraged to include everyone in their
                team and ensure everyone gets a go. Winning isn’t everything.</li>
            </ul>
            <b>4. Healthy Snacks and Hydration Stations:</b>
            <ul>
                <li>Lunch will be provided as well as fruit and water on the day.</li>
                <li>Also supplied by our friends at Priceline, sunscreen will be everywhere on the day to
                ensure kids aren’t getting sunburnt.</li>
            </ul>
            <b>5. Prizes and Awards:</b>
            <ul>
                <li>Whilst game wins will be recorded on a whiteboard for kids to see who their team is
                doing, winning isn’t everything. Kids will be selected at random by coaches and refs
                for good sportsmanship and friendly attitudes through the day.</li>

            </ul>
            <b>6. Music and Entertainment:</b>
            <ul>
                <li>Game Day is all about having fun with your mates, whether it’s playing sport or just
                jiving to the music in the background, it’s a fantastic FUN day out for the kids!</li>

            </ul>
            <b>7. Qualified Staff:</b>
            <ul>
                <li>All staff at Game Day are Encourage parents, teachers, and community members to
                volunteer in organizing and overseeing the event. Their involvement can contribute
                to the success of the day.</li>

            </ul>
            <b>8. Photography and Memories:</b>
            <ul>
                <li>We will have Little Sports Photography onsite taking photos of your children through
                their day. These photos can be purchased after the fact if parents wish to do so.</li>

            </ul>
            <b>9. Safety Measures:</b>
            <ul>
            <li>First aid tent and supplies are on site along with trained staff. Safety protocols are
            explained to children at the start of the day included emergency situation
            instructions.</li>

        </ul>
            <br />
            <br />
            Summer School Holidays of January 2024 is going to be epic with Growing Athletes Game Day!
            Check it out here and secure your childs spot today as numbers are very limited!
            www.growingathletes.com.au`,
    },
  ];
  const { slug } = useParams();
  return (
    <>
      <Navbar />
      {blogs.map((b) => {
        if (b.slug == slug) {
          return (
            <>
              <section className={`${styles.blogInnerHero}`}>
                <h1 className="mainFont color__white">{b.title}</h1>
                <div
                  className={`${styles.thumbnailContainer}`}
                  style={{ backgroundImage: `url(${b.image})` }}
                ></div>
              </section>
              <section className={`${styles.contentContainer} container`}>
                <p
                  className="mainFont"
                  dangerouslySetInnerHTML={{ __html: b.description }}
                ></p>
              </section>
            </>
          );
        }
      })}
      <section className="mt-5 container">
        <h2 className="mainFont color__white mb-3">Recommended</h2>
        <div className="row">
          {blogs.map((b) => {
            if (b.slug != slug) {
              return (
                <div
                  className="col-12 col-md-4"
                  onClick={() => navigate("/blog-inner/" + b.slug)}
                  style={{ cursor: "pointer" }}
                >
                  <div className={`${styles.blogContainer}`}>
                    <div
                      className={`${styles.blogThumbnail}`}
                      style={{ backgroundImage: `url(${b.image})` }}
                    ></div>
                    <div className="px-4">
                      <h3 className="mainFont fontBold pt-3 pb-3">{b.title}</h3>
                      <p className="mainFont fontRegular pb-3">{b.summary}</p>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </section>
      <Footer />
    </>
  );
};
export default BlogInner;
