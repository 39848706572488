import React, { useEffect, useState } from "react";
import styles from "./Events.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const WeeklyEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchEventName, setSearchEventName] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [searchDeposit, setSearchDeposit] = useState("");
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    axios.get("https://3.106.200.146:3001/getWeeklyEvents").then((response) => {
      setLoading(false);
      setEvents(response.data.result);
    });
  }, [refresh]);
  const filteredEvents = events.filter((e) => {
    const eventNameMatch = e.weekly_location
      .toLowerCase()
      .includes(searchEventName.toLowerCase());
    const locationMatch = e.weekly_day
      .toLowerCase()
      .includes(searchLocation.toLowerCase());
    const depositMatch = e.deposit.toString().includes(searchDeposit);

    return eventNameMatch && locationMatch && depositMatch;
  });

  const deleteHandler = (e) => {
    axios
      .get("https://3.106.200.146:3001/delete-weekly/" + e)
      .then((response) => {
        if (response.data.code == 1) {
          setRefresh(!refresh);
        }
      });
  };
  const updateVisibility = (e, para) => {
    axios
      .get("https://3.106.200.146:3001/update-weekly/" + e + "/" + para)
      .then((response) => {
        if (response.data.code == 1) {
          setRefresh(!refresh);
        }
      });
  };
  return (
    <>
      <div className="container">
        <h3 className="mainFont">All Weekly Trainings</h3>
      </div>

      <div className={`${styles.tableContainer} container`}>
        <table className={`${styles.customTable} table`}>
          <thead>
            <tr className="mainFont fontThin">
              <th scope="col">Event Location</th>
              <th scope="col">Time</th>
              <th scope="col">Day</th>
              <th scope="col">Date</th>
              <th scope="col">Booking</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading
              ? filteredEvents.map((e) => (
                  <tr key={e.id} className="mainFont fontThin">
                    <td style={{ paddingTop: "15px" }}>{e.weekly_location}</td>
                    <td style={{ paddingTop: "15px" }}>{e.weekly_time}</td>
                    <td style={{ paddingTop: "15px" }}>{e.weekly_day}</td>
                    <td style={{ paddingTop: "15px" }}>{e.weekly_date}</td>
                    <td style={{ paddingTop: "15px" }}>{e.booking_count}</td>
                    <td className="d-flex" style={{ paddingTop: "15px" }}>
                      <p
                        className="color__red"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => deleteHandler(e.id)}
                      >
                        Delete
                      </p>
                      <p
                        className="color__blue"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() =>
                          updateVisibility(
                            e.id,
                            e.visible == "yes" ? "no" : "yes"
                          )
                        }
                      >
                        {e.visible == "yes" ? "Hide event" : "Show event"}
                      </p>
                      <p
                        className="color__blue"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/admin/booking-details/" + e.id)
                        }
                      >
                        View Bookings
                      </p>
                    </td>
                  </tr>
                ))
              : "Loading..."}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WeeklyEvents;
