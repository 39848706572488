import React from "react";
import {
  BlueBgButtonRounded,
  RedBgButtonRounded,
} from "../../components/Buttons";
import HeroImage from "../../images/hero_img.png";
import TennisIcon from "../../images/tennis.svg";
import RugbyIcon from "../../images/rugby.svg";
const HeroSection = ({ styles }) => {
  return (
    <div className={`${styles.hero__fluidContainer} container-fluid`}>
      <img src={RugbyIcon} className={`${styles.rugbyIcon}`} />
      <div className={`${styles.hero__container} container`}>
        <div className="row">
          <div className="col-md-7 col-12">
            <div className="d-flex flex-column align-items-start">
              <h2
                className={`${styles.hero__heading} mainFont fontExtraBold color__orange`}
              >
                Instilling Good Habits, Training Willpower
              </h2>
              <div className={`${styles.heroParaContainer}`}>
                <p className={`${styles.heroPara} color__white mainFont`}>
                  Training children to be active from a young age fosters a
                  lifetime of healthy habits and enjoyment in sports.
                  Encouraging kids to engage in physical activities and play
                  sports not only helps develop their physical fitness but also
                  builds essential skills like teamwork and discipline.
                  <br />
                  By integrating fun, age-appropriate exercises and games into
                  their routines, children learn the importance of maintaining
                  good health and staying active. This early foundation supports
                  their overall well-being and sets the stage for a balanced and
                  active lifestyle as they grow.
                </p>
                <p className={`${styles.heroPara} color__white mainFont`}>
                  Based on the Central Coast NSW, Growing Athletes runs a
                  variety of programs to help children become the best version
                  of themselves. <br /> <br /> Their Training Camps offer a
                  dynamic environment where children can learn and refine
                  physical skills crucial for their athletic development and
                  incorporating the foundations of a healthy lifestyle.
                  <br /> <br /> These camps focus on age-appropriate drills and
                  exercises that enhance strength, coordination, and agility,
                  helping young athletes build a solid foundation for future
                  sports endeavours.
                  <br /> <br /> Through engaging activities and expert guidance,
                  children not only improve their physical abilities but also
                  gain confidence, teamwork skills, and a love for staying
                  active. It’s a fun and effective way for kids to grow,
                  develop, and discover their potential in sports.
                </p>
                <BlueBgButtonRounded
                  text="WEEKLY TRAINING CAMPS"
                  external="yes"
                  link="weekly-training"
                />
                <br />

                <p className={`${styles.heroPara} color__white mainFont`}>
                  <br />
                  Their GAME DAY school activity camps are a fantastic
                  opportunity for children to explore new sports, make new
                  friends, and have a blast. These camps offer a diverse range
                  of activities that introduce kids to different sports and
                  games in a fun, supportive environment. Beyond just learning
                  new skills, children enjoy the chance to socialize,
                  collaborate, and build lasting friendships. <br /> <br /> With
                  a focus on enjoyment and personal growth, school activity
                  camps provide a memorable and enriching experience that
                  combines physical activity with social interaction for primary
                  school aged children.
                </p>

                <BlueBgButtonRounded
                  text="SCHOOL HOLIDAY GAME DAY"
                  external="yes"
                  link="/gameday"
                />
              </div>
            </div>
          </div>
          <div className={`${styles.heroImage__col} col-5`}>
            <div className={`${styles.heroImage__container}`}>
              <img
                src={HeroImage}
                className={`${styles.hero__image}`}
                style={{ width: "90%" }}
              />
              <img src={TennisIcon} className={`${styles.hero__tennis}`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
