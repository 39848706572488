import React from "react";
import Navbar from "../../components/Navbar";
import HeroSection from "./HeroSection";
import styles from "./Home.module.css";
import SportsPlayed from "./SportsPlayed";
import GetStarted from "./GetStarted";
import ContactUs from "./ContactUs";
import Footer from "../../components/Footer";
import Newsletter from "./Newsletter";
import MobileHome from "./MobileHome";
const Home = () => {
  return (
    <>
      <Navbar icon="yes" />
      <div className={`${styles.desktopView}`}>
        <HeroSection styles={styles} />
        <SportsPlayed styles={styles} />
        <ContactUs styles={styles} />
        <Newsletter />
      </div>
      <div className={`${styles.mobileView}`}>
        <MobileHome />
      </div>
      <Footer />
    </>
  );
};

export default Home;
