import React, { useEffect, useState } from "react";
import styles from "./AddEvent.module.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
const EditEvent = () => {
  const { eventId } = useParams();
  const [status, setStatus] = useState("none");
  const [dates, setDates] = useState([]);
  const [eventTitle, setEventTitle] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [oldDates, setOldDates] = useState([]);
  const [deposit, setDeposit] = useState(0);
  const [closed, setClosed] = useState("NO");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://3.106.200.146:3001/getEditEventDetails/" + eventId)
      .then((response) => {
        setEventTitle(response.data.result[0].event_name);
        setEventLocation(response.data.result[0].event_location);
        setEventDescription(response.data.result[0].event_description);
        setDeposit(response.data.result[0].deposit);
        setClosed(response.data.result[0].closed);
      });

    axios
      .get("https://3.106.200.146:3001/getEventDates/" + eventId)
      .then((response) => setOldDates(response.data));
  }, []);

  const initialFormData = {
    eventDate: "",
    relation: "only",
    year_one: "",
    year_two: "",
  };
  const addDates = () => {
    setDates([...dates, { id: dates.length, data: { ...initialFormData } }]);
  };

  const sendEventData = () => {
    // let insertedId = 0;
    // setStatus("Inserting Event...");
    // axios
    //   .post("https://3.106.200.146:3001/addevent", {
    //     event_name: eventTitle,
    //     event_location: eventLocation,
    //     event_description: eventDescription,
    //     deposit: deposit,
    //   })
    //   .then((response) => {
    //     setStatus("Adding Dates");
    //     insertedId = response.data.insertedId;
    //     for (let i = 0; i < dates.length; i++) {
    //       axios
    //         .post("https://3.106.200.146:3001/add-date", {
    //           event_id: insertedId,
    //           event_date: dates[i].data.eventDate,
    //           age_from: dates[i].data.year_one,
    //           age_relation: dates[i].data.relation,
    //           age_to: dates[i].data.year_two,
    //         })
    //         .then((response) => navigate("/admin/events"));
    //     }
    //     navigate("/admin/events");
    //     setStatus("none");
    //   });
  };

  const updateChanges = () => {
    setStatus("Updating");
    axios
      .post("https://3.106.200.146:3001/updateEvent", {
        event_name: eventTitle,
        event_location: eventLocation,
        event_description: eventDescription,
        event_id: eventId,
        deposit: deposit,
      })
      .then((response) => {
        if (response.data.code == 1) {
          for (let i = 0; i < oldDates.length; i++) {
            axios
              .post("https://3.106.200.146:3001/update-date", {
                event_id: eventId,
                event_date: oldDates[i].event_date,
                age_from: oldDates[i].age_from,
                age_relation: oldDates[i].age_relation,
                age_to: oldDates[i].age_to,
                date_id: oldDates[i].id,
              })
              .then((response2) => {
                if (response.data.code == 1) {
                  navigate("/admin/events");
                }
              });
          }
        }
      });
  };
  const handleFormChange = (formId, fieldName, value) => {
    setDates(
      dates.map((form) => {
        if (form.id === formId) {
          return {
            ...form,
            data: {
              ...form.data,
              [fieldName]: value,
            },
          };
        }
        return form;
      })
    );
  };
  const handleDateChange = (event, index) => {
    const { name, value } = event.target;
    setOldDates((prevDates) => {
      const updatedDates = [...prevDates];
      updatedDates[index] = { ...updatedDates[index], [name]: value };
      return updatedDates;
    });
  };
  useEffect(() => {
    console.log(oldDates);
  }, [oldDates]);
  return (
    <div className="container-fluid mt-5">
      <div className={`${styles.formBackground}`}>
        <h5 className="mainFont fontMediumBold">Event Details</h5>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Event Title</label>
          <input
            className={`${styles.eventForm} w-100`}
            value={eventTitle}
            onChange={(e) => setEventTitle(e.target.value)}
          />
        </div>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Event Location</label>
          <input
            className={`${styles.eventForm} w-100`}
            placeholder="Mackillop Ova"
            value={eventLocation}
            onChange={(e) => setEventLocation(e.target.value)}
          />
        </div>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Event Description</label>
          <textarea
            className={`${styles.eventForm} w-100`}
            value={eventDescription}
            onChange={(e) => setEventDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="d-flex justify-content-end"></div>
      </div>
      {oldDates.map((o, index) => (
        <div className={`${styles.formBackground} mt-3`}>
          <h5 className="mainFont fontMediumBold">Date Details</h5>
          <div className="w-100 mt-3">
            <label className="mainFont fontThin">Event Date</label>
            <input
              className={`${styles.eventForm} w-100`}
              type="date"
              name="event_date"
              value={o.event_date}
              onChange={(e) => handleDateChange(e, index)}
            />
          </div>
          <div className="w-100 mt-3">
            <label className="mainFont fontThin">Age Group</label>
            <div className="d-flex">
              <select
                className={`${styles.eventForm}`}
                style={{ marginRight: "10px" }}
                name="age_relation"
                onChange={(e) => handleDateChange(e, index)}
              >
                <option
                  value="Only"
                  selected={o.age_relation == "Only" ? true : false}
                >
                  Only
                </option>
                <option
                  value="And"
                  selected={o.age_relation == "And" ? true : false}
                >
                  And
                </option>
                <option
                  value="Between"
                  selected={o.age_relation == "Between" ? true : false}
                >
                  Between
                </option>
              </select>
              <input
                className={`${styles.eventForm}`}
                style={{ marginRight: "10px" }}
                type="number"
                name="age_from"
                placeholder="Min Age"
                value={o.age_from}
                onChange={(e) => handleDateChange(e, index)}
              />
              <input
                className={`${styles.eventForm}`}
                style={{ marginRight: "10px" }}
                type="number"
                name="age_to"
                placeholder="Max Age"
                value={o.age_to}
                onChange={(e) => handleDateChange(e, index)}
              />
            </div>
          </div>
        </div>
      ))}
      {dates.map((form) => (
        <div key={form.id}>
          <DatesForm
            formData={form.data}
            formId={form.id}
            onFormChange={handleFormChange}
          />
        </div>
      ))}
      <div className={`${styles.formBackground} mt-3 mb-3`}>
        <h5 className="mainFont fontMediumBold">Deposit Details</h5>
        <div className="w-100 mt-3">
          <label className="mainFont fontThin">Amount</label>
          <input
            className={`${styles.eventForm} w-100`}
            placeholder="30"
            value={deposit}
            onChange={(e) => setDeposit(e.target.value)}
          />
        </div>
      </div>
      <div className={`${styles.formBackground} mt-3 mb-3`}>
        <h5 className="mainFont fontMediumBold">Close Booking</h5>
        <div className="w-100 mt-3">
          <select
            className={`${styles.eventForm}`}
            style={{ marginRight: "10px" }}
            onChange={(e) => {
              setStatus("Updating...");
              axios
                .post("https://3.106.200.146:3001/update-closed", {
                  event_id: eventId,
                  closed: e.target.value,
                })
                .then((response) => {
                  setStatus("none");
                });
            }}
          >
            <option value="YES" selected={closed == "YES" ? true : false}>
              YES
            </option>
            <option value="NO" selected={closed == "NO" ? true : false}>
              NO
            </option>
          </select>
        </div>
      </div>
      <center>
        <button className="btn btn-primary px-5 mb-5" onClick={updateChanges}>
          {status == "none" ? "Update Event" : status}
        </button>
      </center>
    </div>
  );
};

const DatesForm = ({ formData, formId, onFormChange }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onFormChange(formId, name, value);
  };

  return (
    <div className={`${styles.formBackground} mt-3`}>
      <h5 className="mainFont fontMediumBold">Date Details</h5>
      <div className="w-100 mt-3">
        <label className="mainFont fontThin">Event Date</label>
        <input
          className={`${styles.eventForm} w-100`}
          type="date"
          name="eventDate"
          value={formData.eventDate}
          onChange={handleInputChange}
        />
      </div>
      <div className="w-100 mt-3">
        <label className="mainFont fontThin">Age Group</label>
        <div className="d-flex">
          <select
            className={`${styles.eventForm}`}
            style={{ marginRight: "10px" }}
            name="relation"
            value={formData.relation}
            onChange={handleInputChange}
          >
            <option value="Only">Only</option>
            <option value="And">And</option>
            <option value="Between">Between</option>
          </select>
          <input
            className={`${styles.eventForm}`}
            style={{ marginRight: "10px" }}
            type="number"
            name="year_one"
            placeholder="Min Age"
            value={formData.year_one}
            onChange={handleInputChange}
          />
          <input
            className={`${styles.eventForm}`}
            style={{ marginRight: "10px" }}
            type="number"
            name="year_two"
            placeholder="Max Age"
            value={formData.year_two}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default EditEvent;
