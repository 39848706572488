import React, { useEffect, useState } from "react";
import styles from "./AddEvent.module.css";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const Waiver = () => {
  const [status, setStatus] = useState("none");
  const [weeklyAgreement, setWeeklyAgreement] = useState("");
  const [gameAgreement, setGameAgreement] = useState("");

  useEffect(() => {
    axios
      .get("https://3.106.200.146:3001/get-waiver/1")
      .then((response) =>
        setWeeklyAgreement(response.data.result[0].agreement)
      );
    axios
      .get("https://3.106.200.146:3001/get-waiver/2")
      .then((response) => setGameAgreement(response.data.result[0].agreement));
  }, []);

  const sendEventData = () => {
    setStatus("Updating...");
    axios
      .post("https://3.106.200.146:3001/update-waiver", {
        w1: weeklyAgreement,
        w2: gameAgreement,
      })
      .then((response) => {
        if (response.data.code == 0) {
          alert("An error occured!");
          setStatus("");
        } else {
          setStatus("Updated");
          setTimeout(() => setStatus("none"), 3000);
        }
      });
  };
  return (
    <div className="container-fluid mt-5">
      <div className={`${styles.formBackground}`}>
        <h5 className="mainFont fontMediumBold">Weekly Training Waiver</h5>
        <div className="w-100 mt-3">
          <CKEditor
            editor={ClassicEditor}
            data={weeklyAgreement}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();

              setWeeklyAgreement(data);
            }}
            onBlur={(event, editor) => {
              console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              console.log("Focus.", editor);
            }}
          />
        </div>
      </div>
      <div className={`${styles.formBackground} mt-3`}>
        <h5 className="mainFont fontMediumBold">Game Day Waiver</h5>
        <div className="w-100 mt-3">
          <CKEditor
            editor={ClassicEditor}
            data={gameAgreement}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setGameAgreement(data);
            }}
            onBlur={(event, editor) => {
              console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              console.log("Focus.", editor);
            }}
          />
        </div>
      </div>
      <center>
        <button
          className="btn btn-primary px-5 mb-5 mt-3"
          onClick={sendEventData}
        >
          {status == "none" ? "Update" : status}
        </button>
      </center>
    </div>
  );
};

export default Waiver;
