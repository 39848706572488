import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./Teams.module.css";

const Members = ({ styles, teamCode }) => {
  const [captain, setCaptain] = useState([]);
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  useEffect(() => {
    axios
      .get("https://3.106.200.146:3001/teamCaptain/" + teamCode)
      .then((response) => {
        setCaptain(response.data.result);
        axios
          .get("https://3.106.200.146:3001/teamMembers/" + teamCode)
          .then((response) => {
            setMembers(response.data.result);
            setLoading(false);
          });
      });
  });
  return (
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Captain</th>
          <th scope="col">Member Name</th>
          <th scope="col">Contact</th>
          <th scope="col">Team Code</th>
          <th scope="col">Email Address</th>
          <th scope="col">Date Of Birth</th>
          <th scope="col">Alt Contact</th>
          <th scope="col">Next Of Kin</th>
          <th scope="col">Diet / Allergies</th>
        </tr>
      </thead>
      <tbody>
        {loading
          ? "Loading..."
          : captain.map((e, index) => (
              <tr>
                <td>Yes</td>
                <td>{e.captain_name}</td>
                <td>{e.captain_phone}</td>
                <td>{e.team_code}</td>
                <td>{e.captain_email}</td>
                <td>{e.captain_dob}</td>
                <td>{e.secondPhone}</td>
                <td>{e.kin}</td>
                <td>N / A</td>
              </tr>
            ))}
        {loading
          ? "Loading..."
          : members.map((e, index) => (
              <tr>
                <td>No</td>
                <td>{e.member_name}</td>
                <td>{e.member_phone}</td>
                <td>{e.team_code}</td>
                <td>{e.member_email}</td>
                <td>{e.member_dob}</td>
                <td>{e.secondPhone}</td>
                <td>{e.kin}</td>
                <td>{e.description == "" ? "N / A" : e.description}</td>
              </tr>
            ))}
      </tbody>
    </table>
  );
};

const Teams = () => {
  const { filter } = useParams();
  const [teams, setTeams] = useState([]);
  const [eventDates, setEventDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(0);
  const [selectedDate, setSelectedDage] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");

  const filteredTeams = teams.filter((t) => t.event_date === selectedDate);

  useEffect(() => {
    axios.get("https://3.106.200.146:3001/allEvents").then((response) => {
      setEvents(response.data.result);

      if (response.data.result.length > 0) {
        setSelectedEvent(response.data.result[0].id);

        axios
          .get("https://3.106.200.146:3001/getTeams/" + selectedEvent)
          .then((response) => {
            setTeams(response.data.result);

            if (response.data.result.length > 0) {
              axios
                .get("https://3.106.200.146:3001/eventDates/" + selectedEvent)
                .then((response) => {
                  setEventDates(response.data.result);

                  if (response.data.result.length > 0) {
                    setSelectedDage(response.data.result[0].event_date);
                    setLoading(false);
                  } else {
                    console.error("No event dates available");
                  }
                })
                .catch((error) => {
                  console.error("Error fetching event dates:", error);
                });
            } else {
              console.error("No teams available");
            }
          })
          .catch((error) => {
            console.error("Error fetching teams:", error);
          });
      } else {
        console.error("No events available");
      }
    });
  }, []);
  useEffect(() => {
    if (selectedEvent != 0) {
      setLoading(true);
      axios
        .get("https://3.106.200.146:3001/getTeams/" + selectedEvent)
        .then((response) => {
          setTeams(response.data.result);
          axios
            .get("https://3.106.200.146:3001/eventDates/" + selectedEvent)
            .then((response) => {
              setEventDates(response.data.result);
              setSelectedDage(response.data.result[0].event_date);
              setLoading(false);
            });
        });
    }
  }, [selectedEvent]);
  return (
    <>
      <div className={`${styles.tableContainer} container mt-5`}>
        {selectedTeam == "" ? (
          <>
            <div className="d-flex">
              <div>
                <label>Event Date:</label>
                <br />
                <select
                  style={{
                    padding: "10px",
                    border: "1px solid lightgrey",
                    width: "200px",
                    marginRight: "20px",
                  }}
                  onChange={(e) => setSelectedDage(e.target.value)}
                >
                  {eventDates.map((e, index) => (
                    <option key={index} value={e.event_date}>
                      {e.event_date}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>Events:</label>
                <br />
                <select
                  style={{
                    padding: "10px",
                    border: "1px solid lightgrey",
                    width: "200px",
                    marginRight: "20px",
                  }}
                  onChange={(e) => setSelectedEvent(e.target.value)}
                >
                  {events.map((e, index) => (
                    <option key={index} value={e.id}>
                      {e.event_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <br />
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Team Name</th>
                  <th scope="col">Captain Name</th>
                  <th scope="col">Team Code</th>
                  <th scope="col">Event Date</th>
                  <th scope="col">Deposit</th>
                  <th scope="col">Members</th>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? "Loading..."
                  : filteredTeams.map((t, index) => (
                      <tr key={index}>
                        <th scope="row">{t.team_id}</th>
                        <td>{t.team_name}</td>
                        <td>{t.captain_name}</td>
                        <td>{t.team_code}</td>
                        <td>{t.event_date}</td>
                        <td className="allCaps">{t.payment}</td>
                        <td>
                          <p
                            className="color__blue"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSelectedTeam(t.team_code)}
                          >
                            View Members
                          </p>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </>
        ) : (
          <Members styles={styles} teamCode={selectedTeam} />
        )}
      </div>
    </>
  );
};

export default Teams;
