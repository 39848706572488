import React from "react";
import styles from "./HomeLatest.module.css";
import ExternalIcon from "../../images/external_link_icon.png";
import HT1 from "../../images/h_t1.png";
import HT2 from "../../images/h_t2.png";
import Swimming from "../../images/swimming_2.png";
import { useNavigate } from "react-router-dom";

import Rugby from "../../icons/rugby.svg";
import Racket from "../../icons/racket.svg";
import Whistle from "../../icons/whistle.svg";
import Clock from "../../icons/watch.svg";
import Basketball from "../../icons/basketball.svg";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div className={`${styles.heroSectionContainer}`}>
      <h1 className="color__orange mainFont fontBold">
        Instilling Good Habits, Training Willpower
      </h1>
      <p className="mainFont fontRegular">
        Training children to be active from a young age fosters a lifetime of
        healthy habits and enjoyment in sports. Encouraging kids to engage in
        physical activities and play sports not only helps develop their
        physical fitness but also builds essential skills like teamwork and
        discipline. <br />
        By integrating fun, age-appropriate exercises and games into their
        routines, children learn the importance of maintaining good health and
        staying active. This early foundation supports their overall well-being
        and sets the stage for a balanced and active lifestyle as they grow
      </p>
      <p className="mainFont fontRegular">
        Based on the Central Coast NSW, Growing Athletes runs a variety of
        programs to help children become the best version of themselves. <br />{" "}
        <br /> Their Training Camps offer a dynamic environment where children
        can learn and refine physical skills crucial for their athletic
        development and incorporating the foundations of a healthy lifestyle.
        <br /> <br /> These camps focus on age-appropriate drills and exercises
        that enhance strength, coordination, and agility, helping young athletes
        build a solid foundation for future sports endeavours.
        <br /> <br /> Through engaging activities and expert guidance, children
        not only improve their physical abilities but also gain confidence,
        teamwork skills, and a love for staying active. It’s a fun and effective
        way for kids to grow, develop, and discover their potential in sports.
      </p>
      <div className="d-flex justify-content-center">
        <button
          className="color__white mainFont"
          onClick={() => navigate("/weekly-training")}
        >
          WEEKLY TRAINING CAMPS <img src={ExternalIcon} />
        </button>
      </div>
      <p className="mainFont fontRegular">
        Their GAME DAY school activity camps are a fantastic opportunity for
        children to explore new sports, make new friends, and have a blast.
        These camps offer a diverse range of activities that introduce kids to
        different sports and games in a fun, supportive environment. Beyond just
        learning new skills, children enjoy the chance to socialize,
        collaborate, and build lasting friendships. <br /> <br /> With a focus
        on enjoyment and personal growth, school activity camps provide a
        memorable and enriching experience that combines physical activity with
        social interaction for primary school aged children.
      </p>
      <div className="d-flex justify-content-center">
        <button
          className="color__white mainFont"
          onClick={() => navigate("/gameday")}
        >
          SCHOOL HOLIDAY GAME DAY <img src={ExternalIcon} />
        </button>
      </div>
    </div>
  );
};
const Journey = () => {
  const navigate = useNavigate();
  return (
    <div className={`${styles.journeyContainer}`}>
      <h2 className="color__orange mainFont fontBold">
        Start Your <br /> Journey Today
      </h2>
      <div>
        <img src={HT1} onClick={() => navigate("/gameday")} />
        <h3
          className="color__white mainFont fontSemiBold"
          onClick={() => navigate("/gameday")}
        >
          Find a School <br /> Holiday Game Day
        </h3>
      </div>
      <div>
        <img src={HT2} onClick={() => navigate("/weekly-training")} />
        <h3
          className="color__white mainFont fontSemiBold"
          onClick={() => navigate("/weekly-training")}
        >
          Join A Weekly <br /> Training Camp
        </h3>
      </div>
    </div>
  );
};
const Contact = () => {
  return (
    <div className={`${styles.contactContainer}`}>
      <h2 className="color__blue mainFont fontBold">
        CONTACT <br /> US
      </h2>
      <img src={Swimming} />
      <div>
        <h2 className="color__white mainFont fontBold">
          HIT US UP IF YOU HAVE ANY QUESTIONS!
        </h2>
        <input placeholder="Full Name" className="mainFont" />
        <input placeholder="Email Address" className="mainFont" />
        <div>
          <label className=" mainFont">Description</label>
          <br />
          <textarea></textarea>
        </div>
        <div className="d-flex justify-content-end">
          <button className={`${styles.submitButton} mainFont fontMedium`}>
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  );
};

const Newsletter = () => {
  return (
    <div className={`${styles.newsletterContainer}`}>
      <h2 className="mainFont color__white fontBold">Newsletter</h2>
      <p className="mainFont color__white text-center">
        Yay! You made it to the end. Subscribe to our <br /> Newsletter for
        future Updates.
      </p>
      <div>
        <input
          placeholder="Enter your email address"
          className="mainFont fontThin"
        />
        <button className="mainFont">Let's Go!</button>
      </div>
    </div>
  );
};

const MobileHome = () => {
  return (
    <div style={{ position: "relative" }}>
      <img
        src={Racket}
        className={`${styles.racket}`}
        style={{ position: "absolute" }}
      />
      <img
        src={Rugby}
        className={`${styles.rugby}`}
        style={{ position: "absolute" }}
      />
      <img
        src={Whistle}
        className={`${styles.whistle}`}
        style={{ position: "absolute" }}
      />
      <img
        src={Clock}
        className={`${styles.clock}`}
        style={{ position: "absolute" }}
      />
      <img
        src={Whistle}
        className={`${styles.whistle2}`}
        style={{ position: "absolute" }}
      />
      <img
        src={Basketball}
        className={`${styles.basketball}`}
        style={{ position: "absolute" }}
      />
      <img
        src={Whistle}
        className={`${styles.whistle3}`}
        style={{ position: "absolute" }}
      />
      <HeroSection />
      {/* Spacer */}
      <div style={{ height: "7.5rem" }}></div>
      <Journey />
      {/* Spacer */}
      <div style={{ height: "7.5rem" }}></div>
      <Contact />
      {/* Spacer */}
      <div style={{ height: "6.8rem" }}></div>
      {/* Newsletter */}
      <Newsletter />
    </div>
  );
};

export default MobileHome;
