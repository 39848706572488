import React, { useEffect, useState } from "react";
import styles from "./Events.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const GameDayLeads = () => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const [code, setCode] = useState("");
  const [amount, setAmount] = useState("");

  const navigate = useNavigate();

  const [leads, setLeads] = useState([]);

  useEffect(() => {
    axios.get("https://3.106.200.146:3001/getgamedayleads").then((response) => {
      setLeads(response.data.result);
      setLoading(false);
    });
  }, [loading]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const convertDateTime = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);

    // Extract components
    const year = dateObject.getFullYear();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      dateObject
    );
    const day = dateObject.getDate();
    const hour = dateObject.getHours() % 12 || 12; // Convert to 12-hour format
    const minute = dateObject.getMinutes();
    const ampm = dateObject.getHours() >= 12 ? "PM" : "AM";

    // Format the result
    const formattedDateTime = `${month} ${day}, ${year} ${hour}:${
      minute < 10 ? "0" : ""
    }${minute} ${ampm}`;

    return formattedDateTime;
  };
  return (
    <>
      <div className="container">
        <h3 className="mainFont">GameDay Leads</h3>
      </div>

      <div className={`${styles.tableContainer} container`}>
        <table className={`${styles.customTable} table`}>
          <thead>
            <tr className="mainFont fontThin">
              <th scope="col">Lead ID</th>
              <th scope="col">Child Name</th>
              <th scope="col">Parent Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {!loading
              ? leads.map((e) => (
                  <tr key={e.id} className="mainFont fontThin">
                    <td style={{ paddingTop: "15px" }}>{e.id}</td>
                    <td style={{ paddingTop: "15px" }}>{e.child_name}</td>
                    <td style={{ paddingTop: "15px" }}>{e.parent_name}</td>
                    <td style={{ paddingTop: "15px" }}>{e.email}</td>
                    <td style={{ paddingTop: "15px" }}>{e.phone}</td>
                    <td style={{ paddingTop: "15px" }}>
                      {convertDateTime(e.date)}
                    </td>
                  </tr>
                ))
              : "Loading..."}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GameDayLeads;
