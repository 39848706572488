import React, { useState } from "react";
import styles from "./Faq.module.css";
import Navbar from "../../components/Navbar";
import Football from "../../images/football.svg";
import Rugby from "../../images/rugby.svg";
import Whistle from "../../icons/whistle.svg";
import Clock from "../../icons/watch.svg";
import Footer from "../../components/Footer";
const Faq = () => {
  const [faqSelected, setFaqSelected] = useState(1);

  const faq = [
    {
      number: "01",
      question: "What exactly do we do?",
      answer:
        "Growing Athletes runs weekly Training Camps for children to help them build their confidence as well as their athletic abilities. We also run a school holiday Game Day which allows GA members and others to join a fun day of sport and activities.",
    },
    {
      number: "02",
      question: "Is GA insured?",
      answer:
        "Yes, we have full insurance for our Training Camps and Game Days.",
    },
    {
      number: "03",
      question:
        "Are all staff qualified in First Aid and have Working with Children Checks?",
      answer:
        "Yes. Our coaches at our Training camps have both. Our staff at Game Days also all have WWCC and we have a dedicated onsite First Aid tent and staff member for the day.",
    },
    {
      number: "04",
      question: "Do you accept Service NSW vouchers.",
      answer: "We are working on this! Stay tuned.",
    },
  ];

  const faq2 = [
    {
      number: "05",
      question: "What time is pick up and drop off for game day?",
      answer:
        "Drop off is 8:30am and pickup is 3:30pm. Details will be needed for next of kin for pickup too. We do expect parents or caregivers to stick to these times or make arrangements to do so.",
    },
    {
      number: "06",
      question: "What if a parent cannot make pickup time?",
      answer:
        "Please let us know at drop off the details of who is collecting the child. If something unexpected comes up during the day, the listed next of kin will be contacted for pickup.",
    },
    {
      number: "07",
      question: "What do kids need to bring to game day?",
      answer:
        "Lunch is provided, but any snacks your child thinks they may need, and a drink bottle.",
    },
    {
      number: "08",
      question: "What if my child has food allergies?",
      answer:
        "In the comments section of your booking, please let us know of your child's allergies and dietary requirements for us to adhere to.",
    },
    {
      number: "09",
      question: "What footwear should kids wear to game day?",
      answer:
        "Enclosed joggers or running shoes, as these cater to most activities. Football boots can also be packed to be changed into for appropriate sports.",
    },
    {
      number: "10",
      question: "What if my child is sick on the day and cannot attend?",
      answer:
        "We require at least 24 hours notice in this case and you have the option to either replace your child with another child (maybe another friend would like to attend instead) or we will issue you a credit note for the next Game Day.",
    },
  ];

  const faq3 = [
    {
      number: "11",
      question: "How much are training camps?",
      answer:
        "Training camps are $13/child per session or you can pay for the term in advance for $10/session.",
    },
    {
      number: "12",
      question: "What fitness level does my child need to be to attend?",
      answer:
        "Beginners to advanced, we take all levels and cater to them appropriately.",
    },
    {
      number: "13",
      question: "How often can kids attend?",
      answer:
        "We encourage children to come weekly to help them build their athletic abilities continuously.",
    },
    {
      number: "14",
      question:
        "What if we miss a session because of sickness or other commitments?",
      answer:
        "No problems! A missed session can be made up at another time of the week or location.",
    },
    {
      number: "15",
      question: "What should my child wear and bring?",
      answer:
        "Comfortable light clothing and enclosed joggers. Bring a water bottle, and a towel if it’s wet.",
    },
  ];

  return (
    <>
      <Navbar />
      <h2
        className={`${styles.faqHeading} text-center mainFont color__blue fontBold mt-5`}
      >
        Frequently Asked Questions
      </h2>
      <div className="container">
        <div className={`${styles.faq__container}`}>
          <img src={Football} className={`${styles.football}`} />
          <img src={Rugby} className={`${styles.rugby}`} />
          {faq.map((f) => (
            <div
              className={`${styles.faq__box} ${
                faqSelected == f.number
                  ? styles.faq__activeBox
                  : styles.faq__boxHidden
              }`}
              onClick={() => setFaqSelected(f.number)}
            >
              <div className={`${styles.faq__number}`}>
                <h3
                  className={`${
                    faqSelected == f.number ? "color__white" : "color__blue"
                  } mainFont  fontBold`}
                >
                  {f.number}
                </h3>
              </div>
              <div className={`${styles.faq__textContainer}`}>
                <div className={`${styles.faq__question} mb-4`}>
                  <h3 className="mainFont color__white fontMedium">
                    {f.question}
                  </h3>
                </div>
                <div className={`${styles.faq__answer}`}>
                  <p className="mainFont fontThin">{f.answer}</p>
                </div>
              </div>
              <div
                className={`${
                  faqSelected == f.number ? styles.faq__close : styles.faq__open
                }`}
              >
                <button>{faqSelected == f.number ? "X" : "+"}</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <div className={`${styles.faq__container}`}>
          <h2 className="color__orange mainFont fontBold mx-3 mt-5 mb-5">
            Game Day Related
          </h2>
          <img src={Whistle} className={`${styles.football}`} />
          <img src={Clock} className={`${styles.rugby}`} />
          {faq2.map((f) => (
            <div
              className={`${styles.faq__box} ${
                faqSelected == f.number
                  ? styles.faq__activeBox
                  : styles.faq__boxHidden
              }`}
              onClick={() => setFaqSelected(f.number)}
            >
              <div className={`${styles.faq__number}`}>
                <h3
                  className={`${
                    faqSelected == f.number ? "color__white" : "color__blue"
                  } mainFont  fontBold`}
                >
                  {f.number}
                </h3>
              </div>
              <div className={`${styles.faq__textContainer}`}>
                <div className={`${styles.faq__question} mb-4`}>
                  <h3 className="mainFont color__white fontMedium">
                    {f.question}
                  </h3>
                </div>
                <div className={`${styles.faq__answer}`}>
                  <p className="mainFont fontThin">{f.answer}</p>
                </div>
              </div>
              <div
                className={`${
                  faqSelected == f.number ? styles.faq__close : styles.faq__open
                }`}
              >
                <button>{faqSelected == f.number ? "X" : "+"}</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <div className={`${styles.faq__container}`}>
          <h2 className="color__orange mainFont fontBold mx-3 mt-5 mb-5">
            Training Camp Related
          </h2>
          <img src={Football} className={`${styles.football}`} />
          <img src={Rugby} className={`${styles.rugby}`} />
          {faq3.map((f) => (
            <div
              className={`${styles.faq__box} ${
                faqSelected == f.number
                  ? styles.faq__activeBox
                  : styles.faq__boxHidden
              }`}
              onClick={() => setFaqSelected(f.number)}
            >
              <div className={`${styles.faq__number}`}>
                <h3
                  className={`${
                    faqSelected == f.number ? "color__white" : "color__blue"
                  } mainFont  fontBold`}
                >
                  {f.number}
                </h3>
              </div>
              <div className={`${styles.faq__textContainer}`}>
                <div className={`${styles.faq__question} mb-4`}>
                  <h3 className="mainFont color__white fontMedium">
                    {f.question}
                  </h3>
                </div>
                <div className={`${styles.faq__answer}`}>
                  <p className="mainFont fontThin">{f.answer}</p>
                </div>
              </div>
              <div
                className={`${
                  faqSelected == f.number ? styles.faq__close : styles.faq__open
                }`}
              >
                <button>{faqSelected == f.number ? "X" : "+"}</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
